html, body {
    background: #DDD !important;
    width: 100% !important;
    min-width: 350px;
    // min-width: 100vw;
}

body, #root {
    overflow-x: hidden;
    position: relative;
}

* {
    font-family: 'Poppins' !important;
}

.text-muted {
    color: #1D2230 !important;
}

.page-section {
    padding: 4rem 0;
}

.hide {
    display: none !important;
}

.fixed-top {
    position: absolute !important;
}

.flexc {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.flexc p {
    text-align: center !important;
}

.coin {
    position: absolute;
    max-width: 1200px;
    width: 90%;
    z-index: -1;
    height: auto;
}

.ple {
    position: absolute;
    width: 100%;
    left: 0px;
    top: 0px;
}

.sub-menu > a {
    text-decoration: none !important;
}

.tfc-0 { color: #202581 !important; }
.tfc-1 { color: #627CDB !important; }
.tfc-2 { color: #000 !important; }

.nav-link {
    color: #000 !important;
}

#mainNav .navbar-nav .nav-item .nav-link {
    font-size: 16px;
}

#mainNav .navbar-nav .nav-item .nav-link:focus, #mainNav .navbar-nav .nav-item .nav-link:active {
    color: #7D97F4 !important;
}

.text-black {
    color: #000 !important;
}

.w100 {
    width: 100%;
}

h1.text-black {
    line-height: 1.4 !important;
}

.dmode {
    display: none !important;
    &.responsive, &.is-tablet, &.is-mobile { display: none !important; }
    &.not-responsive, &.is-desktop { display: none !important; }
}
[data-theme='dark'] .dmode {
    display: inline-flex !important;
    &.responsive, &.is-tablet, &.is-mobile { display: none !important; }
    &.not-responsive, &.is-desktop { display: inline-flex !important; }
}
.lmode {
    display: inline-flex !important;
    &.responsive, &.is-tablet, &.is-mobile { display: none !important; }
    &.not-responsive, &.is-desktop { display: inline-flex !important; }
}
[data-theme='dark'] .lmode {
    display: none !important;
    &.responsive, &.is-tablet, &.is-mobile { display: none !important; }
    &.not-responsive, &.is-desktop { display: none !important; }
}

.btn-primary {
    font-weight: 200;
    max-width: 100%;
}

.app-menu {
    display: none;
    width: 1140px;
    position: fixed;
    top: 100px;
    min-height: 100px;
    right: 50px;
    padding: 1.2em .5em;
    transform: 250ms ease-in-out all;
    z-index: 100000;
    background: #FFFFFF;
    box-shadow: 0px 0px 11px rgba(125, 151, 244, 0.28);
    border-radius: 10px;
}

.for-all {
    margin: 0px !important;
    padding: 0px !important;
}

.logclo {
    display: flex;
    padding: 1.5em;
    width: 100%;
    margin-bottom: 50px;
    justify-content: space-between;
}

.logclo img {
    cursor: pointer;
}
#app-overlay-alt {
    display: none;
}

img.close {
    width: 20px;
    height: 20px;
}

#app-overlay, #app-overlay-alt {
    display: none;
    width: 100vw;
    position: fixed;
    height: 100vh;
    z-index: 10000000000;
    cursor: pointer;
    /* background-color: #57575717; */
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
}

.sub-menu {
    min-height: 80px;
    margin: 0;
    display: inline-block;
    cursor: pointer;
    float: left;
    transition: 250ms ease-in all;
    border-radius: 10px;
    padding-top: 12px;
    height: 100px;
    padding-left: 1em;
    padding-right: 10px;
    padding-bottom: .5em;
    width: calc(100% / 3);
    width: 340px;
    margin-left: 20px;
    margin-right: 10px;
    padding-bottom: 12px;
    position: relative;
}

[data-theme='dark'] .app-menu, [data-theme='dark'] .lang-menu, [data-theme='dark'] .triangle:after {
    background: #1D2230 !important;
}

.scroll_top {
    position: fixed;
    right: 5px;
    background: rgba(0,0,0,0.4);
    border-radius: 5px;
    cursor: pointer;
    color: #fff;
    bottom: 125px;
    height: 40px;
    width: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transform: translateX(55px);
    z-index: 10000000000;
    transition: 250ms ease-in all;
    opacity: 0.7;
    &.active {
        opacity: 1;
        transform: translateX(0px);
    }
}

.xrw {
    justify-content: center;
    align-items: center;
    display: inline-flex;
    img {
        width: 120px !important;
    }
}

.rock {
    height: 80%;
    width: 80%;
}

.slick-track {
    display: flex;
    flex-direction: row;
}

.ud {
    margin-left: 2px;
}

.ud.active {
    transform: rotate(180deg);
}

.nav-link:focus {
    color: #627CDB !important;
}

.app-menu.active {
    display: block;
}

#app-overlay.active {
    display: flex;
}

.sub-menu:not(.real):hover {
    background: rgba(186, 204, 253, 0.17);

}

.sub-menu:hover b {
    font-weight: 500;
}

.sub-menu:hover * {
    color: inherit !important;
}

.sub-menu.real {
    justify-content: left !important;
    align-items: center !important;
    padding-left: 97px !important;
}
.sub-menu.real:hover b {
    font-weight: 500 !important;
}

.sub-menu:not(.real)::before {
    position: absolute;
    content: '';
    height: 100%;
    left: 10px;
    top: 0px;
    width: 1px;
    background: #E0E0E0;
}

.sub-menu:not(.real):hover::before {
    background: #F22FB0 !important;
}

.sub-menu b {
    font-weight: 500;
    font-size: 16px;
}

.sub-menu span {
    margin-top: 6px;
    font-weight: 400;
    font-size: 14px;
}

.sub-menu img:not(.ud, .air) {
    position: relative;
    top: 8px;
    width: 40px !important;
    height: 40px !important;
}

.sub-menu.real {
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.sub-menu.real b, .sub-menu.real > a {
    font-size: 24px;
}

.flexy {
    padding-left: 20px;
    display: flex;
    flex-direction: column;
}

.flexx {
    padding-left: 5px;
    display: flex;
    flex-direction: row;
}

.col-p {
    color: #8593f8 !important;
}

.hts, .kts {
    display: inline-flex;
    flex-direction: column;
}

.hts .sm {
    margin-top: 2em;
    font-size: 12px;
    color: #979797;
}

.its, .jts {
    position: relative;
}

img.air {
    position: absolute;
    // top: -33px;
    // right: -8px;
    top: -25px;
    right: -35px;
}

img.mobh {
    position: absolute;
    top: -161px;
    right: 0px;
    height: 121%;
}

img.mlo {
    height: 30px;
}

img.rl {
    position: absolute;
    z-index: -1;
    left: 15%;
    left: 10px;
}

img.rr {
    position: absolute;
    z-index: -1;
    right: 9%;
}

img.bt {
    position: absolute;
    z-index: 1;
    left: 40%;
    bottom: 0px;
}

img.gpla {
    position: absolute;
    left: -180px;
    top: -180px;
    z-index: -1;
}

img.ud {
    width: 10px ;
    height: 8px;
}

.elf {
    top: 200px;
    // top: 210px;
    position: absolute;
    // left: -5px;
    left: 0px;
    // width: calc(100% + 8px);
    width: 100%;
}

._pa {
    position: absolute;
    right: -250px;
    top: -50px;
    z-index: -1;
}

.table_dl {
    top: 240px;
    position: absolute;
    width: 86%;
    right: -50px;
}

img.eci {
    position: absolute;
    z-index: -1;
}

img.ic_love, img.ic_face {
    position: absolute;
    z-index: 1;
    left: 10%;
    top: 30%;
}

img.ic_face {
    bottom: 15%;
    top: unset;
    left: 7%;
}

img.eli {
    position: absolute;
    top: 100px;
    left: 18px;
    z-index: -1;
}

.lft {
    position: absolute;
    right: -250px;
    top: -40px;
    z-index: -1;
}

@media (max-width: 1351px) {
    img.eli {
        position: absolute;
        top: 120px;
        left: 1%;
        z-index: -1;
        width: 50%;
    }
}

img.plo {
    position: absolute;
    top: 225px;
    left: 135px;
    z-index: 0;
    display: none;
}

img.plr {
    position: absolute;
    top: 30px;
    right: 0px;
    z-index: -1;
}

img.plis {
    position: absolute;
    z-index: -1;
    width: 100%;
    right: 0px;
    height: 550px;
    top: 0px;
}

img.av {
    position: absolute;
    z-index: 1;
    right: 75px;
    bottom: 4.2em;
}

img.fdf {
    width: 100%;
    max-width: 523px;
    height: fit-content;
    margin-bottom: 30px;
}

img.bbg {
    display: none;
    position: fixed;
    z-index: -2;
    left: 0px;
    top: 0px;
    right: 0px;
    bottom: 0px;
    width: 100%;
    height: 100%;
}

.nav-item {
    margin-left: 14px;
    width: max-content;
}

.imq {
    position: absolute;
    left: -45px;
    top: -15px;
}

.mw8 {
    min-width: 80px;
}

.my-slider {
    display: flex;
    transition: all 0.5s;
    position: relative;
    width: 100%;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    height: 500px;
    padding-top: 180px;
    position: absolute;

    &.pd {
        padding-left: 100px;
        .btn-prev {
            opacity: 0 !important;
        }
    }

    &.mr {
        pointer-events: none;
        overflow-x: scroll;
        overflow-y: hidden;
        height: 600px !important;
        -webkit-overflow-scrolling: auto !important;
    }

    .myslider {
        width: 100%;
        height: 250px;
        position: relative;
        // overflow: hidden;
        &.mr {
            // background-color: #ddd;
            // border: 3px dashed #444;
            position: absolute;
            width: 125vw;
            opacity: 0.5;
            z-index: 10000;
            overflow-x: scroll;
            overflow-y: hidden;
            -webkit-overflow-scrolling: auto !important;
        }
    }

  .slide:first-child {
      opacity: 0 !important;
  }
  
  .slide {
    width: 100%;
    max-width: 400px;
    height: 250px;
    position: absolute;
    transition: all 250ms;
  }
  
  .slide img:not(.dp) {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .btn {
    position: absolute;
    z-index: 100;
    top: calc(180px + 125px - 50px) !important;
    z-index: 100000 !important;
    height: 60px !important;
    width: 60px !important;
    border-radius: 50% !important;
    border: 0px !important;
    cursor: pointer !important;
    background-color: #fff;
    // opacity: 0.95;
    opacity: 1;
    // background: #7D97F4 !important;
    &:disabled, &.disabled {
        opacity: 0 !important;
    }
  }
  
  .btn-prev {
    left: 2%;
    transform: rotate(180deg);
    &:active, &:focus {
        opacity: 1;
        transform: scale(1.03) rotate(180deg);
    }
  }
  
  .btn-next {
    right: 2%;
    &:active, &:focus {
        opacity: 1;
        transform: scale(1.03);
    }
  }
}

#wps, .testimony {
    ::-webkit-scrollbar { background: #fff !important; height: 0px !important; -webkit-appearance: none !important; }
    ::-webkit-scrollbar-track { background: #fff !important; height: 0px !important; }
    ::-webkit-scrollbar-thumb { background: #fff !important; height: 0px !important; }
}
[data-theme='dark'] {
    #wps, .testimony {
        ::-webkit-scrollbar { background: #1d2f48 !important; height: 0px !important; -webkit-appearance: none !important; }
        ::-webkit-scrollbar-track { background: #1d2f48 !important; height: 0px !important; }
        ::-webkit-scrollbar-thumb { background: #1d2f48 !important; height: 0px !important; }
    }
}

#media-sect {
    .myslider {
        height: 224px;
        .slide {
            width: 384px;
            height: 224px;
            display: flex;
            justify-content: center;
            align-items: center;
            img {
                width: 100%;
                height: 100%;
                position: relative;
            }
            .play {
                position: absolute;
                z-index: 1;
                cursor: pointer;
                width: 100px;
                height: 100px;
            }
        }
    }
}

#media-sect::after {
    position: absolute;
    right: 0px;
    bottom: 0px;
    content: '';
    top: 0px;
    width: 200px;
    z-index: 1;
    opacity: 0.9;
    background: linear-gradient(270deg, #E2E6F4 26.58%, rgba(226, 230, 244, 0) 100%);
}

[data-theme='dark'] {
    #media-sect::after {
        opacity: 1;
        background: linear-gradient(270deg, rgba(51, 57, 83, 0.95) 26.58%, rgba(52, 58, 85, 0) 100%);
    }
}

.rdm {
    color: rgb(125, 151, 244) !important;
    border: 0px solid transparent;
    background: transparent !important;
    font-weight: 600;
    padding: 0px;
    cursor: pointer;
    span {
        color: rgb(125, 151, 244) !important;
    }
}

.testimony {
    overflow: hidden;
    .my-slider {
        padding-top: 240px;
        height: 600px;
        &.mr {
            height: 900px !important;
        } 
        .myslider {
            height: 300px;
        }
    }
    .tesb {
        z-index: 10;
    }
    h2.f40 {
        position: absolute;
        top: 80px;
        width: 100%;
        z-index: 20;
    }
    p.f22 {
        position: absolute;
        top: 160px;
        width: 100%;
        z-index: 20;
    }
    #testy-sect {
        height: 600px;
        .myslider {
            height: 300px;
            .slide {
                width: 440px;
                max-width: 440px;
                height: 264px;
                display: flex;
                justify-content: center;
                // align-items: center;
                align-items: flex-start;
            }
        }
    
        .btn {
            position: absolute;
            z-index: 100;
            top: 340px !important;
        }
        
        // &.rmore {
            height: 750px;
            .myslider {
                height: auto !important;
                .slide {
                    height: 458px;
                }
            }
            .btn {
                top: 400px !important;
            }
        }
    // }
    .tesb {
        width: 200px;
        height: 300px;
    }
}

.testimony._nx {
    .tesb {
        height: 100% !important;
    }
}

.testimony.stopad {
    position: relative;
    height: 600px;
    padding: 0px !important;
}

body:not([data-theme='dark']) {
    #testy-sect {
        .btn {
            background: #7D97F4 !important;
        }
    }
}

.pbold {
    font-weight: 400;
    font-size: 20px;
    color: var(--color);
    line-height: 28.8px;

    &.f24, &.f22 {
        font-weight: 500;
        line-height: 33px;
    }
}

.kts {
    align-items: center;
    justify-content: space-evenly;
    justify-content: center;
}

.kts .sm {
    font-size: 12px;
    color: #979797;
}

.sm {
    cursor: pointer;
}

.kts .blk {
    margin-top: 1em;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
}

.dcenter {
    display: inline-flex; align-items: center; justify-content: center;
}

.monitor {
    position: absolute;
    top: 165px;
    right: 8%;
    z-index: 1;
}

.c45 {
    position: absolute;
    top: 36%;
    left: 15%;
    font-size: 2.5em;
    font-weight: 600;
    z-index: 100;
}

.tup {
    // position: absolute;
    // bottom: 60px;
    // left: 8%;
    // z-index: 1;
    position: absolute;
    bottom: 100px;
    left: 6%;
    z-index: 100;
}

.plst {
    position: absolute;
    bottom: 0px;
    left: 0;
    top: 0px;
    z-index: 0;
}

.plsb {
    position: absolute;
    top: 0px;
    right: 0;
    z-index: 0;
}

.table.cs {
    width: 970px;
    max-width: 80%;
    background: var(--background);
    z-index: 1000;
    // overflow: hidden;
    // box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.16);
    box-shadow: -25px -25px 50px rgba(125, 151, 244, 0.34);
    // filter: drop-shadow(-30px -30px 50px rgb(125, 151, 244, 0.34));
}

[data-theme='dark'] .table.cs {
    background: var(--background);
    // box-shadow: -20px -20px 40px rgba(116, 147, 224, 0.22);
}

#info-div {
    position: fixed;
    right: 1em;
    bottom: 2em;
    z-index: 10000000;
    background: #000;
    min-width: 50px;
    border-radius: 1em;
    color: #fff !important;
    display: flex;
    font-size: 12px;
    flex-direction: column;
    justify-content: center;
    padding: 10px;
    align-content: center;
    min-height: 50px;
}

.nopad {
    padding: 0px !important;
}

.table.cs tr {
    height: 100px;
    border: 0px;
    padding-left: 3em;
    padding-right: 3em;
    position: relative;
    &._tln_ {
        height: 2px !important;
        opacity: 0.27;
        padding: 0px !important;
        background: linear-gradient(to right, #C059FF, #FD29B5, #4944FF);
        background-repeat: no-repeat;
    }
    .geo-sp {
        font-size: 12px !important;
    }
}

.table.cs tr .trl {
    position: absolute;
    left: 0px;
    width: 100%;
    bottom: 0px;
}

.table.cs th, .table.cs td {
    padding-left: 2em;
}

.table.cs th {
    font-weight: 600 !important;
}

.table.cs td {
    font-weight: 200 !important;
}

.table.cs tr {
    position: relative;
}

.table.cs tr:not(:last-child)::after {
    content:'';
    position:absolute;
    width:100%;
    height: 2px;
    // background:linear-gradient(to right, #C059FF, #FD29B5, #4944FF);
    bottom: 2px;
    opacity: 0.27;
    left:0;
}

.table.cs tr th:first-child {
    justify-content: flex-start;
    padding-left: 50px;
}

.table.cs tr th:not(:first-child),
.table.cs tr td:not(:first-child) {
    text-align: center;
    padding: 0;
}

.table.cs tr th:last-child,
.table.cs tr td:last-child {
    justify-content: flex-start;
    padding-right: 50px;
}

.plink {
    font-weight: 500;
    font-size: 16px;
    color: #7D97F4;
}

span.sm {
    color: #979797;
}

img.i1 { position: absolute; z-index: 0; left: 4.1%; top: 0;}
img.i2 { position: absolute; z-index: 0; right: 4.1%; top: 0;}
img.i3 { position: absolute; z-index: 0; left: 19%; top: 80px}
img.i4 { position: absolute; z-index: 0; right: 19%; top: 80px}
img.i5 { position: absolute; z-index: 0; left: 8%; top: 160px}
img.i6 { position: absolute; z-index: 0; right: 8%; top: 160px}
img.iv { position: absolute; z-index: 0; right: 0; bottom: 20px}

#stats, #clients, .clients, #dc {
    position: relative;
    /* background: linear-gradient(180deg, #42DDFF 0%, rgba(17, 112, 255, 0.46) 100%); */
    background: linear-gradient(180deg, #42ddff4d 0%, rgba(17, 112, 255, 0.18) 100%);
}

.rbg-dark {
    background: transparent !important;
    overflow: hidden;
    position: relative;
    .pl1, .pl2 {
        opacity: 0;
        display: none !important;
    }
}

.rbg-dark::before {
    content: '';
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: -1;
    top: 0px;
    bottom: 0px;
    // filter: blur(20px);
    // opacity: 0.7;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100% 100% !important;
}

#dc {
    &.rbg-dark::after {
        content: '';
        position: absolute;
        z-index: 0;
        bottom: 0px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover !important;
        bottom: 0px;
        left: 0px;
        top: calc(100% - 379px);
        width: 459px;
        height: 379px;
        z-index: 1;
    }
}

.rbgd {
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: 0;
    top: 0px;
    bottom: 0px;
}

.wth-lite {
    font-weight: 600;
    font-size: 20px;
    padding-bottom: 5px;
}

[data-theme='dark'] .rbg {
    background: rgba(29, 47, 72, 1);
}

[data-theme='dark'] .rbg2 {
    background: #23394e;
}

.ppsydiv, .testidiv {
    display: flex;
    // width: 93%;
    // margin-left: 7%;
    overflow: hidden;
    padding-left: 0px !important;
    padding-right: 0px !important;
}

img.pl1 { position: absolute; height: 100%; z-index: 10; left: 0px; bottom: 0px; max-width: 100%; opacity: 0.7; }
img.pl2 { position: absolute; height: 100%; z-index: 10; right: 0px; top: 0px; max-width: 100%; opacity: 0.55; }

.mlfi {
    position: relative;
    display: inline-flex;
}

.mlfi::after {
    content: '';
    background: #f5f7ff;
    top: 18px;
    right: 0px;
    bottom: 0px;
    height: 25px;
    width: 2px;
    position: absolute;
}

#footer-0 .mlfi::after {
    background: #f6faff;
}

[data-theme='dark'] .mlfi::after {
    background: #373e51;
}

[data-theme='dark'] #footer-0 .mlfi::after {
    background: #1d212e;
    top: 15px;
}

.wth {
    font-size: 40px;
    font-weight: 600 !important;
}

.sl-nav {
    &>span, &>.ud {
        position: relative;
        // top: 7px;
        font-size: 16px;
    }
}

#mainNav .navbar-nav {
    .nav-item {
        .nav-link {
            font-weight: 500 !important;
        }
    }
}

.home-page {
    .hero-sect {
        &>img {
            filter: blur(2px);
        }
    }
}

// .navbar.py-3 {
//     padding-top: 45px !important;
//     padding-bottom: 45px !important;
// }

.m1 {
    display: flex;
    align-items: flex-end;
    flex-direction: column;
}

.m1 .no, .m2 .no {
    font-weight: 400;
    font-size: 28px;
    margin-top: -5px;
    color: #7D97F4;
}

.m1 .line, .m2 .line {
    width: 2px;
    height: 65%;
    display: flex;
    opacity: 0.27;
    margin-top: 0px;
    margin-right: 3px;
    background-color: #7D97F4;
}

.fxlt {
    min-width: calc(100% / 4) !important;
}

.fxlt2 {
    min-width: 25% !important;
    padding: 0px !important;
    img.lmode {
        display: flex !important;
    }
}

.hero-sect-ant {
    position: relative;
    width: 500px;
    height: 500px;
    top: -45px;

    #pre-can {
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: 0;
    }

    .rok-mob {
        width: 70%;
        height: 500px;
        position: relative;
        // background-color: white;
        
        #pre-mun, .pre-mun {
            height: 120px !important;
            width: 120px !important;
            z-index: 0;
            bottom: 22% !important;
            right: -5px !important;
            position: absolute !important;
        }
        img {
            position: absolute;

            &.rok {
                width: 120px;
                right: 40px;
                top: 36%;
                z-index: 1;
            }
            &.ieli {
                top: 26%;
                right: 20px;
                width: 145px;
            }
            &.ivbg {
                right: 5px;
                top: 50%;
                width: 15px;
            }
            &.blo2 {
                top: 55%;
                right: 140px;
            }
            &.elb {
                right: 0px;
                bottom: 0px;
            }
            &.im2 {
                bottom: 29%;
                right: 64px;
                width: 50px;
                z-index: 1;
                height: 50px;
            }
        }
        .flame-body {
            position: absolute;
            top: 47%;
            transform: rotate(50deg) scaleX(.36) scaley(1.3);
            right: 108px;
            z-index : 0;
        }
    }
}

.rings, .rings-main {
    &.rings-main {
        position: absolute;
        width: 500px;
        height: 500px;
        top: 0px;
        bottom: 0;
        right: 0;
        left: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
            position: absolute;
        }
    }

    img {
        width: 265px;
        // opacity: 0.9;
        // position: absolute;
        &.ring1 {
            -webkit-animation-timing-function: linear;
            -webkit-animation: scaler 4s infinite;  /* Chrome, Safari, Opera */
            -webkit-animation-timing-function: linear;  /* Chrome, Safari, Opera */
            animation: scaler 4s infinite;
            animation-timing-function: linear;
        }
        &.ring2 {
            -webkit-animation-timing-function: linear;
            -webkit-animation: scaler 4s infinite;  /* Chrome, Safari, Opera */
            -webkit-animation-timing-function: linear;  /* Chrome, Safari, Opera */
            animation: scaler 4s infinite;
            animation-timing-function: linear;
            -webkit-animation-delay: 1s;
        }
        &.ring3 {
            -webkit-animation-timing-function: linear;
            -webkit-animation: scaler 4s infinite;  /* Chrome, Safari, Opera */
            -webkit-animation-timing-function: linear;  /* Chrome, Safari, Opera */
            animation: scaler 4s infinite;
            animation-timing-function: linear;
            -webkit-animation-delay: 2s;
        }
        &.ring4 {
            -webkit-animation-timing-function: linear;
            -webkit-animation: scaler 4s infinite;  /* Chrome, Safari, Opera */
            -moz-animation: scaler 4s infinite;  /* Chrome, Safari, Opera */
            animation: scaler 4s infinite;  /* Chrome, Safari, Opera */
            -webkit-animation-timing-function: linear;  /* Chrome, Safari, Opera */
            animation: scaler 4s infinite;
            animation-timing-function: linear;
            -webkit-animation-delay: 3s;
        }
    }
}

.canv-over {
    position: absolute;
    display: flex;
    z-index: 10;
    width: 100%;
    height: 100%;

    img {
        position: absolute;

        &.im1 {
            top: 50px;
            left: 25px;
            width: 105px;
            height: 105px;
        }
        &.im2 {
            bottom: 84px;
            right: 60px;
            width: 80px;
            height: 80px;
        }
        &.ipm {
            top: -12px;
            left: 25%;
            width: 220px;
        }
        &.igrp {
            bottom: -10px;
            right: 110px;
            width: 210px;
        }
        &.ieli {
            top: 34%;
            left: 75%;
            height: 230px;
            width: 230px;
        }
        &.iblo {
            bottom: -10px;
            left: 20px;
        }
        &.ibli {
            bottom: 30%;
            left: 40px;
            opacity: 0;
        }
        &.ivbr {
            bottom: 10px;
            right: 100px;
            width: 25px;
        }
        &.ivbs {
            top: 41%;
            left: 10px;
        }
        &.ivbg {
            bottom: 40%;
            right: 15px;
            width: 25px;
        }
        &.ivby {
            top: 0px;
            left: 20px;
            width: 30px;
        }
    }
}

[data-theme='dark'] {
    .fxlt2 {
        img.dmode {
            display: flex !important;
        }
    }
}

.pad0 {
    padding: 0px !important;
}

.hero-sec-fig {
    // background-color: #ddd;
    width: 600px;
    height: 600px;
    min-width: 600px;
    overflow: hidden;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    figure {
        // filter: blur(2px);
        // background: #000;
        left: -20px;
    }

    .eye-case {
        position: absolute;
        top: 139px;
        width: 66px;
        left: 200px;

        .eye {
            width: 100%;
            height: 26px;
            position: absolute;
            z-index: 2;
            &.b { top: 0px; height: 9px; z-index: 4; width: 25px; left: 42%; }
            &.o { top: 0px; height: 15px; z-index: 4; }
            &.i { top: 5px; height: 15px; z-index: 4; }
            &.o-alt { top: 10px; transform: rotate(180deg); height: 15px; z-index: 1; width: 97%; }
            &.i-alt { top: 13px; transform: rotate(180deg); height: 15px; z-index: 1; width: 97%; }
            &.d { top: 11px; left: 0; height: 16px; z-index: 2; width: 30px; }
            &.w { top: 5px; height: 20px; z-index: 0; }
            &.e { top: 6px; height: 24px; z-index: 3; width: 25px; left: 42%; }
        }
        &.l {
            top: 107px;
            width: 46px;
            left: 136px;
            // top: 140px;
        }
        &.alt {
            left: 133px;
            width: 55px;
            transform: scale(0.85);
            top: 107px;

            .e {
                left: 30%;
                height: 22px;
            }
            .b {
                left: 30%;
                height: 8px;
                transform: rotate(10deg);
            }
            .i-alt {
                top: 8px;
                transform: rotate(0deg);
            }
        }
        &.blk {
            .eye {
                // animation-duration: 1.5s;
                animation-duration: 250ms;
                animation-iteration-count: linear;
                animation-timing-function: ease-in-out;
                &.b { animation-name: eyblinking_b; }
                &.o { animation-name: eyblinking_o; }
                &.i { animation-name: eyblinking_i; }
                &.d { animation-name: eyblinking_d; }
                &.o-alt { animation-name: eyblinking_o2; }
                &.i-alt { animation-name: eyblinking_i2; }
                &.i-alt.li { animation-name: eyblinking_li; }
                &.e { animation-name: eyblinking_e; }
                &.e.li { animation-name: eyblinking_le; }
                &.w { animation-name: eyblinking_w; }
            }
        }
    }

    .elipses {
        position: absolute;
        width: 600px;
        height: 600px;
        top: 20px;
    }
    
    .trg, .rsq, .blo, .vbs, .triy, .xx, .blo2, ._eli {
        position: absolute;
    }

    ._eli {
        top: 38px;
        left: 165px;
        z-index: 10;
        &.l {
            left: 40px;
            animation-name: eli_trace;
            animation-duration: 10s;
            animation-iteration-count: infinite;
            animation-timing-function: linear;
        }
    }

    .trg {
        top: 10px;
        left: 25px;
        width: 30px;
    }

    .rsq {
        top: 40%;
        width: 20px;
        left: 25px;
    }

    .blo {
        bottom: 10px;
        left: 10px;
    }

    .blo2 {
        top: 40%;
        z-index: 10;
        left: 75px;
    }

    .vbs {
        bottom: 40px;
        right: 100px;
    }

    .triy {
        bottom: 30%;
        right: 20px;
        width: 30px;
    }

    .xx {
        top: 90px;
        right: 40px;
        z-index: 10;
    }

    .rectb, .rectr {
        position: absolute;
        left: 0;
        top: 0;
        animation-duration: 2s;
        &.rectb {
            z-index: 1;
            animation-name: rectb;
            &.l {
                transform: translate(7px, -12px);
            }
        }
        &.rectr {
            z-index: -1;
            animation-name: rectr;
            &.l {
                transform: translate(-12px, 7px);
            }
        }
    }
}

@keyframes rectb {
    from {
        transform: translate(0px, 0px);
    }
    to {
        transform: translate(7px, -12px);
    }
}

@keyframes rectr {
    from {
        transform: translate(0px, 0px);
    }
    to {
        transform: translate(-12px, 7px);
    }
}

@keyframes eli_trace {
    0% {
        left: 40px;
        top: 38px;
    }
    25% {
        left: 40px;
        top: 460px;
    }
    50% {
        left: 400px;
        top: 460px;
    }
    75% {
        left: 400px;
        top: 40px;
    }
    100% {
        left: 40px;
        top: 38px;
    }
}

@keyframes eyblinking_b {
    from { top: 0px; height: 9px; }
    60%  { top: -2px; height: 11px; }
    to   { top: 0px; height: 9px; }
}

@keyframes eyblinking_o {
    from { top: 0px; height: 15px; }
    60%  { top: 6px; height: 4px; }
    to   { top: 0px; height: 15px; }
}

@keyframes eyblinking_i {
    from { top: 5px; height: 15px; }
    60%  { top: 8px; height: 4px; }
    to   { top: 5px; height: 15px; }
}

@keyframes eyblinking_d {
    from { top: 11px; opacity: 1; height: 16px; }
    60%  { top: 9px; opacity: 0.6; height: 7px; }
    to   { top: 11px; opacity: 1; height: 16px; }
}

@keyframes eyblinking_o2 {
    from { height: 15px; opacity: 1; }
    60%  { height: 4px; opacity: 0.5; }
    to   { height: 15px; opacity: 1; }
}

@keyframes eyblinking_i2 {
    from { top: 13px; height: 15px; }
    60%  { top: 12px; height: 4px; }
    to   { top: 13px; height: 15px; }
}

@keyframes eyblinking_li {
    from { top: 8px; height: 15px; }
    60%  { top: 9px; height: 4px; }
    to   { top: 8px; height: 15px; }
}

@keyframes eyblinking_e {
    from { top: 6px; height: 24px; opacity: 1; }
    60%  { top: -1px; height: 14px; opacity: 0.7; }
    to   { top: 6px; height: 24px; opacity: 1; }
}

@keyframes eyblinking_le {
    from { top: 6px; height: 22px; opacity: 1; }
    60%  { top: -1px; height: 11px; opacity: 0.7; }
    to   { top: 6px; height: 22px; opacity: 1; }
}

@keyframes eyblinking_w {
    from { top: 5px; height: 20px; opacity: 1; }
    60%  { top: 15px; height: 3px; opacity: 0.7; }
    to   { top: 5px; height: 20px; opacity: 1; }
}

.lintl, .lintr {
    width: 0px;
    position: absolute;
    overflow: hidden;
    top: 40px;
    transition: 1.5s ease-in all;
    &.anm {
        width: calc(50% - 70px);
    }
}

.lintd {
    position: absolute;
    overflow: hidden;
    top: 25px;
    left: 40px;
    height: 0px;
    transition: 1.5s ease-in all;
    &.anm {
        height: 100px;
    }
}

.lintl { left: 1px; }
.lintr { right: 0px; }

.cl-tx {
    height: 80%;
    display: inline-flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}

#root:not(.home-page) {
    // .pbold {
    //     margin-bottom: 1.5rem;
    // }
    .swae {
        background: linear-gradient(88.21deg, #7D97F4 5.51%, #03259C 141.95%) !important;
    }
}

.cl-im {
    position: relative;
    top: 25px;
}

.colsec {
    padding-bottom: 0px;
}

.fxlt2 .ellc {
    display: flex;
    width: 100%;
    margin-bottom: 5px;
    flex-direction: row;
    position: relative;
    justify-content: center;
    align-items: center;
}

.fxlt2 .elli {
    margin-left: -15px;
}

.fxlt2 .mt-5 {
    margin-top: 3rem !important;
    padding: 12px;
    padding-top: 15px;
}

.tns-controls {
    z-index: 1000000 !important;
}

.tns-controls [data-controls] {
    position: absolute !important;
    top: calc(50% + 20px) !important;
    z-index: 100000 !important;
    height: 60px !important;
    width: 60px !important;
    border-radius: 50% !important;
    border: 0px !important;
    cursor: pointer !important;
    background: #7D97F4 !important;
    &:disabled {
        opacity: 0 !important;
    }
}

.ppsydiv .tns-controls [data-controls] {
    background: #fff !important;
    // top: calc(50% - 30px) !important;
}

// .testidiv .tns-controls [data-controls] {
//     top: calc(50% - 30px) !important;
// }

.tns-item {
    position: relative;
    justify-content: center;
    align-items: center;
}
.ppsydiv .tns-controls [data-controls=prev] {
    opacity: 0;
    left: 50px !important;
    transform: rotate(180deg);
}
.ppsydiv .tns-controls [data-controls=next] {
    right: 50px !important;
}

.zin {
    z-index: 0 !important;
}

.trans-1 {
    transition: 1s ease-in-out all;
}

.tns-item {
    height: 100%;
}

.testidiv .tns-controls [data-controls=prev] {
    opacity: 0;
    left: 3% !important;
    transform: rotate(180deg);
    top: calc(50% + 4rem) !important;
}

.testidiv .tns-controls [data-controls=next] {
    right: 3% !important;
    top: calc(50% + 4rem) !important;
}

[data-theme='dark'] .tns-controls [data-controls] {
    background: #fff !important;
}

.tns-item .play {
    z-index: 10000;
    cursor: pointer;
    position: absolute;
    top: calc(50% - 44px);
    left: calc(50% - 44px);
}

@media (min-width: 1200px) {
    .crac.man {
        max-width: 100%;
    }
}

.lrs {
    position: absolute;
    right: 0px;
    opacity: 0.9;
    top: 184px;
    width: 200px;
    height: 223px;
    z-index: 10000;
    bottom: 0px;
    transition: 500ms ease-in-out;
}

.tpb {
    .more {
        display: none;
        transition: 1s ease-in all;
    }
    span.r {
        display: inline;
    }
    span.l {
        display: none;
    }
    &.r {
        .more {
            display: inline;
        }
        .dots {
            display: none;
        }
        span.l {
            display: inline;
        }
        span.r {
            display: none;
        }
        // .rdm {
        //     display: none !important;
        // }
        // .pbold {
        //     margin-bottom: 0px !important;
        // }
    }
}

.lrs2 {
    position: absolute;
    left: 0px;
    opacity: 0.7;
    top: 0px;
    transform: rotate(180deg);
    width: 100px;
    height: 100%;
    bottom: 0px;
    transition: 500ms ease-in-out;
}

.pbg {
    position: absolute;
    top: -15px;
    left: 0;
    z-index: 0;
}

.lrs2:hover, .lrs2:hover {
    opacity: 1;
}

.fxlt3, .fxlt4 {
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 250px;
    height: 170px;
    background: #FFFFFF;
    margin: 10px;
    border-radius: 8px;
    box-shadow: 0px 16px 32px rgba(0, 0, 0, 0.16);
    justify-content: flex-start;
    padding-top: 35px;
}

.fspc {
    position: absolute;
    bottom: -110px;
    left: 20px;
    font-weight: 200;
    font-size: 16px !important;
}

.fxlt4 {
    background: transparent;
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow: none;
    height: auto;
    width: 380px;
    padding: 1.5em;
}

[data-theme='dark'] .fxlt3 {
    background: #1D2230;
}

[data-theme='dark'] .fxlt4 {
    border: 1px solid rgba(255, 255, 255, 0.1);
}

.fxlt3 b, .fxlt4 b {
    font-size: 16px;
}

.fxlt4 b {
    font-size: 16px;
    margin-top: 15px !important;
}

.fxlt4 span {
    margin-bottom: 10px !important;
}

.fxlt2 .nox {
    font-weight: 600;
    font-size: 48px;
    position: absolute;
    z-index: 10;
    left: calc(50% - 7px);
    top: 10px;
    font-weight: 600;
    font-size: 48px;
    line-height: 140%;
}

// .crac.man, .man.pr,
// ._col1 .dcenter img,
// ._col2 .dcenter img,
// ._col3 .dcenter img,
// .testimony .rk img,
// ._clogo
// {
//     width: fit-content;
//     height: fit-content;
// }

.f200 {
    font-weight: 200;
}

.f400 {
    font-weight: 400;
}

.m2 {
    display: flex;
    align-items: flex-start;
    flex-direction: row;
    justify-content: flex-end;
}

.m2 .no {
    font-size: 40px;
    font-weight: 600;
    margin-right: 70px;

}

.m2 .line {
    background: rgba(253, 41, 181, 0.53);
    margin-left: 50px;
    opacity: 0.9;
    width: 3px;
    margin-right: 1em;
    margin-top: 0px;
    height: 100%;
}

.pml {
    /* border-left: 4px solid rgba(253, 41, 181, 0.53);; */
    padding-top: 20px !important;
    padding-left: 60px !important;
}

.px-lg-5 {
    padding-right: 1rem !important;
    max-width: 90% !important;
    padding-left: 1rem !important;
}

.kblock {
    display: flex;
    height: 3em;
    &.sm {
        height: 1.5em;
    }
}

.kblock-sm {
    display: none;
}

@media (max-width: 767px) {
    .kblock {
        display: none !important;
    }
    
    .kblock-sm {
        display: flex;
        height: 2em;
        // background: #ccc;
        &.sm {
            height: 1.5em;
        }
    }
}

#clients {
    .px-lg-5 {
        padding-left: 0px !important;
        padding-right: 0px !important;
    }
}

.slick-slider .slick-prev {
    display: block;
    background: url(../img/vl.png) no-repeat;
    position: absolute;
    bottom: unset;
    cursor: pointer;
    left: calc(50% - 30px);
    border: 0px !important;
}

.slick-slider .slick-next {
    display: block;
    background: url(../img/vr.png) no-repeat;
    position: absolute;
    bottom: unset;
    cursor: pointer;
    right: calc(50% - 30px);
    border: 0px !important;
}

.slick-slider .slick-next,
.slick-slider .slick-prev {
    top: 30px !important;
}

.slick-initialized .slide {
    display: inline-flex;
    justify-content: center;
}

.multiple-items, .multiple-items-tab, .multiple-items-alt {
    background: transparent;
    transition: 500ms ease-in all;

    .mt-5 {
        text-align: center;
    }
}

.testimony .tns-item {
    height: 255px !important;
}

.cm-box, .cm-box-2 {
    padding: 1.5em;
    border: 1px solid #f5eeee;
    box-shadow: 0px 10px 20px rgba(41, 41, 42, 0.07);
    border-radius: 8px;
    margin: 0px 20px;
    background: #fff;
    overflow: hidden;
}

center.cent, center.flexx {
    z-index: 1;
}

.cm-box-2.alt {
    background-color: transparent;
    border: 1px solid rgba(29, 34, 48, 0.1);
    border-radius: 0px;
    width: calc(100% - 15px);
    margin: 10px;
    box-shadow: none;
    height: fit-content;
    min-height: 266px;
}

[data-theme='dark'] {
    .cm-box-2.alt {
        background-color: transparent;
        border: 1px solid #dddddd12;
        border-radius: 0px;
        width: calc(100% - 15px);
        margin: 10px;
    }
}

.tesa {
    position: absolute;
    transform: rotate(180deg);
    left: 0px;
    opacity: 0;
    height: auto;
    z-index: 2;
}

.table.cs {
    th {
        &>b, &>span:not(.not-responsive) {
            text-align: left;
            justify-content: flex-start;
            display: flex;
            padding: 0px;
        }
    }
    td {
        &>span:not(.not-responsive) {
            text-align: center;
            justify-content: center;
            display: inline-flex;
            padding: 0px;
            font-weight: 400;
            flex-direction: column;
        }
    }
}

.tesb {
    position: absolute;
    right: 0px;
    height: auto;
    z-index: 2;
    pointer-events: none !important;
}

.accordion {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.accr {
    width: 780px;
    max-width: 100%;
    display: flex;
    padding: 0px;
    justify-content: flex-start;
    flex-direction: column;
    * {
        transition: 1s ease-in all;
    }
}

.lac {
    width: 100%;
    margin-top: 5px;
    margin-bottom: 5px;
    position: relative;
}

.accr label {
    cursor: pointer;
    padding: 14px 0;
    font-weight: 600;
    font-size: 24px;
    line-height: 140%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.accr span.acs {
    padding: 12px 0 1em;
    font-weight: 400;
    font-size: 18px;
    line-height: 140%;
    transition: 1s ease-in all;
    line-height: 1.65 !important;
}

.xrw {
    display: inline-flex !important;
    justify-content: center !important;
}

.nav-link {
    cursor: pointer !important;
}

.btn-check:focus + .btn-primary, .btn-primary:focus {
    box-shadow: none !important;
}

.tp_star {
    margin: 1px;
}

.swae {
    /* width: auto;
    padding-left: 55px;
    padding-right: 55px; */
    font-size: 16px;
    height: 65px;
    width: 260px;
    font-weight: 400;
    text-align: center;
    position: relative;
    transition : .6s;
    color: #fff !important;
    .light {
        position : relative;
        width : 50px;
        height: 100px;
        transition : .7s;
        background-color: rgba(255, 255, 255, 1);
        transform: skew(-20deg);
        left: 260px;
    }
    &:hover {
        transform: scale(1.03);
        .light {
          background-color: rgba(255, 255, 255, 0.2);
          transform: skew(-20deg) translate(260px, -65px);
          transition : .7s;
        }
    }
}

#navbarResponsive {
    .swae {
        .air {
            right: -9px !important;
            width: 56px;
            top: -21px !important;
            position: absolute;
        }
    }
}

.flexx.w100 {
    justify-content: center !important;
}

.flexx.w100 .flexy {
    margin-left: 0px !important;
    padding-left: 0px !important;
}

.cm-box-2 {
    width: 100%;
}

.pm-box {
    display: flex;
}

.pm-box-2 {
    list-style: none;
    padding-left: 0px;
    width: 100%;
    display: flex;
    overflow: auto;
}

.pm-box-2 li {
    min-width: 80vw;
    display: inline-flex;
    width: 25%;
}


img.ch {
    position: absolute;
    z-index: -1;
    left: 0;
    top: 2%;
}

// img.ch {
//     position: absolute;
//     z-index: -1;
//     left: -105px;
//     width: calc(100% + 175px) !important;
//     right: 0;
//     top: -20px;
// }

// .jts > figure {
//     position: relative;
//     display: flex;
//     flex-direction: row;
//     justify-content: center;
// }
.jts > figure {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    position: relative;
    // left: -1em;
}

body:not([data-theme='dark']) {
    ::-webkit-scrollbar-thumb:not(.pm-box-2) {
        background: #e0e0e0 !important;
    }
    ::-webkit-scrollbar-track:not(.pm-box-2) {
        background: #f9f9f9 !important;
    }
}

.pm-box-2 {
    &::-webkit-scrollbar { width: 5px; height: 2px; }
    &::-webkit-scrollbar-track { background: transparent !important; }
    &::-webkit-scrollbar-thumb { background: transparent !important; }
    &::-webkit-scrollbar-thumb:hover { background: transparent !important; }
}

.dfl {
    display: inline-flex;
    justify-content: flex-start;
    align-items: space-between;
    flex-direction: column;
    padding-top: 4px;
}

.pinfo {
    width: 100%;
    display: flex;
    margin-top: 5px;
    flex-direction: row;
    margin-bottom: 1.5em;
    justify-content: flex-start;
}

.pinfo img, .pinfo .sb-avatar {
    width: 50px;
    height: 50px;
    margin-right: 1em;
}

#root {
    .testimony {
        .pinfo {
            .sb-avatar {
                * {
                    color: #fff !important;
                    font-size: 22px;
                    font-weight: 600;
                }
            }
        }
    }
}

.pinfo h4, .pinfo strong {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 5px;
}

.pinfo span {
    font-size: 12px;
    font-weight: 400;
}

.h4 {
    display: block;
    margin-block-start: 1.33em;
    margin-block-end: 1.33em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
}

.bc {
    position: absolute;
    left: 11%;
    z-index: -2;
}

.rk {
    position: absolute;
    right: 14%;
    bottom: 0px;
    z-index: 2;
    &.responsive {
        transform: scale(.5);
        right: -36px;
        bottom: -58px;
    }
}

.fxltp {
    display: flex;
    justify-content: center;
    span {
        padding: 15px 15px;
        font-size: 16px;
        margin: 0 8px;
        z-index: 1;
        border-radius: 8px;
        display: inline-flex;
        cursor: pointer;
        width: max-content;
        // transition: 250ms ease-in-out background-color;

        padding: 12px 1rem;
        border-radius: 8px;
        font-weight: 600;

        &.active, &:hover {
            color: #fff;
            font-weight: 600;
            transition: 50ms ease-in-out background-color;
            // background: linear-gradient(88.21deg, #7D97F4 5.51%, #03259C 141.95%) !important;
            // background-color: #627CDB;
        }
        &:hover {
            cursor: pointer;
        }
    }

    &.hov, &:not(.mn, .sp) {
        span {
            &.active, &:hover {
                background-color: #627CDB;
            }
            &:not(.active):hover {
                color: var(--color);
            }
        }
    }

    &.mn {
        span {
            background-color: #627cdb2b;
            color: #0000008a;
            margin: 0 5px !important;
            &:hover {
                color: #000;
                font-weight: 600;
                // transition: 50ms ease-in-out background-color;
                background-color: #627cdb2b;
            }
            &.active {
                color: #fff !important;
                font-weight: 600;
                // transition: 50ms ease-in-out background-color;
                background-color: #627CDB !important;
            }
        }
    }

    &.sp {
        span {
            text-transform: uppercase;
            &.s {
                &.active, &:hover {
                    background-color: #627CDB;
                }
            }
            &.p {
                &.active, &:hover {
                    background-color: #000000;
                }
            }
        }
    }
    ._carvl, ._carvr {
        display: inline-flex;
        background-color: transparent;
        border: 0px solid transparent;
        font-size: 16px;
        position: relative;
        font-weight: 600;
        cursor: pointer;
        height: 100%;
        align-items: center;
        justify-content: center;
        color: #545fb6;
        &:disabled {
            opacity: 0;
        }
        img {
            height: 25px;
        }
    }
    ._carvl {
        // top: -22px;
        // transform: rotate(180deg) scale(2);
    }
}

.yfb {
    position: absolute;
    bottom: 10px;
    // transform: rotate(-42deg);
    // right: 10px;
    transform: rotate(-45deg);
    right: 8px;
    z-index: -1;
    .yellow-flame-body {
        position: relative;
        // transform: scaleX(.16) scaley(.35);
        transform: scaleX(0.4) scaley(0.6);

        .white {
            left: 15px !important;
            top: 20px !important;
            width: 30px !important;
            height: 30px !important;
            // background: #cfa62c !important;
            // box-shadow: 0px 0px 9px 4px #cfa62c !important;
            background: #fdc92e !important;
            box-shadow: 8px 0px 9px 4px #fdc92e !important;
        }
        .yellow {
            // background: #d3a92b !important;
            // box-shadow: 0px 0px 9px 4px #d3a92b !important;
            background: #f8bf12 !important;
            box-shadow: 0px 0px 9px 4px #f8bf12 !important;
        }
        .red {
            // border: 1px solid #e4b836 !important;
            // background: #e4b836 !important;
            // box-shadow: 0px 0px 5px 4px #e4b836 !important;
            border: 1px solid #ffbf00 !important;
            background: #ffbf00 !important;
            box-shadow: 0px 0px 5px 4px #ffbf00 !important;
        }
        .orange {
            display: none;
            background: #ffbf00 !important;
            box-shadow: 0px 0px 9px 4px #ffbf00 !important;
        }
    }
}

.pz {
    position: absolute;
    right: 10%;
    top: 140px;
    z-index: -1;
}

.bub {
    position: absolute;
    width: auto;
    right: 5%;
    top: -10px;
    min-width: 80%;
    height: 100%;
    z-index: -1;
}

.bub.w100 {
    width: 100% !important;
}

#contact .container {
    max-width: 85% !important;
}

.soc {
    margin-left: 1em;
    width: 35px;
    height: 35px;
    cursor: pointer;
    position: relative;
    top: -2px;
    display: inline-flex;
}

.pb2 span {
    display: inline-block;
    margin-top: 3px;
}

.mb-col {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
}

.mb-col span,
.mb-col a {
    display: inline-flex;
    margin-top: 5px;
    cursor: pointer;
    color: #000;
    text-decoration: none !important;
    line-height: 2;
    font-size: 14px;
    font-weight: 400;
    text-align: left;
    transition: 250ms ease-in all;
    &:hover {
        text-decoration: underline !important;
    }
}

a[disabled] {
    opacity: 0.8 !important;
    filter: blur(1px) !important;
    * {
        opacity: 0.8 !important;
        filter: blur(1px) !important;
    }
}

.wth2 {
    text-align: left;
    font-weight: 600;
    font-size: 18px;
}

.bluryy {
    filter: blur(3px);
}

.footer-0 {
    padding: 3rem 0;
}

.fip {
    height: 50px;
    width: 50px;
    margin: 0 20px;
    cursor: pointer;
}

.footer {
    background: rgba(18, 18, 18, 0.05) !important;
}

.appointment-page {
    .masthead {
        .hero-sect {
            justify-content: flex-end !important;
            .hero-sec-fig {
                top: -40px;
                left: -50px;
                overflow: unset;
                img {
                    position: absolute;
                }
                .aph-green {
                    top: 60px;
                    left: 51%;
                }
                .aph-yellow {
                    bottom: 70px;
                    left: 60px;
                }
                .aph-pink {
                    bottom: 20px;
                    left: 20%;
                }
                .aph-blue {
                    bottom: 30px;
                    right: 20%;
                }
                .aph-pop {
                    right: -15px;
                    bottom: 230px;
                    z-index: 10000000;
                }
                .aph-eli {
                    position: absolute;
                    left: -35px;
                    width: 280px;
                    height: 280px;
                    top: 55px;
                    bottom: 50%;
                    border-radius: 50%;
                    background: radial-gradient(45.18% 45.18% at 37.65% 25.18%, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */, linear-gradient(132.82deg, rgba(255, 255, 255, 0.47) 14.64%, rgba(255, 255, 255, 0) 85.8%), #3959F1;
                    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
                    filter: blur(6px);
                }
                .aph-eli-sm {
                    position: absolute;
                    right: 25px;
                    top: 53%;
                    width: 182px;
                    height: 182px;
                    border-radius: 50%;
                    background: radial-gradient(45.18% 45.18% at 37.65% 25.18%, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */, linear-gradient(132.82deg, rgba(255, 255, 255, 0.47) 14.64%, rgba(255, 255, 255, 0) 85.8%), #3959F1;
                    box-shadow: 50px 100px 100px rgba(125, 151, 244, 0.26);
                    filter: blur(24px);
                }
                .amd-sar {
                    img {
                        top: 4px;
                    }
                }
                figure {
                    width: 100%;
                    position: relative;
                    z-index: 1;
                    height: 370px;
                    left: 0px;

                    .avs1 {
                        width: auto;
                        &.sm {
                            display: none;
                        }
                    }
                    .avs2 {
                        left: 24px;
                        top: 24px;
                        &.sm {
                            display: none;
                        }
                    }
                }
            }
        }
    }
}

[data-theme='dark'] .mcl:not(.sm, .mh2), [data-theme='dark'] .mcl *:not(.sm, .mh2, .mh2 *) {
    color: var(--color) !important;
}

[data-theme='dark'] img.plo {
    display: block;
}

header.masthead {
    // min-height: 100vh !important;
    height: fit-content !important;
    padding-top: 4.5rem;
    padding-bottom: 2.5rem;
}

header.masthead.alt {
    display: flex;
    align-items: center;
    justify-content: center;
}

.slick-slider .slide img {
    display: block;
    position: relative;
    left: -50%;
}

.iline {
    position: absolute;
    left: 16%;
    width: 4px;
    top: 0px;
    bottom: 0px;
    height: calc(100% - 1em);
}

.hero-sect {
    position: relative; display: flex; width: calc(50vw - 1rem); height: auto;
    // min-height: calc(100vh - 200px);
    min-height: 510px;
    margin-bottom: 10px;
    width: calc(50vw - 1rem + 4px);
}

.hero-sect-2 {
    position: relative;
    position: absolute;
    right: -10px;
    right: 0px;
    top: 150px;
    height: 100%;
    display: flex;
}

.hrr {
    position: absolute;
    right: 0px;
}

.hrl {
    position: absolute;
    right: 0px;
    width: 85px;
    z-index: -2;
    top: 200px;
    box-shadow: none !important;
}

.hrf {
    position: absolute;
    right: 0px;
    width: 120px;
    z-index: -2;
    top: 10px;
    box-shadow: none !important;
}

@media (min-width: 485px) and (max-width: 769px) {
    .hrl {
        top: 37% !important;
    }
    .hrf {
        top: 4% !important;
    }
}

.fnrsp {
    margin-left: calc(100% / 12);
    &.h {
        margin-left: calc(100% / 8);
    }
}

.responsive { display: none; }

.f_18 { font-size: 18px; }
.f_20 { font-size: 20px; }
.f_24 { font-size: 24px; }
.fb22 { font-size: 22px; }
.f16 { font-size: 18px; }
.f18 { font-size: 18px; }
.f20 { font-size: 20px; }
.f22 { font-size: 22px; }
.f24 { font-size: 24px; }
.f36 { font-size: 36px; }
.f40 { font-size: 40px; }
.fl30 { font-size: 30px; }
.ft20 { font-size: 22px; }
.fl50 { font-size: 48px; }


.is-tablet, .is-mobile, .is-smalls {
    display: none !important;
}

.is-desktop, .is-bigs {
    display: inline-flex !important;
}

.is-bigs.its {
    display: block !important;
}

.flexx.tab {
    align-items: center;
    flex-direction: column;
    justify-content: center;
}

.flexx.tab.alt {
    width: 100%;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
}

.flexx.tab .flexy {
    margin-left: 0px !important;
    padding-left: 0px !important;
    margin-top: 10px;
}

@media (min-width: 992px) and (max-width: 1330px) {
    .available-days {
        transform: scale(.8);
        right: -5% !important;
    }
}

@media (max-width: 992px) {
    .dmode {
        display: none !important;
        &.responsive { display: none !important; }
        &.not-responsive { display: none !important; }
    }
    [data-theme='dark'] .dmode {
        display: inline-flex !important;
        &.responsive { display: inline-flex !important; }
        &.not-responsive { display: none !important; }
    }
    .lmode {
        display: inline-flex !important;
        &.responsive { display: inline-flex !important; }
        &.not-responsive { display: none !important; }
    }
    [data-theme='dark'] .lmode {
        display: none !important;
        &.responsive { display: none !important; }
        &.not-responsive { display: none !important; }
    }
    .plst {
        height: 100%;
    }
    
    .bub {
        position: absolute;
        width: auto;
        right: 0;
        top: 0px;
        min-width: 80%;
        height: 100%;
        z-index: -1;
    }
    .message .callout {
        font-size: 14px;
        line-height: 1.5;
    }
    .m1 .line, .m2 .line {
        height: 75%;
    }
    .m1 .no, .m2 .no {
        font-weight: 600;
        font-size: 24px;
    }
    .not-responsive { display: none !important; }
    .responsive { display: flex; }
    .pz.pzx {
        display: none !important;
    }
    img.fdf { width: 75%; }
    #mainNav {
        box-shadow: none !important;
        position: absolute;
        background-color: transparent !important;
    }
    header.masthead {
        min-height: auto !important;
        height: auto !important;
        padding-top: 50px;
    }
    .page-section {
        padding: 2rem 0;
    }
    .navbar-toggler {
        border: 0px solid !important;
        outline: none !important;
        box-shadow: none !important;
    }
    .ft12 { font-size: 12px !important;}
    .table.cs {
        // width: calc(100% - 2em);
        width: 100%;
        max-width: 100%;
    }
    .table.cs tr th:first-child {
        padding-left: 1em;
    }
    .table.cs tr th:last-child,
    .table.cs tr td:last-child {
        padding-right: 1em;
    }
    .table.cs tr th, .table.cs tr td {
        font-size: 15px;
    }
    img.fl4 { height: 20px; }
    /* .f_18, .fb22 { font-size: 16px; }
    .f18, .f_20, .f_24 { font-size: 14px; }
    .fl30 { font-size: 18px; }
    .fl50 { font-size: 24px; }
    .ft20 { font-size: 20px; }
    .f22 {
        font-size: 14px;
        margin-bottom: 0px;
    }
    .f36 {
        font-size: 24px;
    }
    .f24 {
        font-size: 18px;
    }
    .f16 {
        font-size: 16px !important;
        font-weight: 400 !important;
    }
    .f20 { font-size: 16px; } */
    .px-lg-5 {
        padding-right: 2em !important;
        max-width: 95% !important;
        padding-left: 2em !important;
    }
    .swrw {
        flex-direction: column-reverse;
    }
    .nopd {
        padding: 10px !important;
    }
    img.av {
        right: 0px;
        top: 44%;
        width: 50%;
    }
    .pricn {
        padding-bottom: 2em;
    }
    .pl1.pzz {
        height: auto !important;
        width: 50% !important;
    }
    .dcenter > img {
        width: 100%;
        margin-top: 25px;
        margin-bottom: 50px;
    }
    #resources-2 {
        padding: 0;
    }
    .mglf { margin-left: 0px !important; }
    .mglf .m1 { width: 10% !important; }
    .mglf .pbold { width: 90% !important; }
    .mglf .mglf1 { margin-bottom: 50px; }
    img.iv {
        position: absolute;
        z-index: 0;
        right: 0;
        bottom: 37%;
        width: 50%;
    }
    .pt1 {
        padding-top: 1em;
    }
    .fxlt .mt-5 {
        margin-top: 2em !important;
        margin-bottom: 14px !important;
    }
    .fxlt .mt-5 .wth {
        margin-bottom: 10px !important;
    }
    .iline {
        left: 8%;
        width: 5px;
    }
    .pml {
        padding-left: 17% !important;
        padding-right: 10% !important;
        padding-top: 0px !important;
        position: relative;
    }
    .no.responsive {
        display: inline;
        position: absolute;
        left: 3.2%;
        top: 0px;
        font-size: 30px;
    }
    .pml .pbold {
        margin-top: 10px !important;
    }
    #partner .cent {
        margin-top: 40px !important;
    }
    span.responsive {
        display: inline-flex !important;
    }
    .slick-slider .slick-prev {
        left: calc(50% - 33px);
        top: 160px;
    }
    #clients, .clients {
        min-height: 360px;
    }
    .slick-slider .slick-next {
        right: calc(50% - 33px);
        top: 160px;
    }
    .multiple-items .mt-5, .multiple-items-tab .mt-5, .multiple-items-alt .mt-5 {
        margin-top: 1.5em !important;
    }
    .footer {
        background: transparent !important;
    }
    .arr {
        /* background: rgba(18, 18, 18, 0.05) !important; */
        max-width: 100% !important;
        padding: 10px;
    }
    #footer-0 {
        padding: 1.5rem 0;
    }
    span.sm {
        font-size: 10px;
    }
    #contact {
        text-align: center;
    }
    .mts {
        margin-left: 0px !important;
        padding-left: 0px !important;
    }
    // .nopd2 {
    //     padding: 0px !important;
    // }
    .bub {
        width: 100%;
        position: absolute;
        top: -5%;
    }
    .yps {
        margin-bottom: 0px !important;
    }
    .pbold.fb22 {
        width: 80% !important;
        margin-top: 25px !important;
        margin-left: 10% !important;
    }
    .soc {
        width: 25px;
        height: 25px;
    }
    .pinfo {
        margin-bottom: 1em;
    }
    .cm-box-2 {
        padding: 1em;
    }
    img.pz {
        display: none;
    }
    img.rk {
        bottom: 0px;
        right: 2%;
    }
    img.bc {
        top: 140px;
        left: 2%;
    }
    .cm-box, .cm-box-2 {
        margin: 0px 10px;
    }
    // #contact.mcl, #contact.mcl * {
    //     color: #000 !important;
    // }
    .testimony > .container {
        max-width: 100% !important;
        width: 100% !important;
    }

    .navbar-collapse.collapse.show {
        flex-basis: 100%;
        flex-grow: 1;
        align-items: center;
        background: #fff;
    }
    .mw8 {
        display: none !important;
    }
    .navbar-collapse.collapse.show .nav-item {
        width: 100%;
    }
    .nav-item {
        margin-left: 0px !important;
    }
    .switch.mob {
        margin-top: 10px;
        height: 30px;
        // width: 50px;
        width: 56px;
    }
    .responsive {
        .switch.mob {
            margin-top: 0px;
        }
    }
    .switch.mob .slider:before {
        height: 24px;
        width: 24px;
        left: 3px;
        bottom: 3px;
        padding: 1px 2px;
    }
    input:checked + .slider:before {
        -webkit-transform: translateX(20px);
        -ms-transform: translateX(20px);
        transform: translateX(20px);
    }
    #resources > .container, #resources-2 > .container {
        max-width: 90% !important;
    }
    img.imq {
        left: -35px;
    }

    .crac.man.alt, .pr.man.alt {
        width: 100%;
    }
    .btno {
        max-width: 90%;
    }
    /* .btno {
        max-width: 80%;
        width: 200px !important;
        font-size: 12px;
        height: 45px !important;
    } */
    .pr-10{
        padding-right: 1em;
    }
    .footer {
        padding: 0px !important;
    }
    #services {
        margin-top: -50px;
    }
    /* .man.fst {
        width: 80% !important;
    }
    .available-days {
        right: 5px !important;
        bottom: 70px !important;
    } */
    #app-overlay {
        flex-direction: column;
        overflow: auto;
        min-height: 100vh;
        // background-color: #fff;
    }
    [data-theme='dark'] #app-overlay {
        // background: #1D2230 !important;
    }
    [data-theme='dark'] .mobile .app-menu {
        background: transparent !important;
    }
    /* #app-overlay::before {
        content: '';
        width: 100vw;
        position: fixed;
        height: 100vh;
        z-index: -1;
        cursor: pointer;
        background: linear-gradient(181.44deg, rgba(194, 200, 251, 0.17) 1.23%, rgba(197, 213, 247, 0.0119) 167.5%);
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
    } */
    .app-menu {
        /* height: 100vh !important; */
        /* display: flex !important; */
        box-shadow: none !important;
        position: unset !important;
        width: 100vw !important;
        min-height: auto !important;
        top: 0px !important;
        left: 0px !important;
        background-color: transparent !important;
        right: 0px !important;
        flex-direction: column !important;
        border-radius: 0px !important;
    }
    .app-menu .sub-menu {
        display: inline-flex !important;
        width: 100% !important;
        padding-top: 12px !important;
        padding-bottom: 12px !important;
        height: auto !important;
    }
    img.bbg.responsive {
        display: flex !important;
    }
    .sub-menu.active::before {
        background: #F22FB0 !important;
    }
    .sub-menu span {
        font-size: 12px !important;
    }
    .sub-menu img:not(.ud) {
        position: relative;
        top: 8px;
        width: 50px !important;
        height: 50px !important;
    }
}

.accr {
    .acs {
        .kblock-sm {
            display: flex !important;
            height: 13px !important;
        }
    }
}

/* 
@media (max-width: 525px) {
    .man.fst {
        width: 70% !important;
    }
    .available-days {
        transform: scale(.7);
        bottom: 40px !important;
    }
}

@media (max-width: 470px) {
    .man.fst {
        left: -15px !important;
        width: 80% !important;
    }
    .available-days {
        transform: scale(.5);
        right: -50px !important;
        bottom: 0px !important;
    }
}

@media (max-width: 400px) {
    .man.fst {
        width: 90% !important;
    }
    .available-days {
        transform: scale(.5);
        right: -60px !important;
        bottom: 8% !important;
    }    
}

@media (max-width: 272px) {
    .man.fst {
        width: 90% !important;
    }
    .available-days {
        transform: scale(.4);
        right: -60px !important;
        bottom: 9% !important;
    }    
}

@media (max-width: 333px) {
    img.av {
        top: unset;
        bottom: 35%;
    }
    .btn-primary {
        max-width: 90%;
        font-size: 90%;
        height: 45px !important;
    }
} */