#stats {
    padding: 5rem 0 !important;
}
#dc {
    padding: 3rem 0 !important;
}

.sass-page, .premium-page, .social-page {
    .masthead {
        padding-bottom: 100px !important;
        .mh1 {
            text-align: center;
            font-weight: 600 !important;
            font-size: 64px !important;
            line-height: 120% !important;
        }
        .plem {
            position: absolute;
            top: 18%;
            right: 14%;
        }
        .p22 {
            font-weight: 400 !important;
            font-size: 20px !important;
            line-height: 160%;
            text-align: center;
            color: #FFFFFF;
            flex: none;
            order: 1;
            align-self: stretch;
            flex-grow: 0;
        }
        .ple {
            width: 66% !important;
        }
        .hero-pbg {
            width: 100vw;
            height: 100%;
            display: flex;
            position: absolute;
            z-index: 0;
            top: 0px;
        }
        .flexx.tab.alt {
            flex-direction: row !important;
            justify-content: center !important;
            .flexy {
                margin-top: 0px !important;
            }
        }
    }
    .colsec-1 {
        margin-bottom: 6em;
        .figger {
            top: -20px;
        }
    }
    .colsec-3 {
        padding-top: 2.5em;
        margin-bottom: 4em;
    }
    &.sass-page {
        .masthead {
            .hero-pbg {
                .cn-1 {
                    left: -5em;
                    top: -5em;
                    filter: blur(4px);
                    transform: rotate(-45deg);
                    animation-name: cn_1;
                    animation-duration: 15s;
                    animation-iteration-count: infinite;
                    animation-timing-function: ease-in;
                }
                .cn-2 {
                    left: -5em;
                    top: -5em;
                    filter: blur(4px);
                    transform: rotate(-120deg);
                    animation-name: cn_2;
                    animation-duration: 30s;
                    animation-delay: 5s;
                    animation-iteration-count: infinite;
                    animation-timing-function: ease-in;
                }
                .cn-3 {
                    left: -5em;
                    top: -5em;
                    filter: blur(4px);
                    transform: rotate(-130deg);
                    animation-name: cn_3;
                    animation-duration: 20s;
                    animation-delay: 10s;
                    animation-iteration-count: infinite;
                    animation-timing-function: ease-in;
                }
                .cn-4 {
                    left: -5em;
                    top: -5em;
                    filter: blur(4px);
                    transform: rotate(-209deg);
                    animation-name: cn_4;
                    animation-duration: 25s;
                    animation-delay: 9s;
                    animation-iteration-count: infinite;
                    animation-timing-function: ease-in;
                }
                .cn-5 {
                    left: -5em;
                    top: -5em;
                    filter: blur(4px);
                    transform: rotate(-20deg);
                    animation-name: cn_5;
                    animation-duration: 15s;
                    animation-delay: 11s;
                    animation-iteration-count: infinite;
                    animation-timing-function: ease-in;
                }
                .cn-6 {
                    left: -5em;
                    top: -5em;
                    filter: blur(4px);
                    transform: rotate(-25deg);
                    animation-name: cn_6;
                    animation-duration: 22s;
                    animation-delay: 8s;
                    animation-iteration-count: infinite;
                    animation-timing-function: ease-in;
                }
            }
        }
    }
    &.premium-page {
        .masthead {
            .hero-pbg {
                & > div {
                    transform: rotate(0deg);
                    filter: blur(0);
                    transition: .8s ease-in-out all;
                    animation-name: none !important;
                    position: absolute;
                    opacity: 0;
                    z-index: 0;
                    display: inline-block;
                    height: fit-content;
                    left: 10%;
                    top: 73%;
                    .shd {
                        position: absolute;
                        bottom: -30px;
                        right: -25px;
                        width: 100px;
                        height: 100px;
                        display: none;
                        z-index: 0;
                    }
                }
                .cn-1 {
                    // left: 10%;
                    // top: 73%;
                    opacity: 1;
                    transform: scale(1);
                    filter: blur(0);
                    z-index: 10;
                    transition: .5s ease-in-out all;
                    img {
                        height: 43px;
                    }
                    .dv {
                        position: absolute;
                        height: 36px;
                        z-index: 10;
                        left: 7px;
                        top: 3px;
                        width: 40px;
                        display: flex;
                        flex-direction: column;
                        // background: red;
                        overflow: hidden;
                        div {
                            position: relative;
                            transition: 5s ease-in-out all;
                            transform: translateY(0px);
                        }
                    }
                }
                .cn-2 {
                    // left: 7%;
                    // top: 37%;
                    transition-delay: 1s;
                    img {
                        height: 45px;
                    }
                }
                .cn-3 {
                    // left: 37%;
                    // top: 60%;
                    transition-delay: 1.5s;
                    img {
                        height: 35px;
                    }
                }
                .cn-4 {
                    // left: 43%;
                    // top: 35%;
                    transition-delay: 2s;
                    img {
                        height: 35px;
                    }
                }
                .cn-5 {
                    // left: 86%;
                    // top: 73%;
                    transition-delay: 2.5s;
                    img {
                        height: 50px;
                    }
                }
                .cn-6 {
                    // left: 92%;
                    // top: 35%;
                    transition-delay: 3s;
                    img {
                        height: 38px;
                    }
                }

                &.anm {
                    & > div {
                        opacity: 1;
                    }
                    .cn-1 {
                        transform: scale(2) !important;
                        filter: blur(0) !important;
                        .dv {
                            div {
                                transform: translateY(-135px);
                            }
                        }
                        &.pus {
                            transform: scale(1);
                            filter: blur(4);
                        }
                    }
                    .cn-2 {
                        left: 7%;
                        top: 37%;
                    }
                    .cn-3 {
                        left: 37%;
                        top: 60%;
                    }
                    .cn-4 {
                        left: 43%;
                        top: 35%;
                    }
                    .cn-5 {
                        left: 86%;
                        top: 73%;
                    }
                    .cn-6 {
                        left: 88%;
                        top: 35%;
                    }
                }
            }
        }
        .colsec-1 {
            margin-bottom: 4em;
            .figger {
                top: -20px;
            }
        }
        .colsec-2 {
            .figger {
                top: -50px;
            }
        }
        .colsec-3 {
            .figger {
                top: -20px;
            }
        }
        .colsec-4 {
            .man {
                margin-top: -70px;
            }
        }
        #partner {
            margin-top: -1.5em;
        }
    }
    &.social-page {
        .masthead {
            .hero-pbg {
                &>div {
                    position: absolute;
                }
                .cn-1 { // blush
                    left: 10%;
                    top: 68%;
                }
                .cn-3 { // love
                    left: 51%;
                    top: 40%;;
                }
                .cn-5 { // note
                    left: 17%;
                    top: 19%;
                }
                .cn-6 { // what
                    left: 2%;
                    top: 35%;
                }
                .cn-4 { // mess
                    right: 0%;
                    top: 15%;
                }
                .cn-2 { // laff
                    right: 0%;
                    top: 65%;
                }
                .cn-7 { // play
                    right: 8%;
                    top: 75%;
                }
            }
        }
        .colsec-1, .colsec-3 {
            margin-bottom: 1em;
        }
        .colsec-1 {
            // overflow: hidden;
        }
    }
}



@media (min-width: 768px) {
    #clients, #tabb, #dc {
        padding: 5rem 0 !important;
    }
}

@media (min-width: 1200px) {
    #root:not(.home-page) {
        #stats {
            .col-lg-12 {
                margin: 0 auto;
                // width: 85% !important;
                width: 91% !important;
                max-width: 1440px;
                .fxlt2 .mt-5 {
                    padding: 12px 3px;
                    .text-muted {
                        // font-size: 16px !important;
                        margin-top: 10px;
                        min-width: 278px;
                    }
                }
            }
        }
        .crac.man {
            max-width: 100%;
        }
    }
}

@media (min-width: 993px) {
    #root:not(.home-page) {
        .swae {
            background: linear-gradient(88.21deg, #7D97F4 5.51%, #03259C 141.95%) !important;
        }
        .pigger.responsive {
            display: none !important;
        }
        .nav-item.mw8 {
            min-width: 100px;
            display: inline-flex;
            justify-content: center;
            align-items: center;
        }
        .table.cs {
            overflow: hidden;
        }
        img.fdf {
            width: 100% !important;
            max-width: 100% !important;
        }
        .colsec {
            .pbold.f20 {
                margin-bottom: 1.8em !important;
            }
        }
        .swae {
            font-size: 16px;
            width: 300px;
            .air {
                top: -34px;
                right: -10px;
            }
        }
        .flexx.tab.alt {
            justify-content: flex-start;
            .tpil {
                margin-left: 1em !important;
            }
        }
        .fxlt2 .ellc {
            left: -2%;
        }
        .colsec {
            .pbold.f20 { font-weight: 600 !important; }
            .pbold.f16 { font-weight: 400 !important; }
            &.colsec-4 {
                margin-top: 1em;
                margin-bottom: 4em;
            }
        }
        #clients {
            margin-top: -1em !important;
        }
        #stats {
            &.rbg-dark::before {
                background: #e0f5fe url(../img/bg/hiw-desktop-light.webp) no-repeat;
            }
            .col-lg-12 {
                .text-muted {
                    font-size: 18px !important;
                    margin-top: 10px;
                }
            }
        }
        #partner {
            margin-top: -1em !important;
            padding-top: 0em !important;
            .m2 .no {
                margin-right: 100px;
            }
            .iline {
                left: 14%;
            }
            .pml {
                padding-left: 36px !important;
            }
            .f24 {
                font-weight: 600 !important;
            }
        }
        .clients {
            &.rbg-dark::before {
                background: #e0f5fe url(../img/bg/table_light_lg.webp) no-repeat;
            }
            .tns-lazy-img {
                width: 100%;
            }
        }
        .testimony {
            .cm-box-2 {
                .pbold.f_20 {
                    font-weight: 400;
                    font-size: 18px;
                    line-height: 160%;
                }
            }
        }
        #tabb {
            .table.cs {
                min-width: 900px !important;
            }
        }
        #wps {
            &.rbg-dark::before {
                background: #e0f5fe url(../img/bg/hiw-desktop-light.webp) no-repeat !important;
            }
        }
        #services {
            img.i1 { top: 3%; }
            img.i2 { top: 3%; }
            img.i3 { top: 6%; left: 15%; }
            img.i4 { top: 6%; right: 15%; }
            .container {
                padding-left: 0px !important;
                padding-right: 0px !important;
            }
            margin-top: -40px;
        }
        #dc {
            &.rbg-dark::after {
                background: url(../img/home/dcv_light_lg.svg) no-repeat;
            }
            &.rbg-dark::before {
                background: url(../img/bg/dc-desktop-light.webp) no-repeat;
            }
            .fl50 {
                line-height: 62px !important;
            }
        }
    }
    .sales-page {
        .masthead {
            // background: linear-gradient(181.44deg, rgba(230, 194, 251, 0.4) 1.23%, rgba(197, 213, 247, 0.028) 167.5%) !important;
            // background: linear-gradient(181.44deg, rgba(230, 194, 251, 0.4) 1.23%, rgba(197, 213, 247, 0.028) 110%) !important;
            .rock {
                width: 90% !important;
                height: 90% !important;
                position: relative;
                top: -10px;
                right: -4%;
                margin-bottom: 2em;
            }
        }
        .colsec {
            &.colsec-2 {
                padding-bottom: 70px;
            }
            .man {
                width: 100%;
            }
            .crac.man:not(.alt) {
                width: calc(100% + 50px);
                height: auto;
                position: relative;
            }
            &.colsec-1 {
                .man {
                    margin-right: -100px;
                }
            }
            &.colsec-2 {
                .man {
                    margin-left: -55px;
                }
            }
            &.colsec-3 {
                .jts {
                    position: relative;
                    left: 70px;
                }
            }
            &.colsec-4 {
                .man {
                    margin-left: -55px;
                }
            }
        }
    }
    .leads-page {
        .masthead {
            .hero-sect {
                justify-content: flex-end !important;
                .hero-sec-fig {
                    top: -40px;
                }
            }
        }
        .colsec-1 {
            margin-top: -50px;
            .figger {
                top: 0px;
            }
        }
        .colsec-2 {
            margin-top: 4em;
            margin-bottom: 10px;
        }
        .colsec-3 {
            padding-top: 1em !important;
        }
        .colsec-2, .colsec-4 {
            .dcenter {
                justify-content: flex-start !important;
                .figger {
                    margin: 0 0 !important;
                }
            }
        }
    }
    .appointment-page {
        .masthead {
            padding-bottom: 0rem !important;
        }
        .colsec-1 {
            margin-bottom: 6em !important;
            .figger {
                top: -20px;
            }
        }
        .colsec-2 {
            .figger {
                margin-top: -20px !important;
            }
        }
        .colsec-3 {
            padding-top: 2.5em !important;
            margin-bottom: 2em !important;
        }
        #services {
            .accordion {
                margin-top: 2em;
            }
        }
    }
    .sass-page, .premium-page, .social-page {
        #services {
            .accordion {
                margin-top: 2em;
            }
        }
    }
    .premium-page {
        .colsec-1 {
            .f36 {
                min-width: 450px;
            }
        }
    }
    [data-theme='dark'] {
        #root:not(.home-page) {
            #stats {
                &.rbg-dark::before {
                    background: #1d2f48 url(../img/bg/hiw-desktop-dark.webp) no-repeat;
                }
            }
            .clients {
                &.rbg-dark::before {
                    background: #1d2f48 url(../img/bg/table_dark_lg.webp) no-repeat;
                }
            }
            #wps {
                &.rbg-dark::before {
                    background: #1d2f48 url(../img/bg/hiw-desktop-dark.webp) no-repeat !important;
                }
            }
            #dc {
                &.rbg-dark::after {
                    background: url(../img/home/dcv_dark_lg.svg) no-repeat;
                }
                &.rbg-dark::before {
                    background: url(../img/bg/dc-desktop-dark.webp) no-repeat;
                }
            }
        }
        .sales-page {
            .masthead {
                background: linear-gradient(181.44deg, rgba(194, 200, 251, 0.2) 1.23%, rgba(197, 213, 247, 0.014) 167.5%) !important;
            }
        }
    }
}

@media (min-width: 993px) and (max-width: 1225px) {
    // #root:not(.home-page) {
    //     .colsec {
    //         .container {
    //             .kts.nopd2.p {
    //                 padding-left: 3% !important;
    //             }
    //         }
    //     }
    // }
    .leads-page {
        .masthead {
            .hero-sec-fig {
                transform: scale(.9);
                right: -40px;
            }
        }
    }
    .sales-page {
        .colsec-4 {
            .figger {
                transform: scale(.75);
            }
        }
    }
    .sass-page, .premium-page, .social-page {
        .masthead {
            .mh1 {
                font-size: 54px !important;
            }
        }
    }
}

@media (min-width: 993px) and (max-width: 1150px) {
    .social-page {
        #partner {
            .f40 {
                .is-desktop {
                    display: none !important;
                }
            }
        }
    }
}

// 1024.scss

@media (min-width: 993px) and (max-width: 1125px) {
    .leads-page {
        .masthead {
            .hero-sec-fig {
                transform: scale(.8);
                right: -60px;
                top: -70px !important;
            }
        }
    }
}

@media (max-width: 1400px) {
    .appointment-page {
        .masthead {
            .hero-sect {
                transform: scale(.9);
                right: -50px;
            }
        }
    }
}

@media (max-width: 1330px) {
    #root:not(.home-page) {
        .flexx.tab.alt {
            .tpil {
                margin-left: 0px !important;
            }
        }
    }
}



@media (min-width: 993px) and (max-width: 1330px) {
    .appointment-page {
        .masthead {
            .hero-sect {
                transform: scale(.8);
                right: -10%;
                top: -25px;
            }
            .mh1 {
                font-size: 60px !important;
            }
            .mh2 {
                font-size: 34px !important;
            }
        }
        .colsec-1, .colsec-3 {
            .kts.nopd2.p {
                padding-left: 5% !important;
                .f36 {
                    width: 110%;
                }
            }
        }
    }
    .social-page {
        #dc {
            .fl50 {
                br {
                    display: none !important;
                }
            }
        }
    }
}

@media (min-width: 993px) and (max-width: 1150px) {
    .appointment-page {
        .masthead {
            .hero-sect {
                transform: scale(.7);
                right: -15%;
                top: -45px;
            }
            .mh1 {
                font-size: 58px !important;
            }
            .mh2 {
                font-weight: 500 !important;
                font-size: 34px !important;
            }
        }
    }
}

@media (min-width: 993px) and (max-width: 1110px) {
    .appointment-page {
        .masthead {
            .hero-sect {
                transform: scale(.7);
                right: -30%;
                top: -45px;
            }
            .mh1 {
                font-size: 54px !important;
            }
            .mh2 {
                font-weight: 500 !important;
                font-size: 34px !important;
            }
        }
    }
}

@media (min-width: 768px) and (max-width: 900px) {
    .sass-page, .premium-page, .social-page {
        &.premium-page {
            .masthead {
                .hero-pbg {
                    &.anm {
                        .cn-2 {
                            top: 30%;
                        }
                        .cn-3 {
                            left: 34%;
                            top: 40%;
                        }
                        .cn-4 {
                            left: 57%;
                            top: 28%;
                        }
                        .cn-6 {
                            left: 88%;
                            top: 25%;
                        }
                    }
                }
            }
        }
    }
}

@media (min-width: 768px) and (max-width: 992px) {
    #root:not(.home-page) {
        .switch.mob {
            input:checked + .slider:before {
                transform: translateX(20px) !important;
            }
        }
        .rock {
            height: 500px;
            width: 600px;
            margin-left: 114px;
            position: absolute;
            right: -300px;
        }
        .figger {
            margin: unset !important;
            margin-bottom: 2em !important;
        }
        .hmd {
            width: 70%;
            min-width: 680px;
        }
        .masthead {
            padding-bottom: 4rem;
            .hero-sect {
                transform: scale(.75);
                .not-mobile {
                    canvas {
                        width: 500px !important;
                        height: 500px !important;
                    }
                }
            }
            .tpil {
                margin: 0px auto !important;
                margin-left: 3em !important;
                width: 100% !important;
            }
        }
        .testimony {
            height: 550px !important;
            // height: 700px !important;
            margin-top: -3em !important;
            &._mx {
                max-height: 525px !important;
            }
            .tesb {
                opacity: .9;
                width: 120px;
            }
            .my-slider {
                padding-top: 240px;
                height: 560px;
                &.pd {
                    padding-left: 60px;
                }
                .myslider {
                    height: 300px;
                }
            }
            h2.f40 {
                position: absolute;
                top: 50px;
                width: 100%;
                z-index: 20;
                font-size: 36px !important;
            }
            .pinfo span {
                &.sm {
                    font-size: 12px;
                }
            }
            p.f22 {
                position: absolute;
                top: 170px;
                width: 100%;
                z-index: 20;
                font-size: 18px !important;
            }
            .cm-box-2 {
                .pbold.f_20 {
                    font-size: 18px !important;
                    font-weight: 400;
                    line-height: 28.8px;
                }
            }
            .cm-box-2.alt {
                margin: 10px 5px;
            }
            #testy-sect {
                height: 560px;
                .myslider {
                    height: 266px;
                    .slide {
                        width: 323px;
                        max-width: 323px;
                        height: 266px;
                        display: flex;
                        justify-content: center;
                        align-items: flex-start;
                    }
                }
            
                .btn {
                    position: absolute;
                    z-index: 100;
                    top: 320px !important;
                }
                // RMORE
                height: 620px;
                .myslider {
                    height: 550px;
                    .slide {
                        height: 430px;
                    }
                }
                .btn {
                    top: 375px !important;
                }
            }
        }
        .nopd2 {
            padding-left: 0px !important;
            padding-right: 0px !important;
        }
        .pbold {
            line-height: 25px;
        }
        .colsec {
            .pbold.f20 {
                margin-bottom: 1.8em !important;
            }
            .man {
                width: 70%;
            }
            .f36 {
                margin-bottom: 19px !important;
            }
            .kts {
                .pbold.f20 {
                    font-size: 20px;
                    font-weight: 600 !important;
                }
            }
            .flexx.tab {
                margin-top: -50px !important;
            }
            .flexy.tpil {
                margin-left: 3em !important;
                transform: scale(.8);
            }
            &.colsec-4, &.colsec-2 {
                .flexx.tab {
                    margin-left: 7px !important;
                }
            }
        }
        .clients {
            .lrs {
                top: 168px;
                height: 162px;
            }
            &.rbg-dark::before {
                background: #e0f5fe url(../img/bg/table_light_lg.webp) no-repeat;
            }
            .tns-lazy-img {
                width: 100%;
            }
            .tns-controls [data-controls] {
                position: absolute !important;
                top: calc(50% + 35px) !important;
            }
            .pbg {
                top: 10px;
                opacity: 1;
                width: 221px;
                height: 221px;
                &.dmode {
                    opacity: 0.2;
                }
            }
            .monitor {
                right: -40px;
                width: 175px;
                opacity: 0.5;
            }
        }
        #wps {
            height: 380px !important;
            &.rbg-dark::before {
                background: #e0f5fe url(../img/bg/clients-md-light.png) no-repeat;
            }
            .f40 {
                font-size: 36px !important;
                top: 55px !important;
            }
            .my-slider {
                &.pd {
                    padding-left: 60px !important;
                }
                height: 380px;
                padding-top: 150px;
                position: absolute;
                .myslider {
                    height: 178px;
                    .slide {
                      width: 300px;
                      height: 178px;
                    }
                }
                .btn {
                    top: calc(150px + 115px - 61px) !important;
                }
            }
        }
        #clients {
            margin-top: -2em !important;
        }
        #stats {
            &.rbg-dark::before {
                background: #e0f5fe url(../img/bg/hiw-md-light.png) no-repeat;
            }
            .col-lg-12 {
                .text-muted {
                    font-size: 18px !important;
                    margin-top: 10px;
                }
                .cw1, .cw2 {
                    margin-bottom: 35px;
                }
            }
        }
        #services {
            margin-top: 30px;
            padding-top: 20px;
            img.i1 {
                left: 5%;
            }
            img.i2 {
                right: 5%;
            }
            img.i3 {
                top: 100px;
                left: 10%;
            }
            img.i4 {
                top: 120px;
                right: 16%;
            }
            img.i5 {
                left: 4%;
            }
            img.i6 {
                right: 3%;
            }
            .f40 {
                padding-top: 0px;
                font-size: 36px;
                font-weight: 500;
            }
            .plink {
                font-size: 16px;
            }
            .container {
                padding-left: 48px !important;
                padding-right: 48px !important;
            }
            .accordion {
                margin-top: 2em;
            }
        }
        #partner {
            margin-bottom: 1.5em;
            .iline {
                left: 120px;
            }
            .pml {
                padding-left: 200px !important;
            }
            .no.responsive {
                font-weight: 600;
                font-size: 40px;
                left: 70px;
                top: -3px;
            }

            .f40 {
                font-size: 36px;
            }
            .f24 {
                font-size: 24px !important;
                font-weight: 600 !important;
                &._ns {
                    ._redsp._responsive {
                        display: none !important;
                    }
                }
            }
            ._col3 {
                .mi-2 {
                    margin-left: 120px;
                }
            }
            .iline {
                height: 100% !important;
                bottom: 0px !important;
            }
        }
        #tabb {
            padding-top: 3em !important;
            padding-bottom: 3em !important;
            &.rbg-dark::before {
                background: #e0f5fe url(../img/bg/tab-md-light.png) no-repeat;
            }
            .table.cs tr th, .table.cs tr td {
                font-size: 16px;
            }
            .flexy.tpil {
                margin-top: -5px !important;
            }
            .monitor {
                top: 175px;
                opacity: 1 !important;
            }
            .table.cs {
                overflow: hidden;
            }
            .flexx.w100 {
                margin-bottom: 1em;
            }
        }
        #tabb, .testimony {
            .f40 {
                font-size: 36px !important;
                line-height: 50.4px !important;
            }
            &>a {
                font-size: 16px !important;
            }
        }
        .testimony {
            margin-top: -1.5em !important;
        }
        #dc {
            padding-bottom: 1em;
            padding: 3rem 0 !important;
            &.rbg-dark::after {
                top: calc(100% - 315px);
                width: 383px;
                height: 315px;
                background: url(../img/home/dcv_light_lg.svg) no-repeat;
            }
            &.rbg-dark::before {
                background: #e0f5fe url(../img/bg/cta-md-light.png) no-repeat;
            }
            h2 {
                margin-top: .9em !important;
                line-height: 58px;
            }
            .pzz {
                width: 331px !important;
                height: 315px !important;
            }
            .swae {
                width: 331px;
                margin-top: 25px;
                font-size: 20px;
            }
            .flexy.tpil {
                margin-top: -20px !important;
            }
        }
        &.sales-page {
            .hero-sect-ant {
                top: -60px;
            }
            #partner {
                ._col1 {
                    .mi-2 {
                        margin-left: 80px !important;
                    }
                }
            }
            .colsec-1 {
                padding-bottom: 0px !important;
            }
            .colsec-2 {
                margin-top: -100px !important;
                img.man {
                    margin-top: 15px;
                }
                .flexx.tab {
                    margin-top: -100px !important;
                    margin-bottom: 10px !important;
                }
            }
            .colsec-3 {
                margin-top: -1em !important;
                img.man {
                    margin-top: 40px;
                }
            }
            .colsec-4 {
                margin-top: -10em !important;
                margin-bottom: 0px !important;
                img.man {
                    margin-top: -60px;
                    margin-bottom: 20px;
                }
                .figger {
                    top: -75px;
                    position: relative;
                    left: -6%;
                    transform: scale(.8);
                }
                .flexx.tab {
                    margin-top: -180px !important;
                    margin-bottom: 0px;
                }
            }
            .pigger {
                margin-left: 80px;
            }
            #partner {
                margin-top: -3em !important;
                ._col1, ._col2, ._col3 {
                    .mi-2 {
                        margin-top: 2em !important;
                        margin-bottom: 2em !important;
                        margin-left: 80px !important;
                    }
                }
                ._col1 {
                    .mi-2 {
                        margin-top: 1em !important;
                    }
                }
            }
        }
        &.leads-page {
            #partner {
                ._col1 {
                    .mi-2 {
                        margin-left: 80px !important;
                        padding-left: 15px !important;
                    }
                }
                ._col3 {
                    .mi-2 {
                        margin-left: 100px;
                    }
                }
            }
        }
        &.appointment-page {
            .masthead {
                .hero-sect {
                    transform: scale(.7);
                    right: -40%;
                    top: -45px;
                }
            }
            .colsec-1 {
                .figger {
                    left: 25px;
                }
            }
            .colsec-2 {
                .figger {
                    margin-top: 10px !important;
                    margin-bottom: 20px !important;
                    transform: scale(.9) translateX(-50px);
                }
                .flexx.tab.responsive {
                    margin-top: 0px !important;
                }
            }
            .colsec-3 {
                padding-top: .5em !important;
            }
            .colsec-4 {
                margin-bottom: 0px !important;
                .figger {
                    margin-top: -20px !important;
                    margin-bottom: 80px !important;
                }
                .flexx.tab.responsive {
                    margin-top: 0px !important;
                }
            }
            #stats {
                margin-top: -.5em !important;
            }
            #partner {
                margin-top: -2.5em !important;
                ._col2 {
                    .mi-2 {
                        margin-top: 2em !important;
                        margin-left: 100px !important;
                    }
                }
                ._col1, ._col3 {
                    .mi-2 {
                        margin-top: 2em !important;
                        margin-bottom: 2em !important;
                        margin-left: 105px !important;
                    }
                    &._col3 {
                        .mi-2 {
                            margin-left: 125px !important;
                        }
                    }
                }
            }
        }

        &.sass-page, &.premium-page, &.social-page {
            .masthead {
                padding-bottom: 75px !important;
                .mh1 {
                    margin-top: 1em;
                    margin-bottom: 30px;
                    font-size: 48px !important;
                }
                .ple {
                    width: 115% !important;
                }
                .plem {
                    display: none;
                }
                .flexx.tab.alt {
                    flex-direction: column !important;
                    justify-content: center !important;
                    align-items: center;
                    .flexy {
                        margin: 0px auto !important;
                    }
                }
            }
            .colsec {
                .figger {
                    margin-top: 10px !important;
                    transform: scale(0.9) translateX(-50px);
                }
            }
            .colsec-1 {
                margin-bottom: 0em !important;
            }
            .colsec-2 {
                margin-top: -3em !important;
                .figger {
                    top: -40px;
                }
            }
            .colsec-3 {
                padding-top: .5em !important;
                margin-bottom: 0px !important;
            }
            .colsec-4 {
                margin-top: -3em !important;
                margin-bottom: 0px !important;
                .man {
                    margin-top: 0px;
                    margin-bottom: 120px !important;
                    margin-left: -50px;
                    width: 550px !important;
                }
                .figger {
                    margin-bottom: 70px !important;
                }
            }
            #stats {
                margin-top: -1.5em !important;
            }
            #wps {
                margin-top: -1em !important;
            }
            #partner {
                ._col1, ._col2, ._col3 {
                    .mi-2 {
                        margin-top: 2em !important;
                        margin-bottom: 2em !important;
                    }
                }
                .pigger {
                    top: 1em !important;
                    margin-left: 100px;
                }
            }
        }
        &.premium-page {
            #tabb {
                .table.cs tr {
                    height: 67px;
                }
            }
            #partner {
                margin-top: -3em !important;
            }
        }
        &.social-page {
            .colsec-2 {
                margin-bottom: 2em !important;
                .figger {
                    top: -40px;
                }
            }
            #tabb {
                .table.cs tr {
                    td.flx-3 {
                        &>span:not(.not-responsive) {
                            font-size: 16px !important;
                        }
                    }
                }
            }
            #partner {
                margin-top: -3em !important;
            }
        }
        &.sass-page {
            #partner {
                margin-top: -3em !important;
            }
        }
    }

    .leads-page {
        .masthead {
            .hero-sect {
                position: relative;
                top: -100px !important;
                right: -20px;
                transform: scale(.7) !important;
                .triy {
                    right: 50px;
                }
            }
        }
        .pigger {
            margin-left: 80px;
        }
    }

    #root:not(.home-page) {
        &.leads-page {
            .colsec-1 {
                margin-top: -2em !important;
                .figger {
                    margin-top: 50px !important;
                }
            }
            .colsec-2 {
                .flexx.tab {
                    margin-top: -20px !important;
                }
            }
            #stats {
                margin-top: -2em !important;
            }
            .colsec-3 {
                .man {
                    margin-top: 50px;
                }
            }
            .colsec-4 {
                margin-top: -10em;
                .fic4 {
                    width: 50vw !important;
                    transform: scale(.9);
                    left: -25px !important;
                    top: -10px;
                }
                .flexx.tab {
                    margin-top: -90px !important;
                }
            }
            #wps {
                margin-top: -4.5em;
            }
            #partner {
                margin-top: -2.5em;
                margin-bottom: 3em;
                .f40 {
                    margin-left: 0px !important;
                    margin-right: 0px !important;
                }
                ._col1 {
                    .mi-2 {
                        margin-top: 1em !important;
                        margin-bottom: 2.5em !important;
                    }
                }
                ._col2 {
                    .mi-2 {
                        margin-top: 1.5em !important;
                        margin-bottom: 2em !important;
                    }
                }
                ._col3 {
                    .mi-2 {
                        margin-top: 1.5em !important;
                    }
                }
            }
            #dc {
                .fl50 {
                    width: 110%;
                    margin-left: -5%;
                }
            }
        }
    }

    [data-theme='dark'] {
        #root:not(.home-page) {
            #stats {
                &.rbg-dark::before {
                    background: #1d2f48 url(../img/bg/hiw-md-dark.png) no-repeat;
                }
            }
            .clients {
                &.rbg-dark::before {
                    background: #1d2f48 url(../img/bg/table_dark_lg.webp) no-repeat;
                }
            }
            #tabb {
                padding-top: 3em !important;
                &.rbg-dark::before {
                    background: #1d2f48 url(../img/bg/tab-md-dark.png) no-repeat;
                }
            }
            #wps {
                &.rbg-dark::before {
                    background: #1d2f48 url(../img/bg/clients-md-dark.png) no-repeat;
                }
            }
            #dc {
                &.rbg-dark::after {
                    top: calc(100% - 315px);
                    width: 383px;
                    height: 315px;
                    background: url(../img/home/dcv_dark_lg.svg) no-repeat;
                }
                &.rbg-dark::before {
                    background: #2c3d50 url(../img/bg/cta-md-dark.png) no-repeat;
                }
            }
        }
    }
}

@media (min-width: 768px) and (max-width: 870px) {
    .home-page {
        #stats {
            .is-tablet {
                display: none !important;
            }
        }
    }
    .leads-page {
        .masthead {
            .hero-sect {
                transform: scale(1);
                position: relative;
                top: -70px !important;
                right: -140px;
            }
            .fdf {
                width: 138%;
            }
        }
    }
    .appointment-page {
        .masthead {
            .hero-sect {
                right: -63% !important;
            }
        }
        #services {
            .f40 {
                .is-tablet {
                    display: none !important;
                }
            }
        }
    }
    .sass-page {
        #partner {
            h2.f40 {
                .is-tablet {
                    display: none !important;
                }
            }
        }
    }
    .sass-page {
        #services {
            h2.f40 {
                .is-tablet {
                    display: none !important;
                }
            }
        }
    }
    .premium-page {
        .masthead {
            .container {
                p.f22 {
                    .is-tablet {
                        display: none !important;
                    }
                }
            }
        }
        #services {
            h2.f40 {
                .is-tablet {
                    display: none !important;
                }
            }
        }
    }
    .social-page {
        .masthead p.f22 {
            .is-tablet {
                display: none !important;
            }
        }
        #partner {
            h2.f40 {
                margin-left: .5em !important;
                margin-right: .5em !important;
            }
        }
        #services {
            h2.f40 {
                .is-tablet {
                    display: none !important;
                }
            }
        }
    }
}

@media (min-width: 480px) and (max-width: 767px) {
    #root:not(.home-page) {
        .ghs {
            width: 81%;
            margin: 0 auto !important;
            // min-width: 370px;
            min-width: 444px;
            .is-mobile {
                display: none !important;
            }
        }
        &.sales-page {
            .colsec-4 {
                .figger {
                    top: -80px !important;
                    transform: scale(0.85) !important;
                }
                .flexx.tab {
                    margin-top: -200px !important;
                }
            }
        }
        &.sass-page {
            .colsec-2 {
                .figger {
                    margin-top: -50px !important;
                }
                .flexx.tab.responsive {
                    margin-top: -70px !important;
                }
            }
        }
        &.premium-page {
            .colsec-4 {
                margin-top: -2em !important;
            }
            #wps {
                margin-top: -2em !important;
            }
        }
        &.social-page {
            .masthead {
                .mh1 {
                    min-width: 90%;
                    width: 90%;
                    br.is-mobile {
                        display: none !important;
                    }
                }
            }
            .colsec-2 {
                margin-top: -4em !important;
                .figger {
                    margin-top: -50px !important;
                }
            }
            .colsec-3 {
                .figger {
                    margin-top: -20px !important;
                }
            }
            .colsec-4 {
                .figger {
                    margin-top: -50px !important;
                }
            }
        }
    }
}



@media (max-width: 767px) {
    #root:not(.home-page) {
        .masthead {
            padding-bottom: 1.2em;
            .its.is-bigs {
                display: flex !important;
                position: absolute;
                right: 0;
                top: 0px;
                justify-content: flex-end;
                z-index: -1;

                .hero-sect-ant {
                    right: -40%;
                    top: -80px;
                }
            }
            .fdf {
                width: 100% !important;
                max-width: 500px;
            }
        }
        .colsec {
            .pbold.f20 {
                margin-bottom: 1.8em !important;
            }
            .col-lg-6 {
                padding-left: 0px !important;
                padding-right: 0px !important;
            }
            .dcenter > img {
                margin-top: -10px;
                margin-bottom: 0px;
            }
            .container {
                .row {
                    width: 100% !important;
                    margin: 0px !important;
                }
            }
            .kts {
                .f36 {
                    font-size: 24px !important;
                    font-weight: 600 !important;
                }
                .f20 {
                    font-size: 18px !important;
                    font-weight: 600 !important;
                }
                .f16 {
                    font-size: 18px !important;
                    font-weight: 400 !important;
                }
            }
        }
        #stats {
            &.rbg-dark::before {
                background: #e0f5fe url(../img/bg/hiw-sm-light.png) no-repeat;
            }
            .f40 {
                font-weight: 600 !important;
                font-size: 24px !important;
            }
            .col-lg-12 {
                .wth-lite {
                    font-size: 20px !important;
                }
                .text-muted {
                    font-size: 18px !important;
                }
            }
            .gx-4 {
                margin-top: -20px;
            //     &::before {
            //         content: '';
            //         position: absolute;
            //         left: 40px;
            //         top: 130px;
            //         border-left: 3px dashed #a9a5b0; // 82828d
            //         height: 2px;
            //         transition: 2s ease-in all;
            //         width: 2px;
            //     }
            //     &.anm {
            //         &::before {
            //             height: calc(75% -  70px);
            //         }
            //     }
                .ellc {
                    .lintd {
                        display: none !important;
                    }
                }
            }
            .mt-5 {
                &::before {
                    content: '';
                    position: absolute;
                    left: 40px;
                    border-left: 3px dashed #a9a5b0; // 82828d
                    height: 2px;
                    top: 100px;
                    transition: 2s ease-in all;
                    width: 2px;
                }
                &.anm {
                    &::before {
                        height: calc(100% - 80px);
                    }
                }
                &.l {
                    &::before {
                        display: none;
                    }
                }
            }
        }
        .testimony {
            height: 550px !important;
            &._mx {
                max-height: 530px !important;
            }
            &._nx {
                .tesb {
                    height: 150% !important;
                }
            }
            .tesb {
                opacity: .9;
                width: 70px;
            }
            .my-slider {
                padding-top: 210px;
                height: 600px;
                .btn {
                    position: absolute;
                    z-index: 100;
                    top: 300px !important;
                    &:active, &:focus {
                        opacity: 1;
                    }
                }
                .myslider {
                    height: 266px;
                    .slide {
                        width: 323px;
                        max-width: 323px;
                        height: 266px;
                        display: flex;
                        justify-content: center;
                        align-items: flex-start;
                        left: 65px;
                        &:first-child {
                            opacity: 1 !important;
                        }
                    }
                }
                &.pd {
                    padding-left: 40px;
                    .slide {
                        left: 0px;
                        &:first-child {
                            opacity: 0 !important;
                        }
                    }
                }
                .pinfo span {
                    &.sm {
                        font-size: 12px;
                    }
                }

                // RMORE
                height: 620px;
                .myslider {
                    height: 600px;
                    .slide {
                        height: 457px;
                    }
                }
                .btn {
                    top: 360px !important;
                }
            }
            #testy-sect {
                height: 500px;
            }
            h2.f40 {
                position: absolute;
                top: 50px;
                width: 100%;
                z-index: 20;
                padding-left: 10px;
                padding-right: 10px;
                font-weight: 600 !important;
                font-size: 24px !important;
            }
            p.f22 {
                position: absolute;
                top: 130px;
                width: 100%;
                z-index: 20;
                padding-left: 10px;
                padding-right: 10px;
                font-size: 18px !important;
            }
            .cm-box-2 {
                .pbold.f_20 {
                    font-size: 18px !important;
                    font-weight: 400;
                }
            }
            .cm-box-2.alt {
                margin: 10px 5px;
                .pbold.f_20 {
                    font-size: 18px !important;
                    font-weight: 400;
                }
            }
        }
        .clients {
            .pbg {
                top: 10px;
                opacity: 1;
                width: 221px;
                height: 221px;
                &.dmode {
                    opacity: 0.2;
                }
            }
        }
        #wps {
            height: 318px !important;
            min-height: 300px;
            &.rbg-dark::before {
                background: #e0f5fe url(../img/bg/clients-md-light.png) no-repeat;
            }
            .f40 {
                font-size: 24px !important;
                top: 50px !important;
                font-weight: 600 !important;
            }
            .my-slider {
                height: 318px;
                padding-top: 130px;
                position: absolute;
                .myslider {
                    height: 160px;
                    .slide {
                    //   width: 250px;
                    //   height: 150px;
                      width: 285px;
                      height: 160px;
                      img:not(.play) {
                          width: 270px !important;
                      }
                      left: 65px;
                      &:first-child {
                          opacity: 1 !important;
                      }
                    }
                }
                .btn {
                    top: calc(152px + 95px - 70px) !important;
                }
                &.pd {
                    // padding-left: 25px !important;
                    padding-left: 40px !important;
                    .slide {
                        left: 0px;
                        &:first-child {
                            opacity: 0 !important;
                        }
                    }
                }
            }
        }
        #partner {
            padding-top: 10px !important;
            padding-bottom: 3em !important;
            .f40 {
                font-size: 24px !important;
                font-weight: 600 !important;
                line-height: 33.6px !important;
            }
            .no.responsive {
                left: 22px;
                font-weight: 600;
                font-size: 24px;
                width: 19px;
                font-size: 24px;
                text-align: center;
                justify-content: center;
            }
            strong.f24 {
                font-size: 20px !important;
                font-weight: 600 !important;
            }
            .flexy.tpil {
                margin-top: -10px !important;
            }
            .no.responsive {
                top: 3px;
            }
            .ghs {
                margin-left: 0.5em;
                margin-right: 0.5em;
            }
        }
        #tabb {
            padding-top: 3em;
            padding-bottom: 3em;
            &>.container>.hmd {
                font-size: 18px !important;
            }
            .table.cs {
                width: 100% !important;
                tr {
                    // .flx {
                    //     margin-top: 1em;
                    // }
                    padding-left: 10px;
                    padding-right: 10px;
                    padding-top: 15px;
                    padding-bottom: 15px;
                    &._tln_.f {
                        display: none !important;
                    }
                }
            }
            .fsm {
                padding-left: 1.5em;
                padding-right: 1.5em;
            }
            .f40 {
                font-size: 24px !important;
                font-weight: 600 !important;
            }
            .f22 {
                font-size: 18px !important;
                &.fsm {
                    font-size: 12px !important;
                }
            }
            &.rbg-dark::before {
                background: #e0f5fe url(../img/bg/tab-sm-light.png) no-repeat;
            }
            &>a {
                font-size: 14px !important;
            }
        }
        #services {
            margin-top: 20px;
            .f40 {
                font-size: 24px !important;
                margin-top: 0px !important;
                font-weight: 600 !important;
                padding-top: 0px !important;
            }
            .plink {
                font-size: 18px !important;
            }
            img.i1 {
                left: 9%;
            }
            img.i2 {
                right: 9%;
            }
            img.i3 {
                top: 9%;
                left: 3%;
            }
            img.i4 {
                top: 9%;
                right: 3%;
            }
            img.i5 {
                left: 6%;
                top: 17%;
            }
            img.i6 {
                right: 6%;
                top: 17%;
            }
            .accordion {
                padding: 10px 0px !important;
            }
            .accr {
                label {
                    align-items: flex-start;
                    font-size: 20px;
                    line-height: 28px;
                    font-weight: 600;
                    svg {
                        position: relative;
                        top: 10px;
                    }
                }
                span.acs {
                    padding: 10px 0;
                    font-weight: 400;
                    font-size: 18px;
                    line-height: 140%;
                }
            }
        }
        #dc {
            // .f24 {
            //     font-size: 16px !important;
            // }
            &.rbg-dark::after {
                bottom: 25px;
                width: 177px;
                height: 191px;
            }
            &.rbg-dark::before {
                background: url(../img/bg/cta-sm-light.png) no-repeat;
            }
        }

        &.sass-page, &.premium-page, &.social-page {
            .masthead {
                padding-bottom: 10px !important;
                .mh1 {
                    margin-top: 5px;
                    margin-bottom: 20px;
                    font-size: 32px !important;
                }
                p.f22 {
                    line-height: 23px;
                }
                .ple {
                    // width: 115% !important;
                    display: none !important;
                }
                .plem {
                    display: none;
                }
                .flexx.tab.alt {
                    flex-direction: column !important;
                    justify-content: center !important;
                    align-items: center;
                    .flexy.tpil {
                        margin: 0px auto !important;
                        left: 4px;
                    }
                }
            }
            .colsec-2 {
                .flexx.tab {
                    margin-top: -100px !important;
                }
            }
            #partner {
                .pigger {
                    top: 10px !important;
                    margin-left: 80px;
                }
            }
            &.premium-page {
                #partner {
                    margin-top: .5em !important;
                }
            }
        }
    }
    .sales-page {
        .pigger {
            margin-left: 80px;
        }
        .colsec {
            h1, p {
                .is-mobile {
                    display: none !important;
                }
            }
        }
        .colsec-1 {
            padding-top: 2em !important;
            img.man {
                width: 112% !important;
                margin-top: 0px !important;
                margin-bottom: 5px !important;
                margin-left: 0;
            }
        }
        .colsec-2 {
            margin-top: -10em !important;
            img.man {
                margin-top: -10px !important;
            }
            .flexx.tab {
                margin-top: -100px;
                .tpil {
                    margin-top: 5px !important;
                }
            }
        }
        .colsec-3 {
            img.man {
                margin-top: 30px !important;
            }
        }
        .colsec-4 {
            margin-bottom: 0px !important;
            margin-top: -7em;
            .flexx.tab {
                margin-top: -250px;
                .tpil {
                    margin-top: 10px !important;
                }
            }
            .figger {
                position: relative;
                left: 0px;
                transform: scale(.65);
                top: -120px;
            }
            .man {
                margin-top: -50px !important;
            }
        }
        #partner {
            ._col1 {
                .mi-2 {
                    width: 200px;
                    margin-left: 80px !important;
                    margin-top: 30px !important;
                    margin-bottom: 30px !important;
                }
            }
            ._col2 {
                .mi-2 {
                    width: 200px;
                    margin-left: 80px !important;
                    margin-top: 30px !important;
                    margin-bottom: 30px !important;
                }
            }
            ._col3 {
                .mi-2 {
                    width: 200px;
                    margin-left: 90px !important;
                    margin-top: 30px !important;
                    margin-bottom: 30px !important;
                    // margin: 0 auto !important;
                }
            }
        }
    }
    .leads-page {
        .masthead {
            .hero-sect {
                transform: scale(0.5) !important;
                top: -130px !important;
                right: -140px !important;
                position: absolute !important;
            }
            .hero-sec-fig {
                .elipses {
                    position: absolute;
                    width: 600px;
                    height: 600px;
                    top: 40px;
                    right: -20px;
                }
                .rsq {
                    display: none;
                }
                .blo2 {
                    left: 35px;
                    opacity: 0.5;
                }
            }
        }
        .pigger {
            margin-left: 80px;
        }
        .colsec-1 {
            padding-top: 2em !important;
            margin-bottom: 80px;
            .man {
                margin-top: -15px !important;
            }
            .figger {
                left: -20px;
                transform: scale(.9);
                margin-top: 30px !important;
            }
        }
        .colsec-2 {
            margin-top: -100px;
            .flexx.tab {
                margin-top: -30px;
                .tpil {
                    margin-top: 5px !important;
                }
            }
            .figger {
                margin-top: -20px !important;
                transform: scale(.9);
            }
        }
        .colsec-3 {
            .man {
                margin-top: 30px !important;
                max-width: 500px;
                margin-bottom: 20px !important;
            }
        }
        .colsec-4 {
            margin-top: -100px;
            margin-bottom: 0px !important;
            padding-bottom: 1em !important;
            .flexx.tab {
                margin-top: -100px;
                .tpil {
                    margin-top: 5px !important;
                }
            }
            .figger {
                transform: scale(.8);
                left: 0px;
                margin-top: -30px;
            }
        }
        #partner {
            ._col1 {
                .mi-2 {
                    margin-left: 80px !important;
                    padding-left: 30px !important;
                    margin-bottom: 1em !important;
                    margin-top: 30px !important;
                    margin-bottom: 30px !important;
                }
            }
            ._col2 {
                .mi-2 {
                    margin-top: 30px !important;
                    margin-bottom: 0px !important;
                }
            }
            ._col3 {
                .mi-2 {
                    margin-top: 30px !important;
                    margin-bottom: 10px !important;
                }
            }
        }
        #stats {
            margin-top: -15px !important;
        }
    }
    .appointment-page {
        .masthead {
            overflow: unset;
            padding-bottom: 3em !important;
            .hero-sect {
                transform: scale(.8) !important;
                right: -50% !important;
                top: -20px;
                .aph-blue {
                    display: none !important;
                }
            }
        }
        .colsec-1 {
            padding-bottom: 5px !important;
            .figger {
                transform: translateX(25px) scale(.8);
                margin-top: 0px !important;
            }
        }
        .colsec-2 {
            margin-bottom: 0em !important;
            .figger {
                transform: translateX(-15px) scale(.8) !important;
                margin-top: -5px !important;
            }
            .flexx.tab.responsive {
                margin-top: -70px !important;
                .swae {
                    margin-bottom: 2em !important;
                }
            }
        }
        .colsec-3 {
            margin-bottom: 2em !important;
            .crac.man {
                margin-top: 20px !important;
                transform: scale(1);
                margin-bottom: 20px !important;
            }
        }
        .colsec-4 {
            margin-bottom: 0em !important;
            .flexx.tab.responsive {
                .swae {
                    margin-bottom: 2em !important;
                }
            }
        }
        #stats {
            margin-top: 0px !important;
        }
        #partner {
            .pigger {
                margin-top: 20px !important;
                margin-left: 80px;
                margin-bottom: 10px !important;
            }
            ._col1, ._col3 {
                .mi-2 {
                    margin-left: 80px !important;
                    padding-left: 30px !important;
                    margin-bottom: 1em !important;
                    margin-top: 30px !important;
                    margin-bottom: 30px !important;
                }
            }
        }
    }
    .sass-page, .premium-page, .social-page {
        .masthead {
            .container {
                p.f22 {
                    line-height: 27px !important;
                }
            }
        }
        .colsec-1 {
            margin-bottom: 0px !important;
            .figger {
                transform: translateX(0px) scale(0.8);
                margin-top: 0px !important;
            }
        }
        .colsec-2 {
            margin-top: -2.2em !important;
            margin-bottom: 0px !important;
            padding-bottom: 0px !important;
            .figger {
                transform: translateX(0px) scale(0.8);
                margin-top: -25px !important;
            }
            .flexx.tab {
                margin-top: -40px !important;
                .swae {
                    margin-bottom: 2em !important;
                }
            }
        }
        .colsec-3 {
            margin-top: -10px !important;
            margin-bottom: 0px !important;
            .figger {
                transform: translateX(0px) scale(0.8);
                margin-top: 0px !important;
            }
        }
        .colsec-4 {
            margin-top: -10px !important;
            margin-bottom: 0px !important;
            .figger {
                transform: translateX(0px) scale(0.8);
                margin-top: 0px !important;
            }
            .man {
                // transform: translateX(0px) scale(0.8);
                margin-bottom: 50px !important;
                width: 500px !important;
            }
            .flexx.tab {
                margin-top: -50px !important;
                .swae {
                    margin-bottom: 2em !important;
                }
            }
        }
        &.premium-page {
            .masthead {
                .hero-pbg {
                    & > div {
                        top: 83%;
                    }
                    &.anm {
                        .cn-3 {
                            left: 67%;
                            top: 56%;
                        }
                        .cn-4 {
                            left: 34%;
                            top: 23%;
                        }
                        .cn-5 {
                            left: 80%;
                            top: 80%;
                        }
                    }
                }
            }
        }
        &.social-page {
            .masthead {
                .container {
                    p.f22 {
                        width: 100% !important;
                        max-width: 550px;
                    }
                }
                .hero-pbg {
                    & > div {
                        img {
                            transform: scale(.5);
                        }
                    }

                    .cn-1 { // blush
                        left: 1%;
                        top: 68%;
                    }
                    .cn-3 { // love
                        left: 61%;
                        top: 30%;;
                    }
                    .cn-5 { // note
                        left: 1%;
                        top: 4%;
                    }
                    .cn-6 { // what
                        left: -14%;
                        top: 30%;
                    }
                    .cn-4 { // mess
                        right: -2%;
                        top: -4%;
                    }
                    .cn-2 { // laff
                        right: 0%;
                        top: 65%;
                    }
                    .cn-7 { // play
                        left: -2%;
                        top: 50%;
                    }
                }
            }
            .colsec-2 {
                .swae {
                    margin-top: 2em !important;
                }
            }
            #wps {
                margin-top: -25px !important;
            }
        }
    }
    [data-theme='dark'] {
        #root:not(.home-page) {
            #stats {
                &.rbg-dark::before {
                    background: #1d2f48 url(../img/bg/hiw-sm-dark.png) no-repeat;
                }
                .mt-5 {
                    &::before {
                        border-left: 3px dashed #82828d;
                    }
                }
            }
            #wps {
                &.rbg-dark::before {
                    background: #1d2f48 url(../img/bg/clients-md-dark.png) no-repeat;
                }
            }
            #tabb {
                &.rbg-dark::before {
                    background: #1d2f48 url(../img/bg/tab-sm-dark.png) no-repeat;
                }
            }
            #dc {
                &.rbg-dark::after {
                    bottom: 25px;
                    width: 177px;
                    height: 191px;
                }
                &.rbg-dark::before {
                    background: #2c3d50 url(../img/bg/cta-sm-dark.png) no-repeat;
                }
            }
        }
    }
}

@media (min-width: 539px) and (max-width: 767px) {
    #root:not(.home-page) {
        &.leads-page {
            .masthead {
                .fdf {
                    width: 120% !important;
                }
            }
        }
    }
}

@media (min-width: 670px) and (max-width: 767px) {
    #root:not(.home-page) {
        .masthead {
            .hero-sect {
                transform: scale(.75);
                .hero-sect-ant {
                    top: -20px !important;
                    right: -60% !important;
                    .not-mobile {
                        display: flex !important;
                    }
                    .is-mobile {
                        display: none !important;
                    }
                    canvas {
                        width: 500px !important;
                        height: 500px !important;
                    }
                }
            }
        }
    }
}

@media (max-width: 700px) {
    .appointment-page {
        .masthead {
            overflow: unset;
            padding-bottom: 3em !important;
            .hero-sect {
                transform: scale(.8) !important;
                right: -60% !important;
                top: -20px;
                .aph-eli, .aph-eli-sm {
                    display: none;
                }
                .aph-yellow {
                    bottom: 100px !important;
                }
                .aph-pink {
                    bottom: 50px !important;
                }
                .amd-msk {
                    right: unset !important;
                    left: 20% !important;
                }
                .not-mobile-2 {
                    display: none !important;
                }
            }
        }
    }
}

@media (max-width: 605px) {
    .appointment-page {
        .masthead {
            .not-mobile-2 {
                display: none !important;
            }
        }
    }
}

@media (max-width: 565px) {
    .appointment-page {
        .masthead {
            .hero-sect {
                transform: scale(1) !important;
                justify-self: flex-end !important;
                left: unset !important;
                right: calc(-11px - 0.75em) !important;
                width: 150px !important;
                .hero-sec-fig {
                    left: 0px;
                    top: 0px;
                    min-width: 150px !important;
                    width: 150px !important;
                }
                .aph-pop, .aph-green, .aph-pink, .aph-yellow {
                    display: none;
                }
                .amd-msk {
                    right: unset !important;
                    left: 40px !important;
                    transform: scale(.8);
                    top: 25px !important;
                }
                .amd-sar {
                    transform: scale(.8);
                    top: 220px !important;
                }
                .avs1, .avs2 {
                    width: calc(100% - 22px);
                    &.nsm {
                        display: none !important;
                    }
                    &.sm {
                        display: block !important;
                    }
                }
                .avs1 {
                    width: 101% !important;
                }
            }
        }
    }
    .sass-page {
        #tabb {
            .table {
                tr {
                    .flx-3:not(.is-mobile) {
                        &:not(.ft12) {
                            width: 27% !important;
                        }
                        &.ft12 {
                            width: 47% !important;
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: 500px) {
    .sales-page {
        .colsec-3 {
            img.man {
                margin-top: 30px !important;
                width: 110%;
                left: 5%;
                position: relative;
            }
        }
    }

    &.sass-page, &.premium-page, &.social-page {
        .masthead {
            p.f22 {
                width: 100% !important;
            }
        }
    }
}

@media (max-width: 480px) {
    #root:not(.home-page) {
        .masthead {
            p.f22 {
                .is-mobile, .is-smalls {
                    display: none !important;
                }
            }
        }
        .px-lg-5 {
            padding-right: 15px !important;
            width: 100% !important;
            max-width: 100% !important;
            padding-left: 15px !important;
        }
        .colsec {
            h1, p.f20 {
                .is-mobile {
                    display: flex !important;
                }
            }
        }
        .testimony {
            .f22 {
                .is-mobile {
                    display: none !important;
                }
            }
        }
        #stats {
            padding-top: 4em !important;
            padding-bottom: 4em !important;
            .fxlt2 {
                .ellc {
                    left: 0px !important;
                }
                .mt-5 {
                    padding-left: 115px !important;
                }
            }

            .mt-5 {
                &::before {
                    left: 30px;
                }
            }
        }
    }
    .sales-page {
        .colsec-1 {
            img.man {
                margin-top: 25px !important;
                margin-bottom: 15px !important;
            }
        }
        .colsec-2 {
            margin-top: -140px !important;
            padding-bottom: 3em !important;
            img.man {
                margin-top: 20px !important;
            }
            .flexx.tab {
                margin-top: -80px !important;
            }
        }
        .colsec-3 {
            padding-top: 2em !important;
            img.man {
                margin-top: 50px !important;
                width: 110%;
                left: 0px;
                position: relative;
            }
        }
        .colsec-4 {
            margin-top: -100px;
            .figger {
                top: -85px;
                left: -55px;
            }
            .flexx.tab {
                margin-top: -200px !important;
            }
        }
    }
    .leads-page {
        .masthead {
            .hero-sect {
                right: -130px !important;
            }
        }
        #partner {
            .col-lg-6 {
                position: relative;
                z-index: -1;
                .mi-2 {
                    z-index: 0;
                    margin-left: 90px !important;
                }
                .pigger {
                    margin-left: 80px !important;
                }
            }
        }
        .masthead {
            .hero-sect {
                right: -110px !important;
            }
        }
        .colsec-1 {
            .figger {
                width: 100vw !important;
                transform: scale(.65) !important;
                margin-top: -35px !important;
                .fig-1 {
                    left: -10% !important;
                }
            }
        }
        .colsec-2 {
            margin-top: -140px !important;
            .flexx.tab {
                margin-top: -60px;
            }
            .figger {
                width: 100vw !important;
                transform: scale(.65) !important;
                margin-top: -50px !important;
                .fig-4 {
                    left: -10% !important;
                }
            }
        }
        .colsec-3 {
            .man {
                margin-top: 35px !important;
            }
        }
        .colsec-4 {
            margin-top: -80px;
            .flexx.tab {
                margin-top: -120px;
            }
            .figger {
                width: 100vw !important;
                transform: scale(.65) !important;
                margin-top: -60px;
                .fig-4 {
                    left: -10% !important;
                }
            }
        }
    }
    .appointment-page {
        .masthead {
            p.f22 {
                width: 65% !important;
                br.is-mobile {
                    display: none !important;
                }
            }
            .hero-sect {
                top: -50px !important;
                right: -30px !important;
                transform: scale(.8) !important;
            }
        }

        .colsec-1 {
            .figger {
                transform: translateX(-40px) scale(.7) !important;
                margin-top: -40px !important;
            }
        }
        .colsec-2 {
            margin-top: -1em !important;
            margin-bottom: 0em !important;
            padding-bottom: 0px !important;
            .figger {
                transform: translateX(-50px) scale(.8) !important;
                margin-top: -50px !important;
            }
            .flexx.tab.responsive {
                margin-top: -50px !important;
            }
        }
        .colsec-4 {
            margin-bottom: 0em !important;
            .figger {
                transform: translateX(-45px) scale(.8) !important;
                margin-top: -40px !important;
            }
            .flexx.tab.responsive {
                margin-bottom: 0px !important;
            }
        }
        #partner {
            .col-lg-6 {
                position: relative;
                z-index: -1;
                .mi-2 {
                    z-index: 0;
                    margin-left: 90px !important;
                }
                .pigger {
                    margin-left: 90px !important;
                }
            }
        }
    }
    .sass-page, .premium-page, .social-page {
        #services {
            h2.f40 {
                .is-mobile {
                    display: none !important;
                }
            }
        }
    }
    .sass-page, .premium-page, .social-page {
        .colsec-1 {
            .figger {
                transform: translateX(-70px) scale(.7) !important;
                margin-top: -30px !important;
            }
        }
        .colsec-2 {
            margin-top: -4em !important;
            .figger {
                transform: translateX(-50px) scale(.7) !important;
                margin-top: -35px !important;
            }
        }
        .colsec-3 {
            .figger {
                transform: translateX(-75px) scale(.7) !important;
                margin-top: -15px !important;
            }
        }
        .colsec-4 {
            margin-top: -1em !important;
            .figger {
                transform: translateX(-40px) scale(.7) !important;
                margin-top: -35px !important;
            }
            .man {
                transform: translateX(0px) scale(0.8);
                width: 140% !important;
                margin-left: -20%;
                margin-top: -20px;
                margin-bottom: 20px !important;
            }
        }
        &.sass-page {
            .colsec-1 {
                .figger {
                    margin-top: -30px !important;
                }
            }
            .colsec-2 {
                margin-top: -6em !important;
            }
            .colsec-3 {
                .figger {
                    margin-top: -40px !important;
                }
            }
            .colsec-4 {
                margin-top: -3em !important;
            }
        }
    }
    #root:not(.home-page) {
        &.sass-page {
            .colsec-2 {
                .figger {
                    margin-top: -80px !important;
                }
                .flexx.tab {
                    margin-top: -70px !important;
                }
            }
        }
    }
    .premium-page {
        .colsec-1 {
            .figger {
                margin-top: -50px !important;
            }
        }
        .colsec-2 {
            margin-top: -5em !important;
            .figger {
                transform: translateX(-48px) scale(.7) !important;
                margin-top: -40px !important;
            }
        }
        .colsec-3 {
            .figger {
                transform: translateX(-80px) scale(.7) !important;
                margin-top: -20px !important;
            }
        }
        .colsec-4 {
            margin-top: -4em !important;
            .man {
                margin-top: -50px !important;
            }
        }
    }
    .social-page {
        .colsec-1 {
            .figger {
                margin-top: -37px !important;
            }
        }
        .colsec-2 {
            margin-top: -7em !important;
        }
        .colsec-3 {
            .figger {
                transform: translateX(-60px) scale(.7) !important;
            }
        }
        .colsec-4 {
            margin-top: -3em !important;
        }
    }
}

@media (max-width: 440px) {
    #root:not(.home-page) {
        #partner {
            .mi-2 {
                margin-left: 60px !important;
            }
        }
        #services {
            h2.f40 {
                br {
                    display: none !important;
                }
            }
        }
        &.sass-page, &.premium-page, &.social-page {
            #partner {
                ._col1, ._col2, ._col3 {
                    .mi-2 {
                        margin-left: -20px !important;
                    }
                }
                .pigger {
                    top: 10px !important;
                    left: 100px !important;
                }
            }
        }
    }
    .sales-page {
        .colsec-4 {
            .figger {
                transform: scale(.7) !important;
                left: calc(-1*(50vw / 5));
                left: -65px;
            }
        }
    }
    .leads-page {
        .colsec-1 {
            .figger {
                .fig-1 {
                    left: -17% !important;
                }
            }
        }
        .colsec-2 {
            .figger {
                transform: scale(.65) !important;
                margin-top: -60px;
                left: -40px !important;
                .fig-2 {
                    left: 0% !important;
                }
            }
        }
        .colsec-4 {
            .figger {
                transform: scale(.65) !important;
                margin-top: -60px;
                margin-left: -65px;
                .fig-4 {
                    left: 0% !important;
                }
            }
        }
    }
    .appointment-page {
        .colsec-1 {
            .figger {
                transform: translateX(-60px) scale(.7) !important;
            }
        }
        .colsec-2 {
            .figger {
                transform: translateX(-70px) scale(.7) !important;
                margin-bottom: 0px !important;
            }
            .swae {
                margin-top: -2em;
            }
        }
        .colsec-4 {
            .figger {
                transform: translateX(-68px) scale(.8) !important;
                margin-top: -40px !important;
            }
        }
    }
    .sass-page {
        .colsec-1 {
            .figger {
                transform: translateX(-85px) scale(.7) !important;
                margin-top: 5px !important;
            }
        }
        .colsec-2 {
            .figger {
                transform: translateX(-65px) scale(.7) !important;
                margin-top: -30px !important;
            }
        }
        .colsec-3 {
            .figger {
                transform: translateX(-90px) scale(.7) !important;
                // margin-top: 0px !important;
            }
        }
        .colsec-4 {
            margin-top: -1.5em !important;
            .figger {
                transform: translateX(-60px) scale(.7) !important;
            }
        }
    }
    .premium-page {
        .colsec-1 {
            .figger {
                transform: translateX(-100px) scale(.7) !important;
            }
        }
        .colsec-2 {
            .figger {
                transform: translateX(-65px) scale(.7) !important;
                margin-top: -50px !important;
            }
        }
        .colsec-3 {
            .figger {
                transform: translateX(-100px) scale(.7) !important;
                margin-top: -20px !important;
            }
        }
    }
    .social-page {
        .colsec-1 {
            .figger {
                transform: translateX(-80px) scale(.7) !important;
                // margin-top: 0px !important;
            }
        }
        .colsec-2 {
            .figger {
                transform: translateX(-65px) scale(.7) !important;
                margin-top: -50px !important;
            }
        }
        .colsec-3 {
            .figger {
                transform: translateX(-78px) scale(.7) !important;
                margin-top: -25px !important;
            }
        }
        .colsec-4 {
            .figger {
                transform: translateX(-70px) scale(.7) !important;
                margin-top: -70px !important;
            }
        }
    }
}

@media (max-width: 400px) {
    #root:not(.home-page) {
        #partner {
            .mi-2 {
                margin-left: 30px !important;
            }
        }
    }
    .sales-page {
        .colsec-4 {
            .figger {
                transform: scale(.6) !important;
                left: -85px;
            }
        }
    }
    .leads-page {
        .colsec-1, .colsec-2, .colsec-4 {
            .figger {
                .fig-1 {
                    left: -100px !important;
                }
                .fig-2, .fig-4 {
                    left: -1em !important;
                }
            }
        }
    }
    #root:not(.home-page) {
        &.appointment-page {
            #partner {
                .mi-2 {
                    margin-left: 30px !important;
                    &.pigger {
                        margin-left: 0px !important;
                    }
                }
            }
        }
    }
}

.f40, .f36, .f50 {
    font-weight: 600 !important;
}

.swae {
    font-weight: bolder !important;
}