.c45 {
    transform: scale(1.1);
}

@media (min-width: 1301px) {
    .c45 {
        font-size: 2.5em;
    }
}

@media (max-width: 1300px) {
    .c45 {
        font-size: 2.4em;
    }
}

@media (max-width: 1274px) {
    .c45 {
        left: 14.5%;
    }
}

@media (max-width: 1250px) {
    .c45 {
        top: 37%;
    }
}

@media (max-width: 1237px) {
    .c45 {
        top: 39%;
    }
}

@media (max-width: 1205px) {
    .c45 {
        top: 37%;
    }
}

@media (max-width: 1199px) {
    .c45 {
        left: 11%;
    }
}

@media (max-width: 1155px) {
    .c45 {
        top: 38%;
    }
}

@media (max-width: 1111px) {
    .c45 {
        top: 38.5%;
        font-size: 2.2em;
    }
}

@media (max-width: 1024px) {
    .c45 {
        top: 39%;
        font-size: 2.1em;
    }
}




@media (max-width: 992px) {
    .c45 {
        min-width: 100px;
        transform: scale(1) !important;
        left: 0px;
        top: 44%;
        /* background: #dd11448f; */
        text-align: left !important;
        padding-left: 16%;
        font-size: 200%;
    }
    .c45 {
        top: 37%;
        padding-left: 10%;
        font-size: 265%;
    }
}

@media (max-width: 920px) {
    .c45 {
        font-size: 250%;
    }
}

@media (max-width: 890px) {
    .c45 {
        font-size: 235%;
    }
}

@media (max-width: 820px) {
    .c45 {
        font-size: 220%;
    }
}

@media (max-width: 767px) {
    .c45 {
        top: 40%;
        padding-left: 13%;
        font-size: 300%;
    }
}

@media (max-width: 700px) {
    .c45 {
        top: 40%;
        padding-left: 12%;
        font-size: 280%;
    }
}

@media (max-width: 640px) {
    .c45 {
        top: 39.5%;
        padding-left: 12%;
        font-size: 260%;
    }
}

@media (max-width: 545px) {
    .c45 {
        top: 40%;
        font-size: 240%;
    }
}

@media (max-width: 525px) {
    .c45 {
        font-size: 230%;
    }
}

@media (max-width: 500px) {
    .c45 {
        left: 10px;
    }
}

@media (max-width: 480px) {
    .c45 {
        top: 44%;
        padding-left: 16%;
        font-size: 200%;
    }
}

@media (max-width: 420px) {
    .c45 {
        font-size: 180%;
    }
}

@media (max-width: 390px) {
    .c45 {
        top: 45%;
        font-size: 170%;
    }
}

@media (max-width: 360px) {
    .c45 {
        top: 46%;
        font-size: 160%;
    }
}

@media (max-width: 333px) {
    .c45 {
        top: 47%;
        font-size: 150%;
    }
}