.fic-1 {
    width: 500px !important;
    height: 400px !important;
    position: relative;
    margin: 0 auto;
}

.fig-1 {
    width: 500px !important;
    height: 400px !important;
    min-width: 500px !important;
    min-height: 400px !important;
    max-width: 500px !important;
    max-height: 400px !important;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
        position: absolute;
        // opacity: 0;
        animation-duration: 2s;
        &:hover {
            cursor: pointer;
            transition: 500ms ease-in-out all;
            transform: scale(1.1);
        }
    }
    .cbeli {
        left: -20px;
        top: 50px;
        opacity: 1;
        animation-name: pap_fbl;
    }
    .coli {
        left: 215px;
        bottom: 50px;
        z-index: 2;
        opacity: 1;
        animation-name: pap_col;
    }
    .happy {
        top: 0px;
        left: 130px;
        opacity: 1;
        z-index: 1;
        animation-name: pap_hap;
    }
    .cseli {
        top: 65px;
        right: -50px;
        opacity: 1;
        animation-name: pap_fbs;
    }
    .tvp {
        z-index: 1;
        right: 10px;
        top: 100px;
        opacity: 1;
        animation-name: pap_tvp;
    }

    &:not(.anm) {
        img {
            opacity: 0;
            animation: none !important;
        }
    }
}

@keyframes pap_fbl {
    0% {
        left: 100px;
        top: 0px;
        transform: scale(.4);
        opacity: 0;
    }
    10% {
        left: 100px;
        top: 0px;
        transform: scale(.4);
        opacity: 0;
    }
    100% {
        left: -20px;
        top: 50px;
        transform: scale(1);
        opacity: 1;
    }
}

@keyframes pap_fbs {
    0% {
        right: 100px;
        top: 250px;
        transform: scale(.4);
        opacity: 0;
    }
    10% {
        right: 100px;
        top: 250px;
        transform: scale(.4);
        opacity: 0;
    }
    100% {
        top: 65px;
        right: -50px;
        transform: scale(1);
        opacity: 1;
    }
}

@keyframes pap_col {
    0% {
        left: 50px;
        bottom: 0px;
        transform: scale(.4);
        opacity: 0;
    }
    10% {
        left: 50px;
        bottom: 0px;
        transform: scale(.4);
        opacity: 0;
    }
    100% {
        left: 215px;
        bottom: 50px;
        transform: scale(1);
        opacity: 1;
    }
}

@keyframes pap_tvp {
    0% {
        right: 80px;
        top: 0px;
        transform: scale(.4);
        opacity: 0;
    }
    10% {
        right: 80px;
        top: 0px;
        transform: scale(.4);
        opacity: 0;
    }
    100% {
        right: 10px;
        top: 100px;
        transform: scale(1);
        opacity: 1;
    }
}

@keyframes pap_hap {
    0% {
        top: 100px;
        left: 130px;
        transform: scale(.4);
        opacity: 0;
    }
    10% {
        top: 100px;
        left: 130px;
        transform: scale(.4);
        opacity: 0;
    }
    100% {
        top: 0px;
        left: 130px;
        transform: scale(1);
        opacity: 1;
    }
}

.fic2 {
    width: 500px !important;
    height: 400px !important;
    position: relative;
    margin: 0 auto;
}

.fig-2 {
    width: 500px !important;
    height: 400px !important;
    min-width: 500px !important;
    min-height: 400px !important;
    max-width: 500px !important;
    max-height: 400px !important;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
        position: absolute;
        opacity: 0;
        animation-duration: 2s;
        &:hover {
            cursor: pointer;
            transition: 500ms ease-in-out all;
            transform: scale(1.1);
        }
    }
    .fbl {
        left: 0px;
        top: 30px;
        opacity: 1;
        // animation-duration: 3s;
        animation-name: pop_fbl;
    }
    .ffl {
        right: -10px;
        top: 60px;
        z-index: 2;
        opacity: 1;
        // animation-duration: 2s;
        animation-name: pop_ffl;
    }
    .fhe {
        top: 275px;
        right: 20px;
        opacity: 1;
        // animation-duration: 2s;
        animation-name: pop_fhe;
    }
    .fpl {
        top: 300px;
        right: -30px;
        opacity: 1;
        // animation-duration: 2s;
        animation-name: pop_fpl;
    }
    .fmd {
        z-index: 0;
        position: relative;
        left: 25px;
        top: -20px;
        opacity: 1;
        // animation-duration: 2.5s;
        animation-name: pop_fmd;
    }
    .fp1 {
        left: 50px;
        top: 5px;
        opacity: 1;
        // animation-duration: 2s;
        animation-name: pop_fp1;
    }
    .fph {
        right: 45px;
        top: 20px;
        opacity: 1;
        // animation-duration: 2s;
        animation-name: pop_fph;
    }
    .ftb {
        left: 25px;
        top: 215px;
        opacity: 1;
        // animation-duration: 2s;
        animation-name: pop_ftb;
    }

    &:not(.anm) {
        img {
            opacity: 0;
            animation: none !important;
        }
    }
}

@keyframes pop_fmd {
    from {
        opacity: 0.5;
        left: -100px;
    }
    to {
        opacity: 1;
        left: 25px;
    }
}

@keyframes pop_fbl {
    0% {
        left: 100px;
        top: 0px;
        transform: scale(.4);
        opacity: 0;
    }
    10% {
        left: 100px;
        top: 0px;
        transform: scale(.4);
        opacity: 0;
    }
    100% {
        left: 0px;
        top: 30px;
        transform: scale(1);
        opacity: 1;
    }
}

@keyframes pop_fp1 {
    0% {
        left: 150px;
        top: 60px;
        transform: scale(.4);
        opacity: 0;
    }
    10% {
        left: 150px;
        top: 60px;
        transform: scale(.4);
        opacity: 0;
    }
    100% {
        left: 50px;
        top: 5px;
        opacity: 1;
        transform: scale(1);
    }
}

@keyframes pop_fph {
    0% {
        right: 140px;
        top: 100px;
        transform: scale(.4);
        opacity: 0;
    }
    10% {
        right: 140px;
        top: 100px;
        transform: scale(.4);
        opacity: 0;
    }
    100% {
        right: 45px;
        top: 20px;
        opacity: 1;
        transform: scale(1);
    }
}

@keyframes pop_ftb {
    0% {
        left: 105px;
        top: 105px;
        transform: scale(.4);
        opacity: 0;
    }
    10% {
        left: 105px;
        top: 105px;
        transform: scale(.4);
        opacity: 0;
    }
    100% {
        left: 25px;
        top: 215px;
        opacity: 1;
        transform: scale(1);
    }
}

@keyframes pop_fhe {
    0% {
        right: 120px;
        top: 100px;
        transform: scale(.4);
        opacity: 0;
    }
    10% {
        right: 120px;
        top: 100px;
        transform: scale(.4);
        opacity: 0;
    }
    100% {
        top: 275px;
        right: 20px;
        opacity: 1;
        transform: scale(1);
    }
}

@keyframes pop_fpl {
    0% {
        right: 120px;
        top: 100px;
        transform: scale(.4);
        opacity: 0;
    }
    10% {
        right: 120px;
        top: 100px;
        transform: scale(.4);
        opacity: 0;
    }
    100% {
        top: 300px;
        right: -30px;
        opacity: 1;
        transform: scale(1);
    }
}

@keyframes pop_ffl {
    0% {
        right: 100px;
        top: 160px;
        transform: scale(.4);
        opacity: 0;
    }
    10% {
        right: 100px;
        top: 160px;
        transform: scale(.4);
        opacity: 0;
    }
    100% {
        right: -10px;
        top: 60px;
        opacity: 1;
        transform: scale(1);
    }
}

.fic4 {
    width: 550px !important;
    height: 440px !important;
    position: relative;
    margin: 0 auto;
}

.fig-4 {
    width: 550px !important;
    height: 440px !important;
    min-width: 550px !important;
    min-height: 440px !important;
    max-width: 550px !important;
    max-height: 440px !important;
    position: relative;

    img {
        position: absolute;
        opacity: 0;
        animation-duration: 1.5s;
        &:hover {
            cursor: pointer;
            transition: 500ms ease-in-out all;
            transform: scale(1.1);
        }
    }
    .beli {
        top: 0;
        left: 0;
        width: 450px;
        z-index: 0;
        opacity: 1;
        animation-name: zin_beli;
    }
    .seli {
        top: 30px;
        right: -120px;
        z-index: 2;
        opacity: 1;
        // animation-duration: 2.5s;
        animation-name: zin_seli;
    }
    .lm1 {
        top: -5px;
        left: 90px;
        opacity: 1;
        z-index: 2;
        // animation-duration: 2.5s;
        animation-name: zin_lm1;
    }
    .lm2 {
        top: 155px;
        right: 40px;
        opacity: 1;
        // animation-duration: 2.5s;
        animation-name: zin_lm2;
    }
    .flw {
        top: 155px;
        left: 90px;
        opacity: 1;
        // animation-duration: 2.5s;
        animation-name: zin_flw;
    }
    .shu {
        top: -5px;
        right: 40px;
        opacity: 1;
        // animation-duration: 2.5s;
        animation-name: zin_shu;
    }

    &:not(.anm) {
        img {
            opacity: 0;
            animation: none !important;
        }
    }
}

@keyframes zin_beli {
    from {
        opacity: 0.5;
        transform: scale(.2);
    }
    10% {
        opacity: 0.5;
        transform: scale(.2);
    }
    to {
        opacity: 1;
        transform: scale(1);
    }
}

@keyframes zin_seli {
    0% {
        opacity: 0;
        right: 250px;
        transform: scale(.2);
    }
    20% {
        opacity: 0;
        right: 250px;
        transform: scale(.2);
    }
    100% {
        opacity: 1;
        right: -120px;
        transform: scale(1);
    }
}

@keyframes zin_lm1 {
    0% {
        opacity: 0;
        left: 10px;
        transform: scale(.8);
    }
    25% {
        opacity: 0;
        left: 10px;
        transform: scale(.8);
    }
    75% {
        opacity: 1;
        left: 30px;
        transform: scale(.8);
    }
    100% {
        left: 90px;
        opacity: 1;
        transform: scale(1);
    }
}

@keyframes zin_lm2 {
    0% {
        opacity: 0;
        right: 250px;
        top: 15px;
        transform: scale(.8);
    }
    40% {
        opacity: 0;
        right: 250px;
        top: 15px;
        transform: scale(.8);
    }
    75% {
        opacity: 1;
        right: 40px;
        top: 0px;
        transform: scale(.9);
    }
    100% {
        right: 40px;
        opacity: 1;
        top: 155px;
        transform: scale(1);
    }
}

@keyframes zin_flw {
    0% {
        opacity: 0;
        left: 300px;
        transform: scale(.8);
    }
    80% {
        opacity: 0;
        left: 300px;
        transform: scale(.8);
    }
    100% {
        left: 90px;
        opacity: 1;
        transform: scale(1);
    }
}

@keyframes zin_shu {
    0% {
        opacity: 0;
        right: 300px;
        transform: scale(.8);
    }
    40% {
        opacity: 0;
        right: 300px;
        transform: scale(.8);
    }
    75% {
        opacity: 1;
        right: 300px;
        transform: scale(.8);
    }
    100% {
        right: 40px;
        opacity: 1;
        transform: scale(1);
    }
}

.pigger {
    width: 200px;
    height: 240px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    .pig-1, .pig-2, .pig-3, .pig-4 {
        width: 200px;
        height: 240px;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
            position: absolute;
        }

        .wi_n {
            opacity: 0.2;
            transition: 1s ease-in all;
            position: relative;
        }

        .cale {
            transition: 1s ease-in all;
            position: relative;
        }

        .cale-pc {
            position: absolute;
            width: 104px;
            background-color: #fff;
            // background-color: #d14;
            height: 144px;
            display: flex;
            left: 36px;
            top: 44px;
            z-index: 10;
            transition: 500ms ease-in all;
            transform: skewX(30deg) rotate(39deg);



            &.anm {
                height: 50px;
                left: -45px;
                top: 60px;
                width: 210px;
                // background-color: red;
                transform: skewX(-50deg) rotate(19deg);
            }



            // .cale-p {
            //     position: absolute;
            //     width: 100%;
            //     height: 100%;
            //     display: flex;
            //     background-color: green;
            //     transform: rotateX(100deg);
            // }
        }

        .mo_p {
            z-index: 10;
            top: 12%;
            right: 69%;
            transition: 2s ease-in all;
            transform: scale(.6);
        }

        .fone {
            position: relative;
        }

        .leta {
            position: absolute;
            z-index: 1;
            width: 57px;
            left: 66px;
            transition: 1s ease-in all;
        }

        .lap_0 {
            animation-name: lap_0;
        }

        .env_0 {
            top: 15px;
            right: 25px;
            z-index: 2;
            animation-name: env_0;
        }

        .env_1 {
            width: 28px;
            left: 40px;
            top: 52px;
            animation-name: env_1;
        }

        .env_2 {
            width: 21px;
            bottom: 95px;
            right: 60px;
            animation-name: env_2;
        }

        .env_3 {
            width: 21px;
            right: 8px;
            bottom: 63px;
            animation-name: env_3;
        }

        .pho {
            z-index: 0;
        }
        .rob {
            z-index: 1;
            left: 50px;
            top: 45px;
        }
        .ey-1, .ey-2 {
            z-index: 2;
        }

        .brief {
            z-index: 2;
            left: 0px;
            top: 110px
        }

        .clip {
            top: 22px;
        }

        .pen {
            bottom: 60px;
            right: 15px;
        }

        .white-flame-body {
            position: absolute;
            top: 145px;
            transform: scaleX(.16) scaley(.35);
            left: 59px;
            z-index : 0;

            .white {
                left: 15px !important;
                top: 20px !important;
                width: 30px !important;
                height: 30px !important;
                background: #dff1ef !important;
                box-shadow: 0px 0px 9px 4px white !important;
            }
            .yellow {
                background: #5CDAD0 !important;
                box-shadow: 0px 0px 9px 4px #fff !important;
            }
            .red {
                border: 1px solid #74bab4 !important;
                background: #79bfba !important;
                box-shadow: 0px 0px 5px 4px #53bfb7 !important;
            }
            .orange {
                display: none;
                background: #50b2aa !important;
                box-shadow: 0px 0px 9px 4px #9ccbc7 !important;
            }
        }
    }
    .pig-1.anm {
        .wi_n {
            opacity: 1;
        }
        .mo_p {
            transform: scale(1);
            right: 18%;
            top: 65%;
        }
    }
    .pig-2 {
        img {
            margin-left: unset !important;
            animation-duration: 2s;
        }
        &:not(.anm) {
            img {
                opacity: 0.1;
                animation: none !important;
            }
        }
    }
    .pig-4 {
        &.anm {
            .leta {
                width: 72px;
                left: 40px;
            }
        }
    }
}

@keyframes lap_0 {
    from {
        opacity: 0.1;
    }
    to {
        opacity: 1;
    }
}

@keyframes env_0 {
    0% {
        opacity: 0.5;
        top: 47px;
        right: 24px;
    }
    15% {
        opacity: 0.5;
        top: 47px;
        right: 24px;
    }
    100% {
        opacity: 1;
        top: 15px;
        right: 24px;
    }
}

@keyframes env_1 {
    0% {
        opacity: 0;
        left: 116px;
        top: 97px;
    }
    15% {
        opacity: 0.4;
        left: 116px;
        top: 97px;
    }
    100% {
        opacity: 1;
        left: 40px;
        top: 52px;
    }
}

@keyframes env_2 {
    0% {
        opacity: 0;
        bottom: 130px;
        right: 60px;
    }
    15% {
        opacity: 0.4;
        bottom: 130px;
        right: 60px;
    }
    100% {
        opacity: 1;
        bottom: 95px;
        right: 60px;
    }
}

@keyframes env_3 {
    0% {
        opacity: 0;
        right: 60px;
        bottom: 105px;
    }
    15% {
        opacity: 0.4;
        right: 60px;
        bottom: 105px;
    }
    100% {
        opacity: 1;
        right: 8px;
        bottom: 63px;
    }
}

.fic1 {
    width: 550px !important;
    height: 500px !important;
    position: relative;
    margin: 0 auto;
}

.sig-4 {
    width: 550px !important;
    height: 500px !important;
    min-width: 550px !important;
    min-height: 500px !important;
    max-width: 550px !important;
    max-height: 500px !important;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    &>img, .divt {
        position: absolute;
        animation-duration: 2s;
    }

    .sbeli {
        left: -40px;
        top: 140px;
        z-index: 1;
        animation-duration: 2s;
        animation-name: pin_sbeli;
    }

    .divt {
        position: absolute;
        left: 30px;
        top: 100px;
        z-index: 0;
        height: 56px;
        width: 200px;
        display: flex;
        flex-direction: row;
        border-radius: 1em;
        justify-content: flex-start;
        align-items: center;
        padding: 0 5px;
        background: #FFFFFF;
        box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.16);
        border-radius: 8px;
        opacity: 1;
        animation-duration: 2.5s;
        animation-name: pin_dft;

        .spac {
            width: 32px;
            height: 32px;
            display: inline-flex;
            border-radius: 50%;
            position: relative;
            justify-content: center;
            align-items: center;
            transition: 0.25s ease-in all;
            margin: 0 10px;
            opacity: 0.5;
            background: #7D97F4;
            filter: grayscale(1);
            img {
                width: 16px;
            }
        }

        .spat {
            font-size: 12px;
            font-weight: 400;
            color: #444 !important;
        }

        &.divt-2 {
            left: 335px;
            top: 280px;
            width: 182px;
            animation-name: pin_dft2;
            .spac {
                background: #E54799;
            }
        }

        &.lv {
            .spac {
                opacity: 1;
                filter: grayscale(0);
            }
        }
    }

    .sseli {
        top: -40px;
        left: 140px;
        opacity: 1;
        animation-duration: 2s;
        animation-name: pin_sseli;
    }

    .doc {
        z-index: 1;
        left: 30px;
        top: 160px;
        opacity: 1;
        animation-duration: 2s;
        animation-name: pin_doc;
    }

    .envelope {
        position: relative;
        cursor: pointer;
        left: 80px;
        top: -50px;
        z-index: 5;
        animation-duration: 2s;
        animation-name: pin_sevi;

        .back {
            position: relative;
            width: 194px;
            left: 1px;
            height: 127px;
            bottom: 1px;
            background-color: #2c59bb;
            border-bottom-left-radius: 1em;
            border-bottom-right-radius: 1em;
        }
    
        .letter {
            position: absolute;
            background-color: #E9EFFD;
            background-color: transparent;
            width: 138px;
            height: 100px;
            top:20px;
            overflow: hidden;
            left:calc((195px - 138px) / 2);
            transition: 1s;
            padding: 0px;
            img {
                position: absolute;
                top: -16px;
                width: 177px;
                left: -19px;
            }
        }

        ._envv {
            width: 197.5px;
            height: 127px;
            bottom: 0;
            left: 0px;
            position: absolute;
        }

        .top {
            position: absolute;
            border-top: 105px solid #b3caff;
            border-top: 105px solid #a9c3ff;
            border-left: 125px solid transparent;
            border-right: 125px solid transparent;
            border-top: 85px solid #a9c3ff;
            border-left: 98px solid transparent;
            border-right: 98px solid transparent;
            height:0;
            width:0;
            top:0;
            cursor: pointer;
            z-index: 2;
            transform-origin: top;
            transition: .4s;
        }

        &:not(.closed) {
            .top {
                transform: rotateX(185deg);
                border-top-color: #2c59bb;
                z-index: -1;
            }
            .letter {
                transform: translateY(-100px);
                z-index:0;
                height: 200px;
            }
        }
    }

    &:not(.anm) {
        &>img, .divt, .envelope {
            opacity: 0;
            animation: none !important;
        }
    }

}

@keyframes pin_sevi {
    0% {
        opacity: 0;
        left: 30px;
        top: 50px;
        transform: scale(.4);
    }
    25% {
        opacity: 0.2;
        left: 30px;
        top: 50px;
        transform: scale(.4);
    }
    70% {
        opacity: 1;
        left: 80px;
        top: -50px;
        transform: scale(1);
    }
}

@keyframes pin_sbeli {
    0% {
        opacity: 0;
        left: 0px;
        top: 100px;
        transform: scale(.4);
    }
    25% {
        opacity: 0.2;
        left: 0px;
        top: 100px;
        transform: scale(.4);
    }
    100% {
        opacity: 1;
        left: -40px;
        top: 140px;
        transform: scale(1);
    }
}

@keyframes pin_sseli {
    0% {
        opacity: 0;
        left: 180px;
        top: 180px;
        transform: scale(.4);
    }
    25% {
        opacity: 0.2;
        left: 180px;
        top: 180px;
        transform: scale(.4);
    }
    100% {
        opacity: 1;
        top: -40px;
        left: 140px;
        transform: scale(1);
    }
}

@keyframes pin_doc {
    0% {
        opacity: 0;
        left: -20px;
        top: 90px;
        transform: scale(.8);
    }
    25% {
        opacity: 0.2;
        left: -20px;
        top: 90px;
        transform: scale(.8);
    }
    100% {
        opacity: 1;
        left: 30px;
        top: 160px;
        transform: scale(1);
    }
}

@keyframes pin_dft {
    0% {
        opacity: 0;
        left: 0px;
        top: 130px;
        transform: scale(.8);
    }
    25% {
        opacity: 0;
        left: 0px;
        top: 130px;
        transform: scale(.8);
    }
    100% {
        position: absolute;
        opacity: 1;
        left: 30px;
        top: 100px;
        transform: scale(1);
    }
}

@keyframes pin_dft2 {
    0% {
        opacity: 0;
        left: 280px;
        top: 100px;
        transform: scale(.8);
    }
    25% {
        opacity: 0;
        left: 280px;
        top: 100px;
        transform: scale(.8);
    }
    100% {
        opacity: 1;
        left: 335px;
        top: 280px;
        transform: scale(1);
    }
}

.fic0 {
    width: 550px !important;
    height: 400px !important;
    position: relative;
    margin: 0 auto;
}

.sig-0 {
    width: 550px !important;
    height: 400px !important;
    min-width: 550px !important;
    min-height: 400px !important;
    max-width: 550px !important;
    max-height: 400px !important;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    &>img {
        position: absolute;
        animation-duration: 2s;
    }

    ._sbli {
        right: -115px;
        bottom: -150px;
        z-index: 0;
        opacity: 1;
        animation-name: pin_sbli;
    }

    ._midl {
        position: relative;
        left: 0px;
        z-index: 1;
        opacity: 1;
        animation-name: pin_midl;
    }

    ._mark {
        top: 60px;
        left: 60px;
        opacity: 1;
        animation-name: pin_mark;
    }

    ._phne {
        bottom: 30px;
        left: 40px;
        z-index: 2;
        opacity: 1;
        animation-name: pin_phne;
    }

    &:not(.anm) {
        &>img {
            opacity: 0;
            animation: none !important;
        }
    }
}

@keyframes pin_sbli {
    0% {
        opacity: 0;
        right: 0px;
        bottom: 0px;
        transform: scale(.4);
    }
    25% {
        opacity: 0;
        right: 0px;
        bottom: 0px;
        transform: scale(.4);
    }
    70% {
        opacity: 1;
        right: -115px;
        bottom: -150px;
        transform: scale(1);
    }
}

@keyframes pin_mark {
    0% {
        opacity: 0;
        top: 180px;
        left: 200px;
        transform: scale(.4);
    }
    25% {
        opacity: 0;
        top: 180px;
        left: 200px;
        transform: scale(.4);
    }
    70% {
        opacity: 1;
        bottom: 30px;
        left: 40px;
        transform: scale(1);
    }
}

@keyframes pin_phne {
    0% {
        opacity: 0;
        bottom: 140px;
        left: 100px;
        transform: scale(.4);
    }
    25% {
        opacity: 0;
        bottom: 140px;
        left: 100px;
        transform: scale(.4);
    }
    70% {
        opacity: 1;
        bottom: 80px;
        left: 40px;
        transform: scale(1);
    }
}

@keyframes pin_midl {
    0% {
        opacity: 0.1;
        left: 50px;
        top: 30px;
        transform: scale(.4);
    }
    25% {
        opacity: 0.4;
        left: 50px;
        top: 30px;
        transform: scale(.4);
    }
    70% {
        opacity: 1;
        left: 0px;
        top: 0px;
        transform: scale(1);
    }
}




.tic0 {
    width: 550px !important;
    height: 400px !important;
    position: relative;
    margin: 0 auto;
}

.tig-0, .tig-1, .tig-4 {
    width: 550px !important;
    height: 400px !important;
    min-width: 550px !important;
    min-height: 400px !important;
    max-width: 550px !important;
    max-height: 400px !important;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    .tch-eli, .tch-eli2 {
        position: absolute;
        height: 65px;
        width: 65px;
        border-radius: 50%;
        left: 0px;
        top: 90px;
        transition: 1.5s ease-in-out all;
        background: radial-gradient(45.18% 45.18% at 37.65% 25.18%, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */, linear-gradient(132.82deg, rgba(255, 255, 255, 0.47) 14.64%, rgba(255, 255, 255, 0) 85.8%), linear-gradient(0deg, rgba(57, 89, 241, 0.68), rgba(57, 89, 241, 0.68)), #FFFFFF;
        box-shadow: 50px 10px 96px rgba(125, 151, 244, 0.57);
        filter: blur(2px);
    }

    &.tig-1 {
        img {
            position: absolute;
            transition: 1.5s ease-in-out all;
        }
        .tch-eli {
            top: 15px;
            left: 260px;
            height: 46px;
            width: 48px;
            opacity: 0;
            transform: scale(.1);
            transition: 2s ease-in-out all;
            background: radial-gradient(45.18% 45.18% at 37.65% 25.18%, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */, linear-gradient(132.82deg, rgba(255, 255, 255, 0.47) 14.64%, rgba(255, 255, 255, 0) 85.8%), #3959F1;
            box-shadow: 50px 100px 100px rgba(125, 151, 244, 0.26);
            filter: blur(2px);
        }
        .tch-eli2 {
            bottom: 160px;
            right: 20px;
            height: 24px;
            width: 24px;
            left: unset;
            top: unset;
            filter: unset;
            opacity: 0;
            transition: 2s ease-in-out all;
            background: radial-gradient(45.18% 45.18% at 37.65% 25.18%, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */, linear-gradient(132.82deg, rgba(255, 255, 255, 0.47) 14.64%, rgba(255, 255, 255, 0) 85.8%), #3959F1;
            box-shadow: 50px 100px 100px rgba(125, 151, 244, 0.26);
        }
        .tboy {
            z-index: 3;
            left: 305px;
            bottom: 60px;
        }
        .tfone {
            position: relative;
            opacity: 0.8;
        }
        .tllb {
            bottom: 170px;
            left: 201px;
            opacity: 0;
            z-index: -1;
        }
        .tlok {
            bottom: 120px;
            left: 210px;
            opacity: 0;
        }
        .tche {
            top: 134px;
            left: 210px;
            opacity: 0;
        }
        .tlle {
            top: 70px;
            right: 220px;
            opacity: 0;
        }
        .tlli {
            top: 80px;
            left: 200px;
            z-index: -1;
            opacity: 0;
        }
        .ther {
            bottom: 60px;
            right: 0px;
            opacity: 0;
            transition: 2.5s ease-in-out all;
        }
        .tvas1 {
            bottom: 70px;
            left: 120px;
            opacity: 0;
            transition: 2.5s ease-in-out all;
        }
        .tvas2 {
            bottom: 160px;
            right: 220px;
            opacity: 0;
            transition: 2.5s ease-in-out all;
        }
        &.anm {
            .tfone {
                opacity: 1;
            }
            .tche {
                opacity: 1;
                left: 240px;
            }
            .tlok {
                opacity: 1;
                left: 175px;
            }
            .tch-eli {
                opacity: 1;
                filter: blur(2px);
                transform: scale(1);
            }
            .tlle {
                top: 10px;
                right: 25px;
                opacity: 1;
            }
            .tlli {
                top: 40px;
                left: 40px;
                opacity: 1;
            }
            .ther {
                bottom: 60px;
                right: 0px;
                opacity: 1;
            }
            .tllb {
                opacity: 1;
                left: 30px;
                z-index: 0;
            }
            .tlle {
                opacity: 1;
                
            }
            .tlli {
                top: 40px;
                left: 40px;
                z-index: 0;
                opacity: 1;
            }
            .tlle {
                top: 10px;
                right: 25px;
                opacity: 1;
            }
            .ther, .tvas1, .tvas2, .tch-eli2 {
                opacity: 1;
            }
            .tvas2 {
                right: 25px;
            }
        }
    }

    .d0, .d1, .d2 {
        position: absolute;
        top: 40px;
        z-index: 2;
        opacity: 0;
        transition: 1s ease-in-out all;
    }

    .d0 {
        left: 241px;
        top: 125px;
    }

    .d1 {
        left: 251px;
        top: 104px;
        transition: 1.25s ease-in-out all;
    }

    .d2 {
        left: 275px;
        top: 48px;
        height: 70px;
        width: 95px;
        color: #000 !important;
        font-size: 14px;
        font-weight: 500;
        text-align: center;
        justify-content: center;
        align-items: center;
        display: inline-flex;
        border-radius: 50%;
        background: #FFFFFF;
        border: 1px solid #C2C9E4;
        transition: 1.5s ease-in-out all;
        box-shadow: 0px 16px 32px rgba(0, 0, 0, 0.16);
    }

    .girl2 {
        position: absolute;
        left: 100px;
        top: 115px;
        z-index: 2;
        opacity: 0;
        transition: 1s ease-in-out all;
    }

    .pop {
        position: absolute;
        left: 300px;
        opacity: 0;
        transition: 1s ease-in-out all;
    }

    .msac {
        position: absolute;
        left: 0px;
        bottom: 10px;
        height: 220px;
        width: 300px;
        z-index: 2;
        img {
            position: absolute;
            animation-duration: 1s;
            transition: 1s ease-in-out all;
        }
        .gi_rl {
            left: 20px;
            top: 20px;
            z-index: 1;
        }
        .lhand {
            left: 15px;
            top: 50px;
            opacity: 1;
        }
        .rhand {
            left: 47px;
            top: 54px;
            z-index: 2;
            opacity: 1;
        }
        .mic_ro {
            top: 5px;
            opacity: 1;
        }
    }
    &.anm {
        .rhand {
            top: 50px;
            left: 50px;
            transform: rotate(-15deg);
        }
        .mic_ro {
            top: -16px;
            transform: rotate(-15deg);
        }
        .lhand {
            left: 16px;
            top: 50px;
            height: 50px;
        }

        .girl2 {
            left: 160px;
            opacity: 1;
        }

        .pop {
            left: 275px;
            opacity: 1;
        }
        .tch-eli {
            filter: blur(0px);
        }
        .d0, .d1, .d2 {
            opacity: 1;
        }
    }
}


@keyframes almi {
    0% {
        opacity: 0;
        transform: scaleY(0);
    }
    50% {
        opacity: 0.4;
        transform: scaleY(0);
    }
    100% {
        opacity: 1;
        transform: scaleY(1);
    }
}







.aic1, .aic2, .aic3, .aic4 {
    width: 550px !important;
    height: 400px !important;
    position: relative;
    margin: 0 auto;
}

.aig-1, .aig-2, .aig-3, .aig-4 {
    width: 550px !important;
    height: 400px !important;
    min-width: 550px !important;
    min-height: 400px !important;
    max-width: 550px !important;
    max-height: 400px !important;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    // background-color: #000;

    .tch-eli, .tch-eli2 {
        position: absolute;
        height: 65px;
        width: 65px;
        border-radius: 50%;
        left: 0px;
        top: 90px;
        transition: 1.5s ease-in-out all;
        background: radial-gradient(45.18% 45.18% at 37.65% 25.18%, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */, linear-gradient(132.82deg, rgba(255, 255, 255, 0.47) 14.64%, rgba(255, 255, 255, 0) 85.8%), #3959F1;
        box-shadow: 50px 100px 100px rgba(125, 151, 244, 0.26);
        filter: blur(4px);
    }

    &.aig-1 {
        img, .tch-eli {
            position: absolute;
            transition: 1.5s ease-in-out all;
            opacity: 0;
        }
        .wpg {
            position: relative;
            opacity: 0.7;
            z-index: 1;
            transform: translateX(0px);
            transition: .75s ease-in-out all;
        }
        .mouse {
            z-index: 100;
            left: 90px;
            opacity: 0;
            bottom: 250px;
            transition: 1s ease-in-out all;
        }
        ._bg1 {
            z-index: 0;
            left: 140px;
            top: 30px;
        }
        .lm {
            z-index: 10;
            left: 130px;
            bottom: 73px;
            opacity: 0;
            transform: scaleY(0);
        }
        .lemo {
            right: 40px;
            top: 111px;
            z-index: 1000;
        }
        .tch-eli {
            top: unset;
            left: 42px;
            height: 64px;
            width: 64px;
            z-index: 100;
            bottom: 150px;
            filter: blur(0px);
            transition: 3s ease-in-out all;
        }

        &.anm {
            img {
                opacity: 1;
            }
            .wpg {
                position: relative;
                transform: translateX(-70px);
            }
            .lemo {
                right: 40px;
                top: 35px;
            }
            .ninety {
                position: absolute;
                z-index: 1000;
                left: 10px;
                top: 80px;
            }
            .tch-eli {
                opacity: 1;
                bottom: 100px;
                filter: blur(4px);
            }
            .lm {
                opacity: 1;
                animation-duration: 1.5s;
                animation-name: almi;
                transform: scaleY(1);
            }
            .mouse {
                left: 100px;
                bottom: 70px;
            }
            .lido {
                z-index: 10;
                left: 290px;
                bottom: 85px;
            }
        }
    }

    &.aig-2 {
        img {
            position: absolute;
            transition: 1.5s ease-in-out all;
            opacity: 0;
        }
        .frame {
            position: relative;
            z-index: 1;
            opacity: 1;
        }
        ._bg2 {
            z-index: 0;
            top: 10px;
            left: 80px;
        }
        .sgt {
            opacity: 1;
            top: 90px;
            left: 200px;
        }
        .sgr {
            opacity: 1;
            top: 140px;
            right: 90px;
        }
        .snt {
            top: 50px;
            left: 200px;
            z-index: 100;
        }
        .snb {
            opacity: 0.5;
            bottom: 50px;
            z-index: 100;
            // left: 200px;
            left: 170px;
        }
        .sui {
            z-index: 100;
            top: 80px;
            right: 116px;
            transition: 1s ease-in-out all;
            transform: translateX(50px) scale(.3);
        }
        .sux {
            z-index: 100;
            bottom: 80px;
            left: 11px;
            transition: 1s ease-in-out all;
            transition-delay: .3s;
            transform: translateX(70px) scale(.3);
        }
        .sss {
            right: 57px;
            z-index: 100;
            bottom: 120px;
            transition: 1s ease-in-out all;
            transition-delay: .7s;
            transform: translateX(-50px) scale(.3);
        }
        .spic {
            z-index: 100;
            top: 110px;
            left: 230px;
            transform: translateX(-50px);
        }
        .splay {
            z-index: 100;
            left: 125px;
            top: 110px;
        }
        .nclock {
            z-index: 101;
            left: 60px;
            transform: translateX(18px) scale(.3);
            top: 120px;
        }
        &.anm {
            img {
                opacity: 1;
            }
            .spic, .nclock {
                transform: translateX(0px) scale(1);
            }
            .nclock {
                top: 130px;
            }
            .snt {
                left: 290px;
            }
            .sgt {
                top: 50px;
                left: 180px;
            }
            .sgr {
                right: 35px;
            }
            &.us {
                .sui, .sux, .sss {
                    transform: translateX(0px) scale(1);
                }
                .splay {
                    top: 110px;
                    left: 180px;
                }
            }
        }
    }

    &.aig-3 {
        &>img {
            position: absolute;
            transition: 1.5s ease-in-out all;
            // opacity: 0;
        }
        .extr {
            position: relative;
            z-index: 1;
            opacity: 1;
        }
        .aseli {
            top: 65px;
            left: 215px;
            z-index: 10;
        }
        .cd-axis {
            position: absolute;
            width: 300px;
            // background: red;
            height: 300px;
            display: flex;
            justify-content: center;
            align-items: center;
            z-index: 100;
            &>img, .sphp, .sts {
                background: #fff;
                position: absolute;
                width: 64px;
                height: 64px;
                display: flex;
                padding: .5em;
                z-index: 100;
                border: 1px solid #ebefff;
                border-radius: 50%;
                justify-content: center;
                align-items: center;
            }
            .sreact {
                left: 20px;
                top: 50px;
            }
            .snode {
                bottom: 15px;
                right: 60px;
            }
            .sts {
                left: -35px;
                bottom: 45px;
                padding: 8px;
                background-color: #fff;
                justify-content: center;
                img {
                    position: relative;
                    left: 0px;
                    top: 0px;
                }
            }
            .sangul {
                top: -20px;
                right: 5px;
            }
            .sphp {
                padding: 4px;
                background-color: #fff;
                justify-content: center;
                right: -50px;
                img {
                    position: relative;
                    left: 0px;
                    top: 0px;
                }
            }
        }
    }

    &.aig-4 {
        .cdrad {
            height: 154px;
            width: 154px;
            display: flex;
            border-radius: 50%;
            left: 100px;
            top: 100px;
            opacity: 0;
            z-index: 0;
            transition: 3s ease-in-out all;
            position: absolute;
            background: radial-gradient(45.18% 45.18% at 37.65% 25.18%, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */, linear-gradient(132.82deg, rgba(255, 255, 255, 0.47) 14.64%, rgba(255, 255, 255, 0) 85.8%), #3959F1;
            box-shadow: 50px 100px 100px rgba(125, 151, 244, 0.26);
        }
        .clplane {
            width: 418px;
            height: 268px;
            background: #FFFFFF;
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.16);
            border-radius: 16px;
            opacity: 0.1;
            transition: 300ms ease-in-out all;

            img {
                opacity: 0;
                position: absolute;
                transition: 2s ease-in-out all;
            }

            .clcld {
                z-index: 10;
                top: 35px;
            }

            .cldoc {
                right: -50px;
                top: -20px;
                z-index: 101;
                transition: 2s ease-in-out all;
                transform: translateX(-100px) scale(.3);
            }

            .scay {
                position: absolute;
                left: 10px;
                top: 10px;
                z-index: 100;
                color: #000 !important;
                font-weight: 500;
                font-size: 12px;
                width: 78px;
                opacity: 1;
                min-height: 30px;
            }

            .clg {
                position: absolute;
                z-index: 1;
                width: 100%;
                height: 120px;
                top: 70px;
                display: flex;
                overflow: hidden;
                justify-content: center;
                align-items: flex-start;
                // background-color: red;
                img {
                    position: absolute;
                    opacity: 1;
                }
                .cl1 {
                    left: calc(14%);
                }
                .cl2 {
                    top: 10px;
                    left: calc(33%);
                }
                .cl3 {
                    top: 10px;
                    left: calc(50%);
                }
                .cl4 {
                    left: calc(53% - 2px);
                }
                .hei {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    display: flex;
                    z-index: 10;
                    top: 0px;
                    transition: 2s ease-in-out all;
                    transition-delay: 2s;
                    background: #fff;
                }
            }

            .clnode {
                position: absolute;
                bottom: 20px;
                z-index: 100;
                &._1 {
                    left: calc(10% + 1px);
                    bottom: 50px;
                    transform: scale(.4);
                }
                &._2 {
                    left: calc(27% + 1px);
                    bottom: 40px;
                    transform: scale(.6);
                }
                &._3 {
                    left: calc(47% + 2px);
                    bottom: 30px;
                    transform: scale(.8);
                }
                &._4 {
                    left: 73%;
                }
                .cd1 { bottom: 80px; z-index: 4; }
                .cd2 { bottom: 80px; z-index: 3; }
                .cd3 { bottom: 80px; z-index: 2; }
                .cd4 { bottom: 80px; z-index: 1; }
            }
        }
        &.anm {
            img, .clplane {
                opacity: 1 !important;
            }
            .clcld {
                top: 20px;
            }
            .cdrad {
                filter: blur(3px);
                left: 15px;
                top: 15px;
                opacity: 1;
            }
            .clg {
                .hei {
                    top: 101%;
                }
            }
            .cldoc {
                top: -40px;
                transform: translateX(0px) scale(1);
            }
            .cd1 { animation-delay: 1s !important; animation-name: cl_d1; bottom: 54px !important; }
            .cd2 { animation-delay: 1s !important; animation-name: cl_d2; bottom: 36px !important; }
            .cd3 { animation-delay: 1s !important; animation-name: cl_d3; bottom: 18px !important; }
            .cd4 { animation-delay: 0s !important; animation-name: cl_d4; bottom: 0px !important; }
        }
    }
}


@keyframes cl_d1 {
    from { opacity: 0; bottom: 80px; }
    50% { opacity: 0; bottom: 80px; }
    to { opacity: 1; bottom: 54px; }
}

@keyframes cl_d2 {
    from { opacity: 0; bottom: 80px; }
    30% { opacity: 0.5; bottom: 60px; }
    to { opacity: 1; bottom: 36px; }
}

@keyframes cl_d3 {
    from { opacity: 0; bottom: 80px; }
    30% { opacity: 0.5; bottom: 40px; }
    to { opacity: 1; bottom: 18px; }
}

@keyframes cl_d4 {
    from { opacity: 0; bottom: 80px; }
    30% { opacity: 0.5; bottom: 32px; }
    to { opacity: 1; bottom: 0px; }
}










.wic1, .wic2, .wic3, .wic4 {
    width: 550px !important;
    height: 400px !important;
    position: relative;
    margin: 0 auto;
}

.wig-1, .wig-2, .wig-3, .wig-4 {
    width: 550px !important;
    height: 400px !important;
    min-width: 550px !important;
    min-height: 400px !important;
    max-width: 550px !important;
    max-height: 400px !important;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    // background-color: #000;

    .tch-eli, .tch-eli2 {
        position: absolute;
        height: 65px;
        width: 65px;
        border-radius: 50%;
        left: 0px;
        top: 90px;
        transition: 1.5s ease-in-out all;
        background: radial-gradient(45.18% 45.18% at 37.65% 25.18%, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */, linear-gradient(132.82deg, rgba(255, 255, 255, 0.47) 14.64%, rgba(255, 255, 255, 0) 85.8%), #3959F1;
        box-shadow: 50px 100px 100px rgba(125, 151, 244, 0.26);
        filter: blur(4px);
    }

    &.wig-1 {
        img {
            position: absolute;
            transition: 1.5s ease-in-out all;
            opacity: 0;
        }
        .pwinw {
            width: 225px;
            height: 280px;
            display: flex;
            border-radius: 1em;
            position: relative;
            background-color: #fff;
            flex-direction: column;
            transition: 1s ease-in all;
            box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.16);
            border-radius: 14px;
            .pwtb  {
                height: 40px;
                width: 100%;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                padding-left: 1em;
                flex-direction: row;
                background: linear-gradient(88.21deg, #7D97F4 5.51%, #03259C 141.95%);
                border-radius: 12px 12px 0px 0px;
            }
            .pweb {
                width: 95px;
                height: 40px;
                position: absolute;
                right: 10px;
                top: 60px;
                display: flex;
                flex-direction: column;
                text-align: center;
                font-size: 14px;
                letter-spacing: 1px;
                font-weight: 600 !important;
                justify-content: center;
                align-items: center;
                background: #E54799;
                line-height: 1.1;
                border-radius: 4px;
                z-index: 100;
                opacity: 0;
                transform: scale(.8);
                transition: .5s ease-in all;
                transition-delay: 1s;
            }
            .pwbd {
                display: flex;
                flex-direction: row;
                img {
                    position: relative;
                }
                .p1 {
                    width: 205px;
                    padding: 20px;
                    position: absolute;
                    right: 20px;
                    bottom: 20px;
                    z-index: 2;
                    opacity: 0;
                    background-color: #fff;
                }
                .beach {
                    position: absolute;
                    left: 0px;
                    bottom: 0px;
                    z-index: 10;
                    opacity: 1;
                }
            }
            .dave {
                position: absolute;
                top: 90px;
                z-index: 100;
                left: -40px;
                transition-delay: 1.5s;
            }
            .mary {
                position: absolute;
                bottom: 10px;
                z-index: 100;
                right: -40px;
                transition-delay: 1.5s;
            }
        }
        .wpg {
            position: relative;
            opacity: 0.7;
            z-index: 1;
            transform: translateX(0px);
            transition: .75s ease-in-out all;
        }
        .mouse {
            z-index: 100;
            left: 90px;
            opacity: 0;
            bottom: 250px;
            transition: 1s ease-in-out all;
        }

        &.anm {
            img {
                opacity: 1 !important;
            }
            .pwinw {
                width: 420px;
            }
            .pweb {
                right: -39px;
                top: 50px;
                opacity: 1;
                transform: scale(1);
            }
        }
    }
    &.wig-2 {
        .pap-l, .pap-r {
            background: #FFFFFF;
            display: inline-flex;
            justify-content: flex-start;
            flex-direction: column;
            position: relative;
            width: 200px;
            height: 260px;
            box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.16);
            border-radius: 0px 12px 12px 0px;
            transition: .5s ease-in all;
            opacity: 0.5;

            .phd {
                width: 100%;
                height: 58px;
                display: flex;
                transition: 1.5s ease-in all;
                background: linear-gradient(88.21deg, #7D97F4 5.51%, #03259C 141.95%);
                border-radius: 0px 12px 0px 0px;
            }

            &.pap-l {
                border-radius: 12px 0px 0px 12px;
                box-shadow: none;
                transform: translateX(50px);
                .phd {
                    border-radius: 12px 0px 0px 0px;
                }
            }
            &.pap-r {
                transform: translateX(-150px);
            }
            .blk {
                width: 48px;
                height: 37px;
                display: inline-flex;
                background: #C2C9E4;
                border-radius: 4px;
                margin-left: 24px;
                margin-bottom: 10px;
                transition-delay: 1s;
                opacity: 0;
                &._2 {
                    margin-top: 10px;
                } 
            }
            .blk-sm {
                height: 6px;
                width: 6px;
                opacity: 0;
                margin: 3px 25px;
                background: #C2C9E4;
                border-radius: 1px;
                transition: 1s ease-in all;
                transition-delay: 1s;
            }
            .phb {
                display: block;
                height: calc(100% - 58px);
                margin: 25px auto 0px;
                width: 80%;
                opacity: 0;
                transition: 1s ease-in all;
                transition-delay: 1s;

                &>div {
                    width: 44%;
                    margin: 3px 3% 12px;
                    padding: 0px;
                    float: left;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-direction: column;
                    position: relative;
                    .blk, .blk-sm {
                        display: inline-flex;
                        margin-left: 0px;
                    }
                    .blk-sm {
                        margin-bottom: 0px;
                        margin: 3px auto;
                    }
                }
            }
        }
        .pdsh {
            position: absolute;
            width: 0px;
            height: 30px;
            opacity: 0;
            transition: 1s ease-in all;
            transition-delay: .5s;
            border: 2px dashed #E54799;
        }
        &.anm {
            .pap-l {
                opacity: 1;
                transform: translateX(-20px);
                box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.16);
                .phd {
                    border-radius: 4px;
                    margin: 13px 24px;
                    width: calc(100% - 48px);
                }
            }

            .blk, .phb {
                opacity: 1;
            }
            .blk-sm {
                opacity: 1;
                width: calc(100% - 50px);
                &._2 {
                    width: calc(100% - 65px);
                }
            }

            .pap-r {
                opacity: 1;
                transform: translateX(20px);
                .blk-sm {
                    width: calc(100% - 10px);
                    &._2 {
                        width: calc(100% - 20px);
                    }
                }
            }
            .pdsh {
                opacity: 1;
                height: 302px;
            }
        }
    }
    &.wig-3 {
        img {
            position: absolute;
            opacity: 0.9;
            filter: grayscale(1);
            transform: scale(.9);
            transition: 1s ease-in-out all;
            &:not(.wbg) {
                background: #FFFFFF;
                box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.16);
                border-radius: 8px;
            }
        }
        .wbg {
            position: relative;
            transform: scale(1);
            filter: grayscale(0);
            opacity: 1;
            transition: 1.5s ease-in-out all;
        }
        .f1 {
            z-index: 5;
        }
        .f2 {
            z-index: 4;
            transition-delay: .2s;
        }
        .f3 {
            z-index: 2;
            transition-delay: .6s;
        }
        .f4 {
            z-index: 3;
            transition-delay: .4s;
        }
        &.anm {
            img {
                opacity: 1;
            }
            .f1 {
                transform: translate(-105px, -85px) scale(.9);
            }
            .f2 {
                transform: translate(105px, -85px) scale(.9);
            }
            .f3 {
                transform: translate(-105px, 85px) scale(.9);
            }
            .f4 {
                transform: translate(105px, 85px) scale(.9);
            }
            &.us {
                img {
                    filter: grayscale(0);
                }
                .f1 {
                    transform: translate(-105px, -85px) scale(1);
                }
                .f2 {
                    transform: translate(105px, -85px) scale(1);
                }
                .f3 {
                    transform: translate(-105px, 85px) scale(1);
                }
                .f4 {
                    transform: translate(105px, 85px) scale(1);
                }
            }
        }
    }
}













.mic1, .mic2, .mic3, .mic4 {
    width: 550px !important;
    height: 400px !important;
    position: relative;
    margin: 0 auto;
}

.mig-1, .mig-2, .mig-3, .mig-4 {
    width: 550px !important;
    height: 400px !important;
    min-width: 550px !important;
    min-height: 400px !important;
    max-width: 550px !important;
    max-height: 400px !important;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    // background-color: #000;

    .tch-eli, .tch-eli2 {
        position: absolute;
        height: 65px;
        width: 65px;
        border-radius: 50%;
        left: 0px;
        top: 90px;
        transition: 1.5s ease-in-out all;
        background: radial-gradient(45.18% 45.18% at 37.65% 25.18%, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */, linear-gradient(132.82deg, rgba(255, 255, 255, 0.47) 14.64%, rgba(255, 255, 255, 0) 85.8%), #3959F1;
        box-shadow: 50px 100px 100px rgba(125, 151, 244, 0.26);
        filter: blur(4px);
    }

    &.mig-1 {
        // overflow: hidden;
        * {
            transition: 1s ease-in all;
        }
        .wp1, .wp2 {
            position: absolute;
            left: 200px;
            top: 50px;
            width: 180px;
            height: 180px;
            background-color: #fff;
            border-radius: 10px;
            z-index: 1;
            padding: 1em;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
            z-index: 10;
            opacity: 1;
            transform: translateY(0);
            transition: 500ms ease-in all;
            transition-delay: .25s;
            box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.16);

            &.noshow {
                opacity: 0;
                transform: translateY(50px);
            }

            .nike, .well {
                width: 100%;
                border-radius: 10px;
            }

            .pp {
                width: 90%;
                height: 10px;
                margin-top: 10px;
                border-radius: 4px;
                background-color: #edeaea;
                &._2 {
                    width: 75%;
                }
            }

            &.wp2 {
                top: 140px;
                left: 300px;
                z-index: 1;
                background-color: #edeaea;
                transform: rotate(15deg);
                transition: 2s ease-in all;
                .pp {
                    background-color: #fff;
                }

                &.noshow {
                    opacity: 0;
                    left: 200px;
                    top: 50px;
                    transform: rotate(0deg);
                }
            }
        }
        .extr {
            position: relative;
            left: 132px;
            z-index: 0;
        }
        .tiblock {
            height: 20px;
        }
        .chiwi {
            z-index: 1;
            display: flex;
            flex-direction: column;
            padding-left: 0px;
            position: absolute;
            left: 0px;
            list-style: none !important;
            transition: 2500ms ease-in all;

            .chiw {
                list-style: none !important;
                position: relative;
                flex-direction: column;
                padding-left: 50px;
                display: flex;
                display: none;
                margin-top: 10px;
                margin-bottom: 10px;
                opacity: 0;
                transition: 2500ms ease-in all;

                .chim {
                    position: absolute;
                    left: 0px;
                    height: 58px;
                    top: -5px;
                }

                .cmsg {
                    display: flex;
                    margin: 3px;
                    font-size: 12px;
                    width: fit-content;
                    background: #fff;
                    padding: 6px 10px;
                    border-radius: 8px;
                    justify-content: center;
                    align-items: center;
                    color: #000 !important;
                    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.16);

                    img {
                        width: 16px;
                        height: 16px;
                        margin: 2px;
                        &.luv {
                            margin-left: 7px;
                            position: relative;
                            height: 14px;
                            width: 14px;
                        }
                        &.love {
                            margin-left: 1px;
                            margin-right: 0px;
                            height: 12px;
                            width: 12px;
                        }
                        &.smile {
                            margin: 0px;
                        }
                    }
                }
            }
        }
    }
    &.mig-2 {
        * {
            transition: 1s ease-in all;
        }
        .balle {
            height: 260px;
            width: 260px;
            display: flex;
            position: absolute;
            z-index: 0;
            border-radius: 50%;
            background: radial-gradient(45.18% 45.18% at 37.65% 25.18%, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */, linear-gradient(132.82deg, rgba(255, 255, 255, 0.47) 14.64%, rgba(255, 255, 255, 0) 85.8%), #3959F1;
            box-shadow: 50px 100px 100px rgba(125, 151, 244, 0.26);
            filter: blur(3px);
            opacity: 0;
            left: 200px;
            transform: scale(.8);
        }
        .pg {
            position: relative;
            z-index: 1;
            left: 50px;
            opacity: 0.5;
            transition: 500ms ease-in all;
        }
        .p1 {
            position: absolute;
            left: 110px;
            top: 10px;
            z-index: 2;
            opacity: 0;
            transform: scale(.1);
            transition: 500ms ease-in all;
            transition-delay: .5s;
        }
        .follow {
            position: absolute;
            left: 20px;
            top: 150px;
            z-index: 2;
            opacity: 0;
            transform: scale(.1);
            transition: 500ms ease-in all;
            transition-delay: .75s;
        }
        .pollow {
            position: absolute;
            right: -20px;
            top: 120px;
            z-index: 2;
            opacity: 0;
            transform: scale(.1);
            transition: 500ms ease-in all;
            transition-delay: 2.25s;
        }
        .p2 {
            position: absolute;
            left: 50px;
            top: 240px;
            z-index: 2;
            opacity: 0;
            transform: scale(.1);
            transition: 500ms ease-in all;
            transition-delay: 1s;
        }
        .p3 {
            position: absolute;
            left: 335px;
            top: 290px;
            z-index: 2;
            opacity: 0;
            transform: scale(.1);
            transition: 500ms ease-in all;
            transition-delay: 1.5s;
        }
        .p4 {
            position: absolute;
            right: 100px;
            top: 140px;
            z-index: 2;
            opacity: 0;
            transform: scale(.1);
            transition: 500ms ease-in all;
            transition-delay: 2s;
        }
        .p5 {
            position: absolute;
            right: 155px;
            top: 150px;
            z-index: 2;
            opacity: 0;
            transform: scale(.1);
            transition: 500ms ease-in all;
            transition-delay: 2.5s;
        }
        &.anm {
            * {
                opacity: 1;
                transform: scale(1);
            }
            .balle {
                left: 220px;
            }
            .pg {
                left: 0px;
            }
            .p2 {
                left: 0px;
            }
            .p4 {
                position: absolute;
                right: 0px;
                top: 240px;
            }
            .p5 {
                position: absolute;
                right: 55px;
                top: 50px;
            }
        }
    }
    &.mig-3 {
        .balle {
            height: 150px;
            width: 150px;
            display: flex;
            position: absolute;
            z-index: 0;
            border-radius: 50%;
            background: radial-gradient(45.18% 45.18% at 37.65% 25.18%, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */, linear-gradient(132.82deg, rgba(255, 255, 255, 0.47) 14.64%, rgba(255, 255, 255, 0) 85.8%), #3959F1;
            box-shadow: 50px 100px 100px rgba(125, 151, 244, 0.40);
            filter: blur(3px);
            left: 80px;
            top: 205px;
            opacity: 0.5;
            transform: scale(.7);
            transition: 1s ease-in all;
            &.anm {
                opacity: 1;
                transform: scale(1);
            }
        }
        .balle-sm {
            height: 64px;
            width: 64px;
            border-radius: 50%;
            position: absolute;
            z-index: 10;
            background: radial-gradient(45.18% 45.18% at 37.65% 25.18%, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */, linear-gradient(132.82deg, rgba(255, 255, 255, 0.47) 14.64%, rgba(255, 255, 255, 0) 85.8%), #3959F1;
            box-shadow: 50px 100px 100px rgba(125, 151, 244, 0.26);
            filter: blur(0px);
            top: 80px;
            right: 144px;
            // opacity: .5;
            transform: scale(.2);
            transition: 1s ease-in all;
            // transition-delay: 1.75s;
            &.anm {
                top: 139px;
                right: 315px;
            }
            &.anm-2 {
                top: 15px !important;
                right: 35px  !important;
                // opacity: 1;
                filter: blur(4px);
                transform: scale(1);
            }
        }

        .ppp {
            background-color: #e9effd;
            border-radius: 10px;
            width: 280px;
            height: 318px;
            display: flex;
            flex-direction: column;
            z-index: 1;
            opacity: 0;
            transform: translateX(-20px);
            transition: 1s ease-in all;

            &.anm {
                opacity: 1;
                transform: translateX(70px);
            }

            .cimi {
                width: 90%;
                height: 35px;
                background-color: #fff;
                border-radius: 1.5em;
                bottom: 1em;
                left: 5%;
                position: absolute;
            }


            .tiblock {
                height: 20px;
            }
            .chiwi {
                z-index: 1;
                display: flex;
                flex-direction: column;
                padding-left: 0px;
                position: absolute;
                left: 0px;
                width: 96%;
                top: 10px;
                list-style: none !important;
                transition: 2.5s ease-in all;

                .chiw {
                    list-style: none !important;
                    position: relative;
                    flex-direction: column;
                    padding-left: 48px;
                    display: flex;
                    // display: none;
                    margin-top: 4px;
                    margin-bottom: 4px;
                    margin-left: 0px;
                    // opacity: 0;
                    transition: 2500ms ease-in all;

                    &.alt {
                        margin-top: 0px;
                        margin-bottom: 0px;
                        justify-content: flex-end;
                        align-items: flex-end;
                    }

                    .chim {
                        position: absolute;
                        left: 0px;
                        height: 50px;
                        width: 50px;
                        top: -5px;
                    }

                    .cmsg {
                        display: flex;
                        margin: 2px;
                        font-size: 12px;
                        width: fit-content;
                        background: #fff;
                        font-weight: 500;
                        padding: 7px 10px;
                        border-radius: 8px;
                        border-top-left-radius: 0px;
                        justify-content: center;
                        align-items: center;
                        color: #000 !important;
                        // box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.16);

                        &.alt {
                            background-color: #7d97f4;
                            color: #fff !important;
                            border-top-left-radius: 8px;
                            border-top-right-radius: 0px;
                            text-align: right;
                            float: right;
                        }

                        img {
                            width: 16px;
                            height: 16px;
                            margin: 2px;
                            &.luv {
                                margin-left: 7px;
                                position: relative;
                                height: 14px;
                                width: 14px;
                            }
                            &.love {
                                margin-left: 1px;
                                margin-right: 0px;
                                height: 12px;
                                width: 12px;
                            }
                            &.smile {
                                margin: 0px;
                            }
                        }
                    }
                }
            }
        }

        .cigl {
            display: flex;
            position: absolute;
            z-index: 5;
            left: 165px;
            height: fit-content;
            width: fit-content;
            flex-direction: column;
            transition: 1s ease-in all;

            &.anm {
                left: 0px;
            }

            .cil {
                margin: 6px 0px;
                display: flex;
                width: 220px;
                justify-content: center;
                align-items: flex-start;
                position: relative;
                border-radius: 10px;
                padding: 12px 10px;
                overflow: hidden;
                padding-left: 42px;
                background-color: #fff;
                box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.16);
                flex-direction: column;
                transition: 250ms ease-in all;

                .cav {
                    position: absolute;
                    left: 0px;
                    top: 1px;
                    height: 50px;
                    width: 50px;
                    &.e {
                        height: 27px;
                        width: 27px;
                        left: 11px;
                        top: 10px;
                    }
                }

                .cas-1 {
                    width: 65px;
                    height: 9px;
                    margin: 2px 5px;
                    border-radius: 2px;
                    background-color: #c2c9e4;
                }

                .cas-2 {
                    width: 108px;
                    height: 5px;
                    margin: 2px 5px;
                    background-color: #c2c9e4;
                }

                &.hver {
                    background-color: #7d97f4;
                    transform: translateX(15px) scale(.97);
                    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);

                    .cas-1, .cas-2 {
                        background-color: #f9f9f9;
                    }
                    &.anm {
                        transform: translateX(15px) scale(1);
                    }
                }
            }
        }
    }
    &.mig-4 {
        .balle {
            height: 260px;
            width: 260px;
            display: flex;
            position: absolute;
            z-index: 0;
            border-radius: 50%;
            background: radial-gradient(45.18% 45.18% at 37.65% 25.18%, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */, linear-gradient(132.82deg, rgba(255, 255, 255, 0.47) 14.64%, rgba(255, 255, 255, 0) 85.8%), #3959F1;
            box-shadow: 50px 100px 100px rgba(125, 151, 244, 0.26);
            filter: blur(3px);
            left: 150px;
            opacity: 0.5;
            transform: scale(.8);
            transition: 1s ease-in all;
        }
        .balle-sm {
            height: 64px;
            width: 64px;
            border-radius: 50%;
            position: absolute;
            top: 95px;
            background: radial-gradient(45.18% 45.18% at 37.65% 25.18%, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */, linear-gradient(132.82deg, rgba(255, 255, 255, 0.47) 14.64%, rgba(255, 255, 255, 0) 85.8%), #3959F1;
            box-shadow: 50px 100px 100px rgba(125, 151, 244, 0.26);
            filter: blur(4px);
            right: 100px;
            opacity: 0;
            transition: 1s ease-in all;
            transition-delay: 1.75s;
        }
        .g {
            position: absolute;
            top: 0px;
            right: 0px;
            z-index: 5;
            right: 40px;
            top: 150px;
            opacity: 0;
            transition: 1s ease-in all;
            transition-delay: 500ms;
        }
        .pg {
            z-index: 1;
            opacity: 0.7;
            transition: 250ms ease-in all;
        }
        .b1 {
            position: absolute;
            z-index: 10;
            left: 25px;
            top: 55px;
            opacity: 0;
            transform: scale(.1);
            transition: 1s ease-in all;
            transition-delay: 1s;
        }
        .b2 {
            position: absolute;
            z-index: 10;
            left: 40px;
            top: 230px;
            opacity: 0;
            transform: scale(.1);
            transition: 1s ease-in all;
            transition-delay: 1.5s;
        }
        .b3 {
            position: absolute;
            z-index: 10;
            right: 15px;
            top: 200px;
            opacity: 0;
            transform: scale(.1);
            transition: 1s ease-in all;
            transition-delay: 2s;
        }
        .figd {
            display: flex;
            position: absolute;
            height: 240px;
            width: 60px;
            top: 95px;
            flex-direction: column;
            z-index: 4;
            left: 320px;
            opacity: 0.7;
            align-items: center;
            transition: 250ms ease-in all;
            justify-content: space-around;

            &>span {
                font-size: 14px;
                font-weight: 500;
                color: #7D97F4 !important;
                * {
                    color: #7D97F4 !important;
                }
            }
        }
        &.anm {
            * {
                opacity: 1;
            }
            .balle {
                left: 90px;
                transform: scale(1);
            }
            .balle-sm {
                right: 30px;
            }
            .g {
                top: 50px;
            }
            .b1, .b2, .b3 {
                transform: scale(1);
            }
        }
    }
}






.bg_heart {
    position: relative;
    top: 0;
    left: -20px;
    width: 100%;
    height: 100%;
    z-index: 1000;
    display: flex;
    &.test {
        background-color: #00000030;
    }
    // overflow: hidden
}

.lheart {
    position: absolute;
    top: -50%;
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -m-transform: rotate(-45deg);
    transform: rotate(-45deg)
}

.lheart:before {
    position: absolute;
    top: -50%;
    left: 0;
    display: block;
    content: "";
    width: 100%;
    height: 100%;
    background: inherit;
    border-radius: 100%;
}

.lheart:after {
    position: absolute;
    top: 0;
    right: -50%;
    display: block;
    content: "";
    width: 100%;
    height: 100%;
    background: inherit;
    border-radius: 100%;
}

@keyframes love {
  from {top:320px; opacity: 1; left: 120px;}
  25% {opacity: 1;}
  to {top:-5%; opacity: 0;}
}