.floating {  
    animation-name: floating;
    animation-duration: 3s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
    margin-left: 30px;
    margin-top: 5px;
}

@keyframes floating {
    from { transform: translate(0,  0px); }
    65%  { transform: translate(0, 15px); }
    to   { transform: translate(0, -0px); }    
}

.sfloating {  
    animation-name: sfloating;
    animation-duration: 4s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
    margin-left: 30px;
    margin-top: 5px;
}

.tfloating {  
    animation-name: sfloating;
    animation-duration: 6s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
}

@keyframes sfloating {
    from { transform: translate(0,  0px); }
    65%  { transform: translate(0, 10px); }
    to   { transform: translate(0, -0px); }    
}

.air {  
    animation-name: airing;
    animation-duration: 3s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
}

@keyframes airing {
    from { transform: translate(0, -2px); }
    65%  { transform: translate(0, 2px); }
    to   { transform: translate(0, -2px); }    
}


.float {
	transform: translatey(0px);
	animation: float 4s ease-in-out infinite;
    &.alt {
        animation-delay: 1s;
    }
}

@keyframes float {
	0% {
		transform: translatey(0px);
	}
	50% {
		transform: translatey(-20px);
	}
	100% {
		transform: translatey(0px);
	}
}


.sfloat {
	transform: translatey(0px);
	animation: sfloat 4s ease-in-out infinite;
    &.alt {
        animation-delay: 1s;
    }
}

@keyframes sfloat {
	0% {
		transform: translatey(0px);
	}
	50% {
		transform: translatey(-10px);
	}
	100% {
		transform: translatey(0px);
	}
}

.spin {
    animation-name: spin;
    animation-duration: 8s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}

.spin_alt {
    animation-name: spin_alt;
    animation-duration: 8s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    transform:rotate(360deg);
}

@keyframes spin {
    from {
        transform:rotate(0deg);
    }
    to {
        transform:rotate(360deg);
    }
}

@keyframes spin_alt {
    from {
        transform:rotate(360deg);
    }
    to {
        transform:rotate(0deg);
    }
}

@keyframes scaler{
    0%{
        -webkit-transform: scale(1);
        opacity: 1;
    }

    33%{
        -webkit-transform: scale(1.3);
    }

    67%{
        -webkit-transform: scale(1.5);
    }

    100%{
        -webkit-transform: scale(1.7);
        opacity: 0;
    }
}

.appear {
    animation-name: appear;
    animation-duration: 5s;
    animation-timing-function: linear;
}

@keyframes appear{
    0%{
        opacity: 0;
    }
    25%{
        opacity: 1;
    }
    50%{
        opacity: 0;
    }
    75%{
        opacity: 1;
    }
    100%{
        opacity: 0;
    }
}




.flicker_2 {
	transform-origin: center top;
	animation-name: flicker_2;
	animation-duration: 120ms;
	animation-delay: 100ms;
	animation-timing-function: ease-in;
	animation-iteration-count: infinite;
	animation-direction: alternate;
}

@keyframes flicker_2 {
    0% {
        // transform: rotate(-0.4deg);
        right: 40px;
        top: 36%;
    }
    50% {
        // transform: rotate(0deg);
        // right: 39px;
        top: 35.7%;
    }
    100% {
        // transform: rotate(0.4deg);
        right: 40px;
        top: 36%;
    }
}

.flame-container {
	margin: 0 auto;
	width: 60px;
	height: 60px;
	top: 13%;
	transform-origin: center top;

	animation-name: flicker;
    -webkit-animation: wflicker;

    -webkit-animation-duration: 3ms;
	animation-duration: 3ms;

    -webkit-animation-delay: 200ms;
	animation-delay: 200ms;

    -webkit-animation-timing-function: ease-in;
	animation-timing-function: ease-in;
    
    -webkit-animation-iteration-count: infinite;
	animation-iteration-count: infinite;

    -webkit-animation-direction: alternate;
	animation-direction: alternate;

    .flame {
        bottom: 0;
        position: absolute;
        border-bottom-right-radius: 50%;
        border-bottom-left-radius: 50%;
        border-top-left-radius: 50%;
        transform: rotate(135deg) scale(1.5, 1.5);
        // -moz-transform: rotate(135deg) scale(1.5, 1.5);
        // -webkit-transform: rotate(135deg) scale(1.5, 1.5);
        // -o-transform: rotate(135deg) scale(1.5, 1.5);
    }
    .yellow {
        left: 15px;
        width: 30px;
        height: 30px;
        background: gold;
        box-shadow: 0px 0px 9px 4px gold;
    }
    .orange {
        left: 10px;
        width: 40px;
        height: 40px;
        background: orange;
        box-shadow: 0px 0px 9px 4px orange;
    }
    .red {
        left: 5px;
        top: 20px;
        width: 50px;
        height: 50px;
        background: OrangeRed;
        border: 1px solid OrangeRed;
        box-shadow: 0px 0px 5px 4px OrangeRed;
    }
    .white {
        left: 20px;
        top: 20px;
        width: 20px;
        height: 20px;
        background: white;
        box-shadow: 0px 0px 9px 4px white;
    }
    .circle {
        border-radius: 50%;
        position: absolute;
    }
    .blue {
        width: 10px;
        height: 10px;
        left: 25px;
        top: 5px;
        background: SlateBlue;
        box-shadow: 0px 0px 15px 10px #4fc1e9;
    }
    .black {
        width: 40px;
        height: 40px;
        left: 10px;
        top: -30px;
        background: #ddd;
        border: 1px solid #282A3A;
        box-shadow: 0px 0px 10px 10px #282A3A;
    }
}

@keyframes flicker {
    0% {
        transform: rotate(-1deg);
        // -moz-transform: rotate(-1deg);
        // -webkit-transform: rotate(-1deg);
    }
    20% {
        transform: rotate(1deg);
        // -moz-transform: rotate(1deg);
        // -webkit-transform: rotate(1deg);
    }
    40% {
        transform: rotate(-1deg);
        // -moz-transform: rotate(-1deg);
        // -webkit-transform: rotate(-1deg);
    }
    60% {
        transform: rotate(1deg) scaleY(1.04);
        // -moz-transform: rotate(1deg) scaleY(1.04);
        // -webkit-transform: rotate(1deg) scaleY(1.04);
    }
    80% {
        transform: rotate(-2deg) scaleY(0.92);
        // -moz-transform: rotate(-2deg) scaleY(0.92);
        // -webkit-transform: rotate(-2deg) scaleY(0.92);
    }
    100% {
        transform: rotate(1deg);
        // -webkit-transform: rotate(1deg);
        // -moz-transform: rotate(1deg);
    }
}

@keyframes wflicker {
    0% {
        -webkit-transform: rotate(-1deg);
    }
    20% {
        -webkit-transform: rotate(1deg);
    }
    40% {
        -webkit-transform: rotate(-1deg);
    }
    60% {
        -webkit-transform: rotate(1deg) scaleY(1.2);
    }
    80% {
        -webkit-transform: rotate(-2deg) scaleY(0.9);
    }
    100% {
        -webkit-transform: rotate(1deg);
    }
}

._blink {
  animation: blink 2s linear infinite;
}

.half_blink {
    animation: half_blink 1.5s linear infinite;
}

@keyframes blink {
    0%{opacity: 1;}
    50%{opacity: 0;}
    100%{opacity: 1;}
}

@keyframes half_blink {
    0%{opacity: 1;}
    50%{opacity: 0.5;}
    100%{opacity: 1;}
}

.wiggle {
	animation-name: wiggle;
	animation-duration: 1.5s;
	animation-delay: 80ms;
	animation-timing-function: ease-in;
	animation-iteration-count: infinite;
}

@keyframes wiggle {
    0% {transform: rotateY(10deg)   rotateX(5deg);}
    25% {transform: rotateY(-10deg) rotateX(-5deg);}
    50% {transform: rotateY(7deg)  rotateX(-20deg);}
    75% {transform: rotateY(-5deg)  rotateX(-3deg);}
    100% {transform: rotateY(0deg)  rotateX(0deg);}
  }



















  @-moz-keyframes bounceDown {
    0%, 20%, 50%, 80%, 100% {
      -moz-transform: translateY(0);
      transform: translateY(0);
    }
    40% {
      -moz-transform: translateY(-30px);
      transform: translateY(-30px);
    }
    60% {
      -moz-transform: translateY(-15px);
      transform: translateY(-15px);
    }
  }
  @-webkit-keyframes bounceDown {
    0%, 20%, 50%, 80%, 100% {
      -webkit-transform: translateY(0);
      transform: translateY(0);
    }
    40% {
      -webkit-transform: translateY(-30px);
      transform: translateY(-30px);
    }
    60% {
      -webkit-transform: translateY(-15px);
      transform: translateY(-15px);
    }
  }
  @keyframes bounceDown {
    0%, 20%, 50%, 80%, 100% {
      -moz-transform: translateY(0);
      -ms-transform: translateY(0);
      -webkit-transform: translateY(0);
      transform: translateY(0);
    }
    40% {
      -moz-transform: translateY(-30px);
      -ms-transform: translateY(-30px);
      -webkit-transform: translateY(-30px);
      transform: translateY(-30px);
    }
    60% {
      -moz-transform: translateY(-15px);
      -ms-transform: translateY(-15px);
      -webkit-transform: translateY(-15px);
      transform: translateY(-15px);
    }
  }
  
  
  
  
  
  @-webkit-keyframes bounceLeft {
    0%,
    20%,
    50%,
    80%,
    100% {
      -webkit-transform: translateX(0);
      transform: translateX(0);
    }
    40% {
      -webkit-transform: translateX(30px);
      transform: translateX(30px);
    }
    60% {
      -webkit-transform: translateX(15px);
      transform: translateX(15px);
    }
  }
  @-moz-keyframes bounceLeft {
    0%,
    20%,
    50%,
    80%,
    100% {
      transform: translateX(0);
    }
    40% {
      transform: translateX(30px);
    }
    60% {
      transform: translateX(15px);
    }
  }
  @keyframes bounceLeft {
    0%,
    20%,
    50%,
    80%,
    100% {
      -ms-transform: translateX(0);
      transform: translateX(0);
    }
    40% {
      -ms-transform: translateX(30px);
      transform: translateX(30px);
    }
    60% {
      -ms-transform: translateX(15px);
      transform: translateX(15px);
    }
  }
  /* /left bounce */
  
  
  /* right bounce */
  @-webkit-keyframes bounceRight {
    0%,
    20%,
    50%,
    80%,
    100% {
      -webkit-transform: translateX(0);
      transform: translateX(0);
    }
    40% {
      -webkit-transform: translateX(-30px);
      transform: translateX(-30px);
    }
    60% {
      -webkit-transform: translateX(-15px);
      transform: translateX(-15px);
    }
  }
  @-moz-keyframes bounceRight {
    0%,
    20%,
    50%,
    80%,
    100% {
      transform: translateX(0);
    }
    40% {
      transform: translateX(-30px);
    }
    60% {
      transform: translateX(-15px);
    }
  }
  @keyframes bounceRight {
    0%,
    20%,
    50%,
    80%,
    100% {
      -ms-transform: translateX(0);
      transform: translateX(0);
    }
    40% {
      -ms-transform: translateX(-30px);
      transform: translateX(-30px);
    }
    60% {
      -ms-transform: translateX(-15px);
      transform: translateX(-15px);
    }
  }
  /* /right bounce */
  
  /* assign bounce */
//   .fa-arrow-right, ._carvr {
//     -webkit-animation: bounceRight 2s infinite;
//     animation: bounceRight 2s infinite;
//     float:right;
//   }
  
//   .fa-arrow-left, ._carvl {
//     -webkit-animation: bounceLeft 2s infinite;
//     animation: bounceLeft 2s infinite;
//   }
  
  // .fa-chevron-down {
  //   -moz-animation: bounceDown 2s infinite;
  //   -webkit-animation: bounceDown 2s infinite;
  //   animation: bounceDown 2s infinite;
  //   text-align:center;
  //   display:block;
  // }















.animation_main_div{
  position:absolute;
  margin:0px auto;
  display:flex;
  width: 100px;
  height: 100px;
  z-index: 100000;
  // background: red;
  justify-content: center;
  align-items: center;
  img {
    position: relative !important;
    // opacity: 0;
  }
  div:not(.logo-div-send) {
    position: absolute;
    border-radius: 50%;
    // background: linear-gradient(241.34deg, rgba(125, 151, 244, 0.3) 6.02%, rgba(125, 151, 244, 0) 92.26%);
    background: linear-gradient(241.34deg, rgba(125, 151, 244, 0.5) 6.02%, rgba(125, 151, 244, 0.2) 92.26%);
    animation: scaler 4s infinite;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
  }
  .logo-div-send {
    background: transparent !important;
  }
}


.circle {
  width: 55px;
  height: 55px;
}

.circle2 {
    width: 55px;
    height: 55px;
    animation-delay: 1s !important;
}

.circle3 {
    width: 55px;
    height: 55px;
    animation-delay: 2s !important;
}

.circle4 {
  width: 55px;
  height: 55px;
  animation-delay: 3s !important;
}

@keyframes scaler2 {
  0%{
      -webkit-transform: scale(1);
      opacity: 1;
  }

  33%{
      -webkit-transform: scale(1.3);
      opacity: .7;
  }

  67%{
      -webkit-transform: scale(1.5);
      opacity: .3;
  }

  100%{
      -webkit-transform: scale(1.7);
      opacity: 0;
  }
}





@-webkit-keyframes rotating /* Safari and Chrome */ {
  from {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.rotating {
  -webkit-animation: rotating 2s linear infinite;
  -moz-animation: rotating 2s linear infinite;
  -ms-animation: rotating 2s linear infinite;
  -o-animation: rotating 2s linear infinite;
  animation: rotating 2s linear infinite;
}

.rotatin {
  -webkit-animation: rotating 15s linear infinite;
  -moz-animation: rotating 15s linear infinite;
  -ms-animation: rotating 15s linear infinite;
  -o-animation: rotating 15s linear infinite;
  animation: rotating 15s linear infinite;
}

@-webkit-keyframes antirotating /* Safari and Chrome */ {
  from {
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
  to {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
}
@keyframes antirotating {
  from {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
  to {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
}
.anti-rotatin {
  -webkit-animation: antirotating 15s linear infinite;
  -moz-animation: antirotating 15s linear infinite;
  -ms-animation: antirotating 15s linear infinite;
  -o-animation: antirotating 15s linear infinite;
  animation: antirotating 15s linear infinite;
}


@keyframes upflip {
  from {
    transform: rotateX(0deg);
  }
  to {
    transform: rotateX(-90deg);
  }
}

@keyframes downflip {
  0% { 
    transform: rotateX(90deg);
  }
  100% { 
    transform: rotateX(0deg);
  }
}


@keyframes cn_1 {
  from { left: 50%; top: -5em; }
  to { left: -5em; top: 70%; }
}

@keyframes cn_2 {
  from { top: -5em; left: 40%; }
  to { top: calc(100% + 5em); left: 80%; }
}

@keyframes cn_3 {
  from { top: -5em; left: -5em }
  to { top: calc(100% + 5em); left: 35%; }
}

@keyframes cn_4 {
  from { top: calc(100% + 5em); left: 30%; }
  to { top: -5em; left: 85%; }
}

@keyframes cn_5 {
  from { top: 40%; left: calc(100% + 1em); }
  to { top: calc(100% + 5em); left: 40%; }
}

@keyframes cn_6 {
  from { top: 15%; left: calc(100% + 1em); }
  to { top: calc(100% + 5em); left: -5em; }
}

@keyframes marquee {
  0% { left: 0; }
  100% { left: calc(440px * -4); }
}

@keyframes marquee_c {
  0% { left: 0; }
  100% { left: calc(180px * -11); }
}

@keyframes bounceLeft {
  10% {
    -ms-transform: translateX(0);
    transform: translateX(0);
  }
  40% {
    -ms-transform: translateX(3px);
    transform: translateX(3px);
  }
  70% {
    -ms-transform: translateX(-5px);
    transform: translateX(-5px);
  }
}

@keyframes bounceRight {
  10% {
    -ms-transform: translateX(0);
    transform: translateX(0);
  }
  40% {
    -ms-transform: translateX(-3px);
    transform: translateX(-3px);
  }
  70% {
    -ms-transform: translateX(5px);
    transform: translateX(5px);
  }
}