body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  font-family: Poppins;
}
  
::-webkit-scrollbar:not(.pm-box-2) {
  width: 0px;
  background: #141517;
  border-radius: 1em;
}

::-webkit-scrollbar-track:not(.pm-box-2) {
  background: #141517 !important;
  border-radius: 1em;
}

::-webkit-scrollbar-thumb:not(.pm-box-2) {
  cursor: pointer;
  background: #555 !important;
  border-radius: 1em;
}

:root {
  --color: #000;
  --background: #fff;
  --alt-bg: #dff0f7;
}

[data-theme='dark'] {
  --color: #fff;
  --background: #1D2230;
  --alt-bg: #2c3d50;
}

html {
  z-index: -5;
}

body {
  z-index: -4;
}

#root {
  background: var(--background) !important;
  color: var(--color);
  z-index: -3;
}