@import url('https://fonts.googleapis.com/css?family=Muli');
body {
  font-family: Arial;
  font-family: 'Muli', sans-serif;
}
.nav-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 150px;
  text-align: center;
}
.lang-menu {
  list-style: none;
  padding-left: 0px;
}
.sl-nav {
  display: inline;
  height: 30px;
  padding-top: 0px !important;
  position: relative;
  cursor: pointer;
}
.sl-nav ul.lang-menu {
  display: none;
}
.nav-wrapper:hover .sl-nav ul.lang-menu {
  position: absolute;
  top: 40px;
  left: 0px;
  display: block;
  background: #fff;
  width: 130px;
  padding-top: 0px;
  z-index: 1;
  border-radius:5px;
  box-shadow: 0px 0px 20px rgba(0,0,0,0.2);
}
.sl-nav:hover .triangle {
  position: absolute;
  top: 15px;
  right: -10px;
  z-index:10;
  height: 14px;
  overflow:hidden;
  width: 30px;
  background: transparent;
  display: none;
}
.sl-nav:hover .triangle:after {
  content: '';
  display: block;
  z-index: 20;
  width: 15px;
  transform: rotate(45deg) translateY(0px) translatex(10px);
  height: 15px;
  background: #fff;
  border-radius:2px 0px 0px 0px;
  box-shadow: 0px 0px 20px rgba(0,0,0,0.2);
}
.sl-nav ul.lang-menu li {
  position: relative;
  text-align: left;
  background: transparent;
  padding: 7px 15px;
  padding-bottom:0;
  z-index: 2;
  font-size: 15px;
  color: #3c3c3c;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.sl-nav ul.lang-menu li:last-of-type {
  padding-bottom: 15px;
}
.sl-nav ul.lang-menu li span {
  padding-left: 5px;
}
.sl-nav ul.lang-menu li span:hover, .sl-nav ul.lang-menu li span.active {
  color: #146c78;
}
.sl-flag {
  display: inline-block;
  width: 25px;
  height: 25px;
  position: relative;
  // top: 7px;
  overflow: hidden;
}
.lang-menu .sl-flag {
  top: 0px;
}
.sl-flag img {
  position: relative;
  left: -3px;
  width: 25px;
  height: 25px;
}