.conversation {
    display: flex;
    flex-direction: column;
    list-style: none;
    width: 100%;
    position: relative;
    z-index: 10;
    margin: 0px !important;
    margin-top: 10px !important;
    padding-left: 0px !important;
    margin-left: -1em !important;
    /* position: absolute; */
}

.callout {
    display: none;
    padding: 5px 1em;
    /* padding: 5px 10px; */
    font-size: 16px;
    flex-direction: column;
    border-radius: 1em;
    margin-bottom: 8px;
    width: auto;
    max-width: 285px;
    box-shadow: 1px 2px 3px 1px #ddd;
    /* box-shadow: 1px 2px 3px 2px #f3f3f3; */
    background-color: #fff;
}
.callout:not(.dots) {
  /* min-width: 220px; */
}
/* 
.nightmode .callout {
    box-shadow: 1px 2px 3px #767474 !important;
} */
.nightmode .callout {
  box-shadow: 4px 4px 5px #525050 !important;
}

.conversation li {
    display: flex;
    flex-direction: column;
    position: relative;
    padding-right: 10px;
}

#myAudio {
    display: none;
}

.user-dp {
    left: -100px;
    bottom: -58px;
    position: absolute;
    transition: 500ms ease-in all;
}

.user-role {
    font-size: 10px;
    font-weight: 400;
    line-height: 15px;
}

.user-message {
    color: #11151B;
    font-size: 16px;
    font-size: 18px;
    margin-top: 5px;
    /* letter-spacing: -0.41px; */
    margin-bottom: 5px;
    font-weight: 400;
    /* font-weight: 500; */
}

.conversation li.u1 {
    align-items: flex-start;
    margin-left: 10%;
}

.conversation li.u2 {
    align-items: flex-start;
    margin-left: 30%;
}

.conversation li.u3 {
    margin-top: 5px;
    /* margin-top: 18%; */
    align-items: flex-start;
    margin-left: 45%;
}

.conversation li.u4 {
    align-items: flex-start;
    margin-left: 60%;
}

.message {
    display: none !important;
}

.message.active, .callout.active, .callout.typing {
    display: flex !important;
}

.visible {
    visibility: visible;
    opacity: 1;
    transition: opacity 200ms linear;
}
.hidden {
    visibility: hidden;
    opacity: 0;
    transition: visibility 0ms 200ms, opacity 200ms linear;
}






.tiblock {
    align-items: center;
    display: flex;
    height: 30px;
}

.ticontainer .tidot {
    background-color: #90949c;
}

.tidot {
    -webkit-animation: mercuryTypingAnimation 1.5s infinite ease-in-out;
    display: inline-block;
    margin-right: 5px;
    border-radius: 10px;
    height: 10px;
    width: 10px;
}

@-webkit-keyframes mercuryTypingAnimation{
    0%{ -webkit-transform:translateY(0px); background: rgba(144, 148, 156, .8); }
    28%{ -webkit-transform:translateY(-5px); background: rgba(144, 148, 156, .4); }
    44%{ -webkit-transform:translateY(0px); background: rgba(144, 148, 156, .2); }
}

@keyframes mercuryTypingAnimation {
    0%{ transform:translateY(0px); background: rgba(144, 148, 156, .7); }
    28%{ transform:translateY(-5px); background: rgba(144, 148, 156, .4); }
    44%{ transform:translateY(0px); background: rgba(144, 148, 156, .2); }
}

.tidot:nth-child(1) {
    -webkit-animation-delay: 200ms;
    animation-delay: 200ms;
}
.tidot:nth-child(2) {
    -webkit-animation-delay: 300ms;
    animation-delay: 300ms;
}
.tidot:nth-child(3) {
    -webkit-animation-delay: 400ms;
    animation-delay: 400ms;
}










.available-days {
  display: flex;
  flex-direction: column;
  width: -moz-fit-content;
  width: fit-content;
  position: absolute;
  right: 0%;
  bottom: -50px;
  bottom: -20%;
  height: 373px;
  /* transform: scale(0.5); */
}

.calendar-div, .time-div {
    width: 240px;
    padding: 10px 2em;
    padding: 1em 2em;
    display: flex;
    height: fit-content;
    min-height: -webkit-fit-content;
    position: relative;
    border-radius: 2em;
    cursor: pointer;
    flex-direction: column;
    background-color: #fff;
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.1);
}
.time-div {
    transition: 250ms ease-in all;
    opacity: 0;
    width: 207px;
    transform: translate(-65px,-90px);
}
.calendar-div b, .time-div b,
.nightmode .calendar-div b, .nightmode .time-div b {
    color: #000000 !important;
    font-weight: 600;
    font-size: 18px;
    margin-bottom: 10px;
}
.time-div b {
    width: 100%;
    margin-bottom: 0px;
    text-align: center;
}
img.ars {
    position: absolute;
    top: 3.5em;
    right: 2em;
}
.space-tab-md {
    display: block;
    width: 68px;
    height: 12px;
    background: #DCE7FD;
    border-radius: 7px;
    margin-bottom: 10px;
}
.space-tabs {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 7px;
    margin-bottom: 10px;
}
.space-tab-sm {
    display: inline-flex;
    height: 6px;
    width: 19px;
    margin-right: 8px;
    background: #DCE7FD;
    border-radius: 7px;
}
.dates, .times {
    display: block;
    width: 100%;
    padding-top: 10px;
}
.times {
    padding-top: 5px;
    padding-bottom: 5px;
    display: flex;
    flex-direction: column;
}
.dates span.date-int,
.nightmode .dates span.date-int {
    display: inline-flex;
    margin: calc(20% - 32px);
    height: 25px;
    width: 25px;
    font-size: 14px;
    cursor: pointer;
    font-weight: 500;
    border: 2px solid #E0E0E0;
    border-radius: 50%;
    color: #fff !important;
    /* transition: 250ms ease-in all; */
    align-items: center;
    justify-content: center;
}
.dates span.date-int.active,
.nightmode .dates span.date-int.active {
    color: #000 !important;
    border: 2px solid #7D97F4 !important;
}
.dates span.date-int:hover,
.nightmode .dates span.date-int:hover,
.date-int.hover,
.nightmode .date-int.hover {
    color: #fff !important;
    background-color: #7D97F4;
    border: 2px solid #7D97F4 !important;
}

.times span.time-int,
.nightmode .times span.time-int {
    display: inline-flex;
    margin: 2px 0;
    height: 30px;
    width: 100%;
    font-size: 14px;
    cursor: pointer;
    font-weight: 500;
    border: 2px solid #E0E0E0;
    border-radius: 5px;
    color: #444;
    /* transition: 250ms ease-in all; */
    align-items: center;
    justify-content: center;
}
.times span.time-int.active,
.nightmode .times span.time-int.active {
    color: #000;
    border: 2px solid #7D97F4 !important;
}
.times span.time-int:hover,
.nightmode .times span.time-int:hover,
.time-int.hover,
.nightmode .time-int.hover {
    color: #fff !important;
    background-color: #7D97F4;
    border: 2px solid #7D97F4 !important;
}





 .scroll-container {
    height: 100vh;
    min-height: 450px;
    padding: 2rem 1rem;
    display: flex;
    align-items: center;
    box-sizing: border-box;
  }
  
  .scroll-container:nth-of-type(1) {
    background-color: #bdd0c4;
  }
  .scroll-container:nth-of-type(2) {
    background-color: #f5d2d3;
  }
  .scroll-container:nth-of-type(3) {
    background-color: #9ab7d3;
  }
  .scroll-container:nth-of-type(4) {
    background-color: #dfccf1;
  }
  
  .scroll-container:nth-of-type(even) {
    flex-direction: row-reverse;
  }
  
  .scroll-element,
  .scroll-caption {
    width: 50%;
  }
  
  .scroll-element {
    min-height: 300px;
    height: 100%;
    background-color: #eaeaea;
  }
  
  .scroll-caption {
    margin: 1rem;
  }
  
  footer {
    text-align: center;
    padding: 0.5rem 0;
    background-color: #faddad;
  }
  
  footer p {
    font-size: 0.75rem;
    margin: 0.25rem 0;
    color: #221133;
  }
  
  footer a {
    text-decoration: none;
    color: inherit;
  }
  
  @media screen and (max-width: 650px) {
    .scroll-container,
    .scroll-container:nth-of-type(even) {
      flex-direction: column;
      align-content: inherit;
    }
  
    .scroll-element {
      height: 100%;
    }
  
    .scroll-element,
    .scroll-caption {
      width: 100%;
    }
  }
  /**Styling scrollable elements*/
  
  /* .js-scroll {
    opacity: 0;
    transition: opacity 500ms;
  } */
  
  .js-scroll.scrolled {
    opacity: 1;
  }
  
  .scrolled.fade-in {
    animation: fade-in 1s ease-in-out both;
  }
  
  .scrolled.fade-in-bottom {
    animation: fade-in-bottom 1s ease-in-out both;
  }
  
  .scrolled.slide-left {
    animation: slide-in-left 1s ease-in-out both;
  }
  
  .scrolled.slide-right {
    animation: slide-in-right 1s ease-in-out both;
  }
  
  /* ----------------------------------------------
   * Generated by Animista on 2021-2-11 23:32:31
   * Licensed under FreeBSD License.
   * See http://animista.net/license for more info. 
   * w: http://animista.net, t: @cssanimista
   * ---------------------------------------------- */
  
  @keyframes slide-in-left {
    0% {
      -webkit-transform: translateX(-100px);
      transform: translateX(-100px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateX(0);
      transform: translateX(0);
      opacity: 1;
    }
  }
  
  @keyframes slide-in-right {
    0% {
      -webkit-transform: translateX(100px);
      transform: translateX(100px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateX(0);
      transform: translateX(0);
      opacity: 1;
    }
  }
  
  @keyframes fade-in-bottom {
    0% {
      -webkit-transform: translateY(50px);
      transform: translateY(50px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateY(0);
      transform: translateY(0);
      opacity: 1;
    }
  }
  
  @keyframes fade-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }