@media (min-width: 1331px) {
    .available-days {
        right: 0px;
        bottom: -80px !important;
        transform: scale(1);
    }
}

@media (min-width: 992px) {
    .testimony {
        h2.f40 {
            position: absolute;
            // top: 40px;
            width: 100%;
            z-index: 20;
        }
    }
    .ei8 {
        padding-right: 10% !important;
    }
    header {
        .flexx.tab.alt {
            max-width: 636px;
            padding-left: 0px;
        }
    }
    .nav-link.sl-nav {
        display: inline-flex;
        flex-direction: row;
        align-items: center;
        .ud {
            position: relative;
        }
    }
    #mainNav .navbar-nav .nav-item .nav-link {
        padding: 0 10px;
    }
    .conversation {
        margin-left: -2% !important;
    }
    // .table.cs {
    //     width: calc(100% - 2em);
    //     max-width: 100%;
    // }

    #clients {
        &.rbg-dark::before {
            background: #e0f5fe url(../img/home/clients_light_lg.webp) no-repeat;
        }
    }
    .home-page {
        #services {
            margin-top: 75px;
        }
        #swom {
            .pbold {
                margin-bottom: 12px;
            }
        }
        header.masthead p.f22 {
            // font-size: 22px;
        }
        #stats {
            .col-lg-12 {
                width: 85% !important;
                margin: 0 auto !important;
            }
            &.rbg-dark::before {
                background: #e0f5fe url(../img/home/stats_light_lg.png) no-repeat;
            }
        }
        #dc {
            &.rbg-dark::after {
                background: url(../img/home/dcv_light_lg.svg) no-repeat;
            }
            &.rbg-dark::before {
                background: #e0f5fe url(../img/home/dc_light_lg.webp) no-repeat;
            }
        }
    }
    [data-theme='dark'] {
        .home-page {
            #stats {
                &.rbg-dark::before {
                    background: #2c3d50 url(../img/home/stats_dark_lg.png) no-repeat;
                }
            }
        }
        #clients {
            &.rbg-dark::before {
                background: #2c3d50 url(../img/home/clients_dark_lg.webp) no-repeat;
            }
        }
        #dc {
            &.rbg-dark::after {
                background: url(../img/home/dcv_dark_lg.svg) no-repeat;
            }
            &.rbg-dark::before {
                background: #2c3d50 url(../img/home/dc_dark_lg.webp) no-repeat;
            }
        }
    }
}

._bresp { display: none !important; }

@media (min-width: 992px) and (max-width: 1205px) {
    .nbresp, ._nbresp { display: none !important; }
    .bresp, ._bresp { display: inline-flex !important; }
    span.bresp { display: inline !important; }

    .home-page {
        #stats {
            .col-lg-12 {
                width: 90% !important;
                margin: 0 auto !important;
            }
        }
    }
    .sales-page {
        #colsec-4 {
            .figger {
                position: relative;
                left: 20px;
            }
        }
    }
}


@media (min-width: 992px) and (max-width: 1300px) {
    .conversation {
        right: 15px;
        top: -30px;
        position: relative;
        transform: scale(.9);
        .clast {
            min-width: 240px !important;
        }
    }
}


@media (min-width: 992px) and (max-width: 1170px) {
    .conversation {
        right: 10px !important;
    }
    .cthird {
        min-width: 230px !important;
    }
    .cfourth {
        min-width: 250px !important;
    }
}

@media (min-width: 1171px) and (max-width: 1240px) {
    .app-menu {
        transform: scale(0.8);
        right: -70px;
        left: unset;
        top: 70px;
    }
}
@media (min-width: 992px) and (max-width: 1240px) {
    .flag-span {
        display: none;
    }
    .navbar-nav {
        .nav-item:last-child {
            padding-left: 10px !important;
        }
    }
    .available-days {
        right: 0%;
        bottom: -25% !important;
        transform: scale(0.7);
    }
}

@media (min-width: 1090px) and (max-width: 1190px) {
    .bub {
        right: 2%;
    }
}

@media (min-width: 992px) and (max-width: 1090px) {
    .bub {
        right: 0px;
    }
}

@media (min-width: 992px) and (max-width: 1330px) {
    .message .callout {
        font-size: 14px;
        line-height: 1.5;
    }
    img.gpla {
        left: -214px;
        top: -185px;
        transform: scale(.9);
    }
    #footer-0 {
        .fxlt {
            min-width: auto !important;
        }
    }
    .home-page .man.pr {
        max-width: 100% !important;
    }
    .nav-item {
        margin-left: 0px;
    }
    .px-lg-5 {
        padding-right: 1rem !important;
        padding-left: 1rem !important;
    }
    // .man.fst {
    //     width: 80% !important;
    //     max-width: 80% !important;
    // }
    #swom {
        .pbold.f18 {
            font-size: 18px;
        }
        .kts {
            padding-left: 25px !important;
        }
        .flexx.tab {
            margin-top: 10px;
        }
        .f36 {
            font-size: 32px;
        }
    }
}
@media (min-width: 992px) and (max-width: 1190px) {
    img.gpla {
        left: -250px;
        top: -190px;
        transform: scale(.8);
    }
}
@media (min-width: 1171px) {
    .sub-menu {
        height: 100px;
        &._mn2 {
            height: 115px;
        }
        // padding-top: 10px;
        // padding-right: 10px;
    }
}
@media (max-width: 1170px) {
    body.menu_ed {
        overflow: hidden !important;
    }
    #navbarResponsive {
        display: none !important;
    }
    .rsec.responsive, .navbar-toggler-right {
        display: inline-flex !important;
    }
    .navbar-toggler-right {
        margin-left: 20px;
    }
    .navbar-toggler {
        border: 0px solid !important;
        outline: none !important;
        box-shadow: none !important;
    }

    #app-overlay-alt.active {
        z-index: 8888;
        display: flex;
        background-color: transparent;
    }
    
    #app-overlay {
        position: fixed;
        top: 0;
        right: 0;
        overflow-x: hidden;
        flex-direction: column;
        width: 365px !important;
        height: 80vh !important;
        bottom: unset !important;
        left: unset !important;
        min-width: 365px !important;
        min-height: 650px !important;
        max-height: 750px !important;
        background: #cae2f6 url(../img/bg/mbgl.svg) no-repeat;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover !important;
        box-shadow: 0px 16px 32px rgba(0, 0, 0, 0.3);
        a {
            color: #000 !important;
        }
        &.active {
            .responsive {
                display: flex !important;

                &.for-all:not(.sw) {
                    display: block !important;
                    padding-left: calc(50% - 80px) !important;
                }
                &.sw {
                    display: block !important;
                    padding-left: calc(50% - 125px) !important;
                }
            }
        }
        .app-menu {
            transform: scale(1) !important;
        }
        footer.footer {
            margin-top: -10px;
            background: transparent !important;
            padding-top: 30px !important;
            padding: 0px !important;
            .container:first-child {
                margin-bottom: 1.5em;
            }
            .px-lg-5 {
                padding-right: 2em !important;
                max-width: 95% !important;
                padding-left: 2em !important;
                .f_24 {
                    font-size: 16px !important;
                }
            }
        }
        .swae {
            .air {
                right: -15px;
                width: 60px !important;
                top: -23px;
                position: absolute;
            }
        }
    }

    [data-theme='dark'] {
        #app-overlay {
            background: #2a4261 url(../img/bg/mbgd.svg) no-repeat;
            a {
                color: #fff !important;
            }
            .app-menu {
                background-color: transparent !important;
            }
        }
    }

    .sub-menu {
        margin: 0;
        display: inline-block;
        cursor: pointer;
        float: left;
        transition: 250ms ease-in all;
        padding-top: .5em;
        padding-bottom: .5em;
        position: relative;
        width: 100% !important;
        &.real {
            min-height: 50px;
            padding-left: 0 !important;
            width: 160px !important;
        }
    }

    .app-menu {
        box-shadow: none !important;
        position: unset !important;
        width: 100% !important;
        top: 0px !important;
        left: 0px !important;
        background: transparent !important;
        right: 0px !important;
        align-items: center;
        min-height: auto !important;
        flex-direction: column !important;
        border-radius: 0px !important;

        &.active {
            display: block !important;
            height: -webkit-fit-content !important;
        }

        .sub-menu.real {
            min-height: 50px !important;
        }

        &.sw {
            margin-top: 1.5em !important;
            .sub-menu.real {
                width: 250px !important;
            }
        }
    }

    .blurry {
        // transition: 250ms ease-in all;
        filter: blur(3px);
    }
    
    .app-menu .sub-menu {
        display: inline-flex !important;
        padding-top: 5px !important;
        padding-bottom: 5px !important;
        height: auto !important;
    }
    img.bbg.responsive {
        display: flex !important;
    }
    .sub-menu.active::before {
        background: #F22FB0 !important;
    }
    .sub-menu span {
        font-size: 12px !important;
    }
    .sub-menu img:not(.ud, .air) {
        position: relative;
        left: 0px;
        top: 2px;
        margin-left: 5px;
        width: 40px !important;
        height: auto !important;
    }
    .sub-menu {
        a.flexx {
            padding-top: 5px;
            padding-bottom: 5px;
            justify-content: flex-start;
            align-items: flex-start;
        }
    }
}
@media (max-width: 1330px) {
    .flexx.tab.alt {
        width: 100%;
        justify-content: space-between;
        align-items: flex-start;
        flex-direction: column;
        justify-content: center;
        .tpil {
            margin-top: 25px;
        }
    }
    .tpil {
        margin-left: 0px !important;
        transform: scale(0.9);
    }
}

@media (min-width: 992px) and (max-width: 1330px) {
    .home-page {
        #swom, .colsec {
            .tpil {
                position: relative;
                left: 15px !important;
                top: 5px !important;
            }
        }
        #swom {
            .tpil {
                top: -15px !important;
            }
        }
    }
}

@media (min-width: 768px) and (max-width: 992px) {
    .home-page {
        #swom {
            .tpil {
                position: relative;
                left: 15px !important;
                top: -15px !important;
            }
        }
        .colsec {
            .tpil {
                position: relative;
                left: 10px !important;
                top: 5px !important;
            }
        }
        #services {
            .tpil {
                position: relative;
                left: 20px !important;
                top: -5px !important;
            }
        }
    }

    .conversation li.u3 {
        margin-top: 58px;
        margin-top: 5% !important;
        margin-top: 0px !important;
    }
    img.gpla {
        left: -252px;
        top: -210px;
        transform: scale(.8);
    }
    .col-lg-6 {
        flex: 0 0 auto;
        width: 100%;
    }
    .f_18, .fb22 { font-size: 18px; }
    .f18, .f_20, .f_24 { font-size: 20px; }
    .fl30 { font-size: 22px; }
    .fl50 { font-size: 40px; }
    .ft20 { font-size: 20px; }
    .f22 {
        font-size: 20px;
        margin-bottom: 0px;
    }
    .f36 {
        font-size: 32px;
    }
    .f24 {
        font-size: 20px;
    }
    .f16 {
        font-size: 18px !important;
        font-weight: 400 !important;
    }
    .f20 { font-size: 20px; }
    
    .is-desktop, .is-mobile  {
        display: none !important;
    }

    .is-tablet, .is-tablet.is-desktop {
        display: inline-flex !important;
        .pbold {
            font-size: 16px !important;
        } 
    }
    .cm-box {
        width: 46%;
    }
    #partner {
        .pml {
            img {
                margin: 0px !important;
            }
        }
    }
    .iline {
        position: absolute;
        height: 310%;
    }

    .flexx.tab {
        align-items: center;
        flex-direction: row;
        padding-left: 5%;
        justify-content: flex-start;

        .flexy {    
            margin-top: 0px;
        }
    }
    .is-bigs {
        justify-content: flex-start;
        img.crac, img.man {
            width: 80%;
        }
    }

    .colsec {
        .container {
            padding-left: 2.5em !important;
            padding-right: 2.5em !important;

            .pbold.f16 {
                font-size: 18px !important;
            }
        }
    }
    #swom .jts {
        width: 768px;
    }
    .its.is-bigs {
        position: absolute;
        right: -1em;
    }
    .m_lst {
        .pbold {
            padding-left: 0px !important;
        }
    }
    .nopd2 {
        &>h1, &>.pbold {
            text-align: left;
        }
        &>.pbold {
            font-size: 18px;
        }
    }

    #dc {
        .fl50 {
            font-size: 40px !important;
        }

        .tpil {
            margin-bottom: 3em;
        }

        img.pl1 {
            height: 50%;
        }
    }

    .fxlt2 {
        p {
            width: 284px;
            margin: 0 auto;
            padding-bottom: 1em;
        }
    }
    .col-md-6 {
        flex: 0 0 auto;
        width: 50%;
    }

    .dmode {
        &.is-desktop, &.is-tablet, &.is-mobile { display: none !important; }
    }
    [data-theme='dark'] .dmode {
        &.is-desktop, &.is-mobile { display: none !important; }
        &.is-tablet { display: inline-flex !important; }
    }
    .lmode {
        &.is-desktop, &.is-mobile { display: none !important; }
        &.is-tablet { display: inline-flex !important; }
    }
    [data-theme='dark'] .lmode {
        &.is-desktop, &.is-tablet, &.is-mobile { display: none !important; }
    }
}

@media (max-width: 510px) {
    #app-overlay {
        min-width: 100vw !important;
        max-width: auto !important;
        width: 100vw !important;
        min-height: 100vh !important;
        max-height: auto !important;
        height: 100vh !important;
    }
}

@media (max-width: 767px) {
    .accordion {
        width: 100%;
        margin: 0px;
        padding: 10px;
    }
    img.i1 { left: 1%; top: 10px; }
    img.i2 { right: 1%; top: 30px; }
    img.i3 { left: 24%; top: 20px; }
    img.i4 { right: 24%; top: 8px; }
    img.i5 { left: 15%; top: 130px; }
    img.i6 { right: 15%; top: 130px; }
    .table.cs {
        box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.16);
    }
    .table.cs tr.is-mobile:not(:last-child)::after {
        display: none;
    }
    .table.cs tr {
        display: flex;
        height: auto;
        padding-left: 0px;
        padding-right: 0px;
        padding-top: 10px;
        padding-bottom: 10px;
        flex-direction: row;
        justify-content: flex-start;
        &.is-mobile {
            width: 100%;
            flex-direction: column;
        }
        th, td {
            padding: 0px !important;
            float: left;
            &>b, &>span:not(.not-responsive) {
                text-align: left;
                justify-content: flex-start;
                display: flex;
                padding: 0px;
                padding-left: 10px;
            }
            &>span:not(.not-responsive) {
                font-weight: 500;
                font-size: 16px;
            }
            &.ft12>span:not(.not-responsive) {
                font-weight: 500;
                font-size: 12px;
            }
            &.flx {
                display: flex;
                width: 100%;
                flex-direction: column;
                justify-content: flex-start;
            }
            &.flx-2 {
                width: 50%;
                display: inline-flex;
                flex-direction: column;
                justify-content: flex-start;
            }
            &.flx-3 {
                width: calc(100% / 3);
                display: inline-flex;
                flex-direction: column;
                justify-content: flex-start;
            }

            &>b {
                // color: #BFBFBF !important;
                color: #706c6c !important;
                font-weight: 400;
                display: inline-flex;
                text-align: left;
                font-size: 11px !important;
                justify-content: flex-start;
                font-size: 11px !important;
            }
            &>span {
                padding-left: 10px;
                text-align: left;
                display: inline-flex;
                justify-content: flex-start;
            }
        }
        .not-mobile {
            display: none !important;
        }
    }
    [data-theme='dark'] .table.cs tr {
        th, td {
            &>b {
                color: #BFBFBF !important;
            }
        }
    }
    .lintl, .lintr {
        display: none;
    }
    .fxlt2 {
        position: relative;
        .ellc {
            height: 100%;
            display: inline-flex;
            justify-content: flex-start;
            .elli {
                position: absolute;
                top: 0px;
            }
            .lintd {
                left: 33px;
                // height: 100%;
                z-index: -1;
                // top: 50px;
                left: 33px;
                top: 66px;
                height: 0px;
                transition: 1.5s ease-in all;
                &.anm {
                    height: calc(100% - 33px);
                }
            }
        }
        .mt-5 {
            display: flex;
            text-align: left;
            padding-left: 125px;
            padding-right: 35px;
            margin-top: 15px !important;
            flex-direction: column;
            justify-content: flex-start;
            min-height: 130px;
            p {
                padding-right: 5px;
            }
        }
        .ellc {
            display: inline-flex;
            width: 80px;
            left: 10px;
            top: 8px;
            top: 35px;
            margin-bottom: 5px;
            flex-direction: row;
            position: absolute;
            justify-content: center;
            align-items: center;
            // transform: scale(0.8);
        }
    }
    
    [data-theme='dark'] .footer .fttt {
        background-color: #191c25 !important;
    }
    .yps {
        text-align: center;
        justify-content: center;
    }
    .arr {
        background: rgba(18, 18, 18, 0.05) !important;
        max-width: 100% !important;
        padding: 10px;
    }
    // .f_18, .fb22 { font-size: 20px; }
    .f18, .f_20, .f_24 { font-size: 14px; }
    .fl30 { font-size: 18px; }
    .fl50 { font-size: 24px; }
    // .ft20 { font-size: 20px; }
    .f22 {
        font-size: 14px;
        margin-bottom: 0px;
    }
    .f36 {
        font-size: 24px;
    }
    .f24 {
        font-size: 18px;
    }
    .accr label {
        cursor: pointer;
        padding: 10px 0;
        font-size: 18px;
    }
    .accr span.acs {
        padding: 10px 0;
        font-weight: 100;
        font-size: 14px;
        line-height: 140%;
    }
    
    .is-desktop, .is-tablet {
        display: none !important;
    }

    .is-mobile {
        display: inline-flex !important;
    }

    .flexx.tab {
        align-items: center;
        flex-direction: column;
        // padding-left: 5%;
        justify-content: flex-start;
    }
    
    .flexx.tab .flexy {
        margin-left: 0px !important;
        padding-left: 0px !important;
    }
    .its.is-bigs {
        display: none !important;
    }
    .hero-sect-2.is-smalls {
        display: block !important;
    }

    #dc {
        .fl50 {
            font-size: 24px !important;
        }

        .tpil {
            margin-bottom: 3em;
        }

        img.pl1 {
            height: 50%;
        }
    }


    .dmode {
        &.is-desktop, &.is-tablet, &.is-mobile { display: none !important; }
    }
    [data-theme='dark'] .dmode {
        &.is-desktop, &.is-tablet { display: none !important; }
        &.is-mobile { display: inline-flex !important; }
    }
    .lmode {
        &.is-desktop, &.is-tablet { display: none !important; }
        &.is-mobile { display: inline-flex !important; }
    }
    [data-theme='dark'] .lmode {
        &.is-desktop, &.is-tablet, &.is-mobile { display: none !important; }
    }

    .table.cs tr:first-child,
    .monitor.lmode, .tup.lmode,
    .monitor.dmode, .tup.dmode {
        display: none !important;
    }
    
    .jts > figure {
        position: relative;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        width: 100%;
        margin-left: 0;
    }

    .man.is-smalls {
        margin-top: 2em;
    }
    #partner {
        .container {
            left: 0px !important;
        }
    }
    .px-lg-5 {
        padding-right: 10px !important;
        max-width: 95% !important;
        padding-left: 10px !important;
    }
}

@media (max-width: 572px) {
    .eye-case.is-smalls {
        display: inline-flex !important;
    }
    .eye-case.not-smalls {
        display: none !important;
    }
}

@media (max-width: 480px) {
    .is-bigs {
        display: none !important;
    }
    .is-smalls {
        display: inline-flex !important;
    }
    .not-smalls {
        display: none !important;
    }
    #contact .container {
        max-width: 95% !important;
    }
    .f36 {
        font-size: 20px;
    }
    .f40 {
        font-size: 24px;
    }
    .px-lg-5 {
        padding-right: 8px !important;
        width: calc(100% - 16px) !important;
        max-width: calc(100% - 16px) !important;
        padding-left: 8px !important;
    }
    .colsec {
        .px-lg-5 {
            padding-right: 15px !important;
            width: 95% !important;
            max-width: 100% !important;
            padding-left: 15px !important;
        }
    }
    .masthead > .container {
        margin-top: 70px !important;
    }
    // .hero-sect-2.is-smalls {
    //     top: 125px;
    // }

    #root {
        #footer-0 {
            strong.f_18 {
                padding-left: 0px !important;
                padding-right: 0px !important;
                br.is-mobile {
                    display: none !important;
                }
            }
        }
    }
}

.slick-dots {
    display: none !important;
}
footer.footer {
    background-color: #F3F3F3 !important;
}
[data-theme='dark'] .footer .fttt,
.footer .fttt,
[data-theme='dark'] footer.footer {
    background-color: transparent !important;
}


@media (min-width: 768px) and (max-width: 910px) {
    img.gpla {
        left: -257px;
        top: -215px;
        transform: scale(.8);
    }
}

@media (min-width: 768px) and (max-width: 882px) {
    img.gpla {
        left: -268px;
        top: -224px;
        transform: scale(.8);
    }
}

@media (min-width: 780px) and (max-width: 882px) {
    .conversation {
        li.u4 {
            margin-left: 40% !important;
            width: 260px !important;
        }
    }
}


@media (min-width: 850px) and (max-width: 992px) {
    .conversation {
        li.u4 {
            // margin-left: 40% !important;
            width: 280px !important;
        }
    }
}


.colsec {
    .pbold.f20 { font-weight: 600 !important; }
}