.home-page {
    .cm-box-2, .cm-box {
        height: fit-content;
        *:not(.sm) {
            color: #000 !important;
        }
        .rdm {
            span {
                color: rgb(125, 151, 244) !important;
            }
        }
    }
    #services {
        .flexx.tab {
            margin-top: 2em !important;
        }
    }
    .mh1 {
        line-height: 1.4;
    }
}

@media (min-width: 993px) {
    .home-page {
        .man.fst {
            max-height: 440px !important;
            width: auto !important;
        }
        .masthead {
            min-height: 768px;
        }
        #stats {
            .text-muted {
                font-size: 18px !important;
            }
        }
        .pricn {
            margin-top: -12px !important;
        }
    }
}

@media (min-width: 993px) and (max-width: 1199px) {
    .home-page {
        .colsec-3 {
            .crac.man {
                transform: scale(.9);
            }
        }
    }
}

@media (min-width: 768px) and (max-width: 992px) {
    .responsive {
        .switch.mob {
            position: relative;
            top: 8px;
        }
        .navbar-toggler-right {
            margin-left: 20px;
            img {
                height: 40px;
            }
        }
    }
    .masthead {
        height: 700px !important;
        .fdf {
            width: 100%;
        }
        p.f22 {
            font-size: 18px !important;
        }
        .swae, .tpil {
            width: 100% !important;
            max-width: 333px;
        }
        .flexx.tab {
            padding-left: 0px;
            justify-content: flex-start;
            align-items: flex-start;
            .tpil {
                margin-left: 10px !important;
                width: auto !important;
                min-width: auto !important;
            }
        }
        img.bt {
            // display: none;
            bottom: 15%;
            // bottom: 60px;
        }
        .hero-sect {
            width: calc(50vw - 1rem + 4px);
        }
        .conversation {
            display: flex;
            flex-direction: column;
            list-style: none;
            width: 106%;
            margin: 0px !important;
            max-width: 200%;
            padding-left: 0px;
            left: px;
            transform: scale(.8);
            top: -45px;
            position: relative;
            li:first-child {
                &.u1, &.u2 {
                    &.message{
                        .callout:not(.dots) {
                            max-width: 190px;
                        }
                    }
                }
            }
            li.u2 {
                margin-left: 20% !important;
                max-width: 225px;
            }
            li.u3 {
                margin-left: 32% !important;
            }
            li.u4 {
                margin-left: 40% !important;
                width: 240px;
            }
        }
        .message .callout:not(.dots) {
            font-size: 12px;
            line-height: 1.4;
            margin-top: 0px;
        }
        img.eli {
            position: absolute;
            top: 103px;
            left: -35px;
            transform: scale(0.9);
        }
        img.elf {
            left: -30px;
            top: 160px;
            width: calc(100% + 30px);
            // transform: scale(0.8);
            height: auto !important;
        }
        img.plo {
            position: absolute;
            top: 190px;
            left: 90px;
        }
        img.rl {
            left: 50px;
            width: 13px;
            top: -2px;
        }
    }
    #clients {
        padding: 3.5rem 0 !important;
        &.rbg-dark::before {
            background: #e0f5fe url(../img/home/clients_light_lg.webp) no-repeat;
        }
        h2.f36 {
            padding-left: 1em;
            padding-right: 1em;
            margin-bottom: 1em;
        }
        .multiple-items {
            margin-bottom: 1em;
        }
        .container {
            padding-left: 0px !important;
            padding-right: 0px !important;
            margin-bottom: 70px;
        }
        .slick-slider.is-desktop,
        .slick-slider.is-mobile,
        .slick-slider.is-smalls {
            display: none !important;
        }
        .slick-slider.is-tablet {
            display: flex !important;
        }
        .slick-slide {
            transform: translateX(-60px);
        }
    }
    #dc {
        &.rbg-dark::after {
            top: calc(100% - 315px);
            width: 383px;
            height: 315px;
            background: url(../img/home/dcv_light_lg.svg) no-repeat;
        }
        &.rbg-dark::before {
            background: #e0f5fe url(../img/home/dc_light_lg.png) no-repeat;
        }
        h2 {
            margin-top: .9em !important;
        }
        .pzz {
            width: 331px !important;
            height: 315px !important;
        }
        .swae {
            width: 331px;
            margin-top: 25px;
            font-size: 20px;
        }
    }
    #footer-0 {
        h2 {
            margin-top: 25px !important;
            margin-bottom: 10px !important;
        }
        .f_18 {
            font-size: 18px !important;
            // text-transform: lowercase;
            // &:first-letter {
            //     text-transform: uppercase;
            // }
        }
    }
    footer.footer {
        margin-top: -10px;
        padding-top: 30px !important;
        .f_24 {
            font-size: 18px !important;
        }
        .container:first-child {
            margin-bottom: 1.5em;
        }
    }
    .home-page {
        #swom {
            .kts {
                h1 {
                    width: 100%;
                }
            }
            .jts {
                margin-bottom: 0em !important;
            }
            .available-days {
                right: 10% !important;
                bottom: -12% !important;
                transform: scale(0.9) !important;
            }
            .fspc {
                left: 40px;
            }
            .jts > figure {
                // left: -35px;
            }
            .man.fst {
                width: 60%;
            }
            .flexx.tab.responsive {
                width: 100% !important;
                margin-top: 1.5em !important;
                flex-direction: row !important;
                justify-content: flex-start !important;
                margin-left: 0px !important;
                padding-left: 0px !important;
            }
            .f18 {
                font-size: 18px !important;
            }
        }
        .f18 {
            font-size: 18px !important;
        }
        .colsec {
            .kts.nopd2, .jts {
                padding-left: 0px !important;
                padding-right: 0px !important;
                p.pbold.f20 {
                    font-size: 20px !important;
                }
                p.pbold.f16, p.pbold.f18 {
                    font-size: 18px !important;
                }
            }
        }
        #services {
            margin-top: 20px;
            // overflow: hidden;
            .ivx {
                top: unset;
                right: -90px;
                bottom: -170px;
                z-index: -10;
            }
            img.i1 {
                left: 5%;
            }
            img.i2 {
                right: 5%;
            }
            img.i3 {
                top: 100px;
                left: 10%;
            }
            img.i4 {
                top: 120px;
                right: 16%;
            }
            img.i5 {
                left: 4%;
            }
            img.i6 {
                right: 3%;
            }
            .f36 {
                padding-top: 0px;
                font-size: 36px;
                font-weight: 500;
            }
            .plink {
                font-size: 18px;
            }
            .mglf {
                margin-top: 50px;
            }
            .pbold.f20 {
                font-size: 18px;
            }
        }
        #stats {
            &.rbg-dark::before {
                background: #e0f5fe url(../img/home/stats_light_lg.webp) no-repeat;
            }
            .container {
                position: relative;
                z-index: 10;
            }
            h2.f36 {
                padding: 1em !important;
                margin-top: 10px;
                margin-bottom: 10px;
            }
            .row.gx-4 {
               padding: 1em 2em;
               .wth {
                   font-weight: 500;
               }
               .text-muted {
                   font-size: 18px;
                   padding: 10px 1em;
                   width: 319px;
                   margin: 0 auto;
               }
            }
            img.pl1, img.pl2 {
                z-index: 0;
            }
        }
        #partner {
            .iline {
                left: 100px;
                height: 100%;
            }
            .no.responsive {
                left: 44px;
                font-weight: 600;
                font-size: 40px;
            }
            .ft20 {
                font-size: 24px !important;
            }
            .pml {
                padding-left: 165px !important;
                padding-right: 40px !important;
                .pbold {
                    margin-bottom: 0px;
                }
                img.responsive {
                    margin-top: 8px;
                    transform: scale(0.85);
                }
            }
            .swae {
                width: 333px;
                font-size: 20px;
            }
            .tpil {
                margin-bottom: 1.2em;
            }
        }
        .testimony {
            padding-top: 0px;
            .px-lg-5 {
                padding-right: 2em !important;
                max-width: calc(100% - 4em) !important;
                padding-left: 2em !important;
            }
            .pm-box {
                margin-top: 0px !important;
                padding: .5em 10px !important;
            }
            img.bc {
                top: 88px;
                left: 2%;
                z-index: 1000000;
            }
            img.rk {
                bottom: -15px;
                right: -8px
            }
            .cm-box {
                // height: 215px;
            }
            .dfl {
                h4 {
                    font-size: 20px;
                }
                span.sm {
                    font-size: 12px;
                }
            }
        }
        #contact {
            padding-top: 50px !important;
            .container {
                padding-left: 0px !important;
                padding-right: 0px !important;
                max-width: 90% !important;

                .kts {
                    padding-right: 0px !important;
                }
                .jts.is-tablet {
                    padding-left: 15px !important;
                }
                &>.row {
                    margin-top: 50px;
                    .pbold.pb2 {
                        span {
                            margin-bottom: 10px;
                        }
                        b {
                            font-size: 24px !important;
                        }
                    }
                    .man {
                        // width: 100% !important;
                        // height: auto !important;
                    }
                    .pbold.fb22 {
                        width: 84% !important;
                        margin-top: 25px !important;
                        margin-left: 1% !important;
                    }
                    .soc {
                        width: 35px;
                        height: 35px;
                        top: -4px;
                    }
                }
            }
        }
    }
    [data-theme='dark'] {
        .home-page {
            #stats {
                &.rbg-dark::before {
                    background: #2c3d50 url(../img/home/stats_dark_lg.webp) no-repeat;
                }
            }
        }
        #clients {
            &.rbg-dark::before {
                background: #2c3d50 url(../img/home/clients_dark_lg.webp) no-repeat;
            }
        }
        #dc {
            &.rbg-dark::after {
                top: calc(100% - 315px);
                width: 383px;
                height: 315px;
                background: url(../img/home/dcv_dark_lg.svg) no-repeat;
            }
            &.rbg-dark::before {
                background: #2c3d50 url(../img/home/dc_dark_lg.png) no-repeat;
            }
        }
    }
}

@media (min-width: 481px) and (max-width: 767px) {
    .ghs {
        width: 90%;
        margin: 0 auto;
    }
}

@media (min-width: 768px) {
    #root {
        #clients {
            padding-bottom: 3em !important;
        }
    }
}

@media (max-width: 767px) {
    nav {
        .mlfi {
            top: -13px;
            .mlo {
                width: 125px !important;
                // height: 32px !important;
            }
        }
        .rsec.responsive {
            position: relative;
            right: -30px !important;
            transform: scale(.9) !important;
            top: -20px !important;
        }
        .switch.mob {
            margin-top: 10px;
            height: 32px;
            width: 53px;
            top: 8px !important;
            .slider:before {
                height: 26px;
                width: 26px;
                left: 3px;
                bottom: 3px;
                /* border: 0px !important; */
                padding: 1px 3px;
            }
            input:checked + .slider:before {
                transform: translateX(21px);
            }
        }
        .navbar-toggler-right {
            margin-left: 16px !important;
            margin-right: 10px !important;
        }
    }
    .not-mobile {
        display: none !important;
    }
    .responsive {
        .switch.mob {
            position: relative;
            top: 8px;
        }
        .navbar-toggler-right {
            margin-left: 20px;
            img {
                height: 40px;
            }
        }
    }
    .swae {
        width: 225px !important;
        height: 55px !important;
        margin-top: 0px;
        font-size: 14px !important;
        img.air {
            position: absolute;
            top: -18px;
            right: -12px;
            width: 60px;
            // transform: scale(0.7) !important;
        }
    }
    .tpil {
        margin-bottom: 0 !important;
        margin-top: -20px !important;
        .tp_star {
            width: 25px !important;
        }
        span {
            font-size: 14px !important;
            .tpi {
                height: 20px;
            }
        }
    }
    .mglf1 {
        .pr-10 {
            &>span {
                display: inline-block;
                width: 100%;
                b {
                    display: inline-block;
                    width: 100%;
                }
            }
        }
    }
    .masthead {
        // height: 700px !important;
        .fdf {
            width: 70%;
        }
        p.f22 {
            width: 80%;
            font-size: 18px !important;
        }
        .tpil {
            width: auto !important;
            margin: 0px;
            margin-top: 0px !important;
            position: relative;
            left: -23px;
        }
        .flexx.tab {
            padding-left: 0px;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            margin-bottom: 40px;
        }
    }
    #clients {
        .container {
            padding-bottom: 40px !important;
        }
        &.rbg-dark::before {
            background: #dff0f7 url(../img/home/clients_md.png) no-repeat;
        }
        h2.f36 {
            font-size: 24px;
            padding: 0 5px;
            font-weight: 600 !important;
            padding-left: 10px;
            padding-right: 10px;
        }
        .container {
            padding-left: 0px !important;
            padding-right: 0px !important;
            margin-bottom: 70px;
        }
        .slick-slide {
            transform: translateX(-60px);
        }
    }
    #dc {
        margin-top: 10px !important;
        .fl50 {
            line-height: 35px;
            margin-bottom: 24px !important;
        }
        &.rbg-dark::after {
            top: unset;
            bottom: 0px;
            width: 173px;
            height: 181px;
            background: url(../img/home/dc_v_md.svg) no-repeat;
        }
        &.rbg-dark::before {
            background: #dff0f7 url(../img/home/dc_md.png) no-repeat;
        }
        h2 {
            margin-top: .9em !important;
        }
    }
    #footer-0 {
        h2 {
            margin-top: 25px !important;
            margin-bottom: 0px !important;
        }
        .f_18 {
            font-size: 18px !important;
            line-height: normal;
            padding-left: 10px;
            font-weight: 200;
            padding-right: 10px;
            // text-transform: lowercase;
            // &:first-letter {
            //     text-transform: uppercase;
            // }
        }
    }
    footer.footer {
        margin-top: -10px;
        padding-top: 30px !important;
        background-color: transparent !important;
        .f_24 {
            font-size: 18px !important;
        }
        .container:first-child {
            margin-bottom: 1.5em;
        }
        .fttt {
            padding-left: 0px !important;
            padding-right: 0px !important;
            width: 100% !important;
            max-width: 100% !important;
            background: rgba(18, 18, 18, 0.05) !important;
        }
    }
    header.masthead {
        padding-bottom: 10px;
    }
    .home-page {
        #swom {
            .kts {
                h1 {
                    width: 100%;
                }
            }
            .f36 {
                font-weight: 600 !important;
                font-size: 24px !important;
            }
            .pbold.f22 {
                font-size: 18px;
                margin-top: 10px;
                margin-bottom: 15px;
                line-height: 28px;
                font-weight: 600 !important;
            }
            .pbold.f18 {
                font-weight: 400 !important;
                font-size: 18px !important;
                line-height: 25.6px;
                line-height: 160%;
            }
            .available-days {
                right: 100px !important;
                bottom: -60px !important;
                transform: scale(1) !important;
            }
            .jts > figure {
                // left: -35px;
                width: calc(100% + 100px);
            }
            .jts {
                // padding: 0px !important;
                margin-bottom: 0em !important;
            }
            .man.fst {
                width: 65% !important;
                max-width: 500px !important;
            }
            .flexx.tab.responsive {
                margin-top: 1em !important;
                flex-direction: column !important;
                .tpil {
                    margin-top: 10px !important;
                    width: fit-content !important;
                    min-width: fit-content !important;
                    position: relative;
                    left: 10px !important;
                    top: 0px !important;
                }
            }
            .pbold.is-mobile {
                padding-left: 0px;
            }
        }
        .colsec {
            &.trust {
                .man.pr.alt {
                    margin-top: 40px;
                }
            }
            &:not(.trust) {
                .pbold.f20 {
                    margin-bottom: 10px !important;
                }
            }

            .f36 {
                font-weight: 600 !important;
                width: 110% !important;
                width: 100% !important;
                font-size: 24px !important;
            }
            .kts.nopd2, .jts  {
                padding-right: 0px !important;
                padding-left: 0px !important;
                p.pbold.f20 {
                    font-size: 18px !important;
                    // font-weight: 500 !important;
                }
                p.pbold.f16, p.pbold.f18 {
                    font-size: 18px !important;
                    margin-bottom: 0px;
                }
            }
            .man.is-smalls {
                margin-top: 1em;
                &.crac {
                    width: 100%;
                    position: relative;
                    left: 0px;
                    right: 0px;
                    margin-left: 0px !important;
                    margin: 2em auto;
                    margin-top: 4em;
                    margin-bottom: 0em !important;
                }
            }
            .tpil {
                margin-top: 10px !important;
                width: fit-content !important;
                min-width: fit-content !important;
                position: relative;
                top: -5px !important;
                left: 10px !important;
            }
            img.imq {
                left: -33px !important;
                width: 30px;
                width: 50px;
                top: -6px;
            }
            .flexx.tab {
                margin-top: 1.5em !important;
                justify-content: flex-start;
                align-items: flex-start;
                margin-left: 10px !important;
                .tpil {
                    margin-top: 1em !important;
                }
            }
            &:not(.trust) {
                padding-top: 0px !important;
            }
        }
        #services {
            overflow: hidden;
            .plink {
                margin-top: 20px !important;
                margin-bottom: 0px !important;
                font-size: 14px !important;
            }
            .ivx {
                top: unset;
                right: -30px;
                bottom: 40%;
                height: 216px;
                width: 200px;
            }
            img.i1 {
                left: 4%;
            }
            img.i2 {
                right: 4%;
            }
            img.i3 {
                top: 0px;
                left: 20%;
            }
            img.i4 {
                top: 0px;
                right: 20%;
            }
            img.i5 {
                left: 9%;
                top: 7%;
            }
            img.i6 {
                right: 9%;
                top: 7%;
            }
            .f36 {
                padding-top: 0px;
                font-size: 24px;
                font-weight: 600 !important;
            }
            .container {
                margin-top: -20px;
            }
            .mglf {
                margin-top: 30px;
                .mglf1 {
                    margin-bottom: 25px;
                }
            }
            .flexx.tab {
                margin-left: 10%;
                margin-bottom: 30px;
                align-items: center;
                margin-top: 2em;
                margin-left: 0px !important;
            }
            .tpil {
                margin-top: 10px !important;
                width: fit-content !important;
                min-width: fit-content !important;
                position: relative;
                left: 0px !important;
                top: -5px !important;
            }
            .pbold.f20 {
                font-size: 18px;
            }
        }
        #stats {
            // background-color: #fff !important;
            &.rbg-dark::before {
                background: #dff0f7 url(../img/home/stats_md.png) no-repeat;
            }
            .container {
                position: relative;
                z-index: 10;
            }
            h2.f36 {
                padding: 1em !important;
                padding-top: 10px !important;
                margin-top: 10px;
                padding-left: 10px !important;
                padding-right: 10px !important;
                font-size: 24px !important;
                padding-bottom: 0px !important;
                margin-bottom: 0px;
                font-weight: 600 !important;
            }
            .row.gx-4 {
               padding: 1em 2em .5em;
               .wth {
                   font-weight: 500;
               }
               .text-muted {
                   font-size: 18px;
                   padding: 10px 1em;
                   width: auto;
                   margin: 0 auto;
               }
               .mt-5 {
                   margin-top: 1.5em !important;
                   margin-bottom: 0px !important;
               }
            }
            img.pl1, img.pl2 {
                z-index: 0;
            }
        }
        #partner {
            padding-top: 10px;
            h2 {
                font-weight: 600 !important;
                font-size: 24px !important;
                margin-bottom: 0px !important;
            }
            .iline {
                left: 50px;
                height: 100%;
            }
            .no.responsive {
                left: 30px;
                font-weight: 600;
                font-size: 20px;
            }
            .pml {
                padding-left: 85px !important;
                padding-right: 40px !important;
                .pbold {
                    margin-bottom: 0px;
                }
                img.responsive {
                    margin-top: 8px;
                    // transform: scale(0.85);
                }
                &.f {
                    img.responsive {
                        // transform: rotate(-22deg) !important;
                    }
                }
            }
            h4.ft20 {
                font-size: 20px;
                margin-bottom: 1em;
            }
            .swae {
                margin-bottom: 1em;
            }
            .tpil {
                margin-bottom: 1.2em;
            }
            img.mi-1,
            img.mi-2,
            img.mi-3 {
                position: relative !important;
                left: -30px !important;
            }
            .no.responsive {
                top: 3px;
            }
        }
        .testimony {
            padding-top: 0px;
            .pm-box-2 {
                margin-top: 50px !important;
                padding: .5em 10px !important;
                li {
                    min-width: 333px !important;
                    width: 333px !important;
                    // height: 220px;
                    height: fit-content;
                    .f_20 {
                        font-size: 18px;
                        line-height: 25px;
                    }
                }
            }
            .f36 {
                font-weight: 600 !important;
                font-size: 24px;
            }
            .dfl {
                h4 {
                    font-weight: 500;
                    font-size: 20px !important;
                }
                .sm {
                    font-size: 14px !important;
                }
            }
            img.bc {
                top: 100px;
                left: 2%;
                transform: scale(0.95);
                z-index: 1000000;
            }
            img.rk {
                bottom: -15px;
                right: -8px
            }
        }
        #contact {
            padding-top: 0px !important;
            padding-bottom: 85px !important;
            h1 {
                font-size: 24px !important;
                padding-top: 1.5em;
                line-height: 140%;
                padding-bottom: 10px;
            }
            .f36 {
                font-weight: 600 !important;
            }
            .f_18 {
                font-weight: 400 !important;
                line-height: 150%;
                font-size: 18px;
            }
            .container {
                padding-left: 0px !important;
                padding-right: 0px !important;
                max-width: 90% !important;

                .kts {
                    padding-right: 0px !important;
                }
                .jts.is-tablet {
                    padding-left: 15px !important;
                }
                &>.row {
                    margin-top: 30px;
                    .col-md-6.kts.m_lst {
                        width: 100% !important;
                    }
                    .pbold.pb2 {
                        margin-top: 15px !important;
                        width: 90% !important;
                        span {
                            margin-bottom: 0px;
                            &.conc {
                                margin-bottom: 12px !important;
                            }
                            &.go {
                                font-size: 16px;
                                font-size: 100;
                            }
                        }
                        br.not-mobile {
                            display: none !important;
                        }
                        b {
                            font-size: 20px !important;
                            font-weight: 600 !important;
                        }
                    }
                    .man {
                        width: 100% !important;
                        height: auto !important;
                    }
                }
            }
        }
    }
    [data-theme='dark'] {
        .home-page {
            #stats {
                &.rbg-dark::before {
                    background: #2c3d50 url(../img/home/stats_dark_lg.png) no-repeat;
                }
            }
        }
        #clients {
            &.rbg-dark::before {
                background: #2c3d50 url(../img/home/clients_dark_lg.png) no-repeat;
            }
        }
        #dc {
            &.rbg-dark::after {
                opacity: 0.1;
            }
            &.rbg-dark::before {
                // background-color: #fff !important;
                background: #2c3d50 url(../img/home/dc_dark_lg.png) no-repeat;
            }
        }

        footer.footer {
            .fttt {
                background: rgba(18, 18, 18, 0.36) !important;
            }
        }
    }
}

@media (max-width: 700px) {
    .available-days {
        right: 80px !important;
        bottom: -70px !important;
        transform: scale(0.9) !important;
    }
}

@media (max-width: 600px) {
    .home-page {
        #swom {
            .available-days {
                right: 70px !important;
                bottom: -95px !important;
                transform: scale(0.8) !important;
            }
        }
    }
}

@media (max-width: 480px) {
    .home-page {
        .colsec {
            .crac.man.alt, .pr.man.alt {
                width: 100%;
                margin-top: 30px;
            }
        }
        #swom {
            .available-days {
                left: 53% !important;
                right: unset !important;
                bottom: -100px !important;
                transform: scale(.68) !important;
            }
            .jts > figure {
                left: unset;
                justify-content: flex-start;
                width: 100%;
                margin-left: 0px !important;
            }
            .man.fst {
                width: 75% !important;
                left: 0px !important;
                min-width: 220px !important;
            }
            small.pbold.is-mobile {
                padding-left: 10px;
                padding-right: 10px;
            }
        }
    }
}

@media (max-width: 435px) {
    .home-page {
        #swom {
            .available-days {
                left: 50% !important;
                bottom: -110px !important;
            }
        }
    }
}

@media (max-width: 410px) {
    .home-page {
        #swom {
            .available-days {
                left: 46% !important;
            }
        }
    }
}

@media (max-width: 390px) {
    .home-page {
        #swom {
            .available-days {
                left: 42% !important;
                transform: scale(.6) !important;
            }
        }
    }
}

@media (max-width: 350px) {
    .home-page {
        #swom {
            .available-days {
                left: 40% !important;
            }
        }
    }
}

@media (max-width: 333px) {
    .home-page {
        #swom {
            .available-days {
                left: 40% !important;
                transform: scale(.5) !important;
            }
        }
    }
}

#root {
    #clients {
        .container {
            // overflow: hidden;
            overflow: unset !important;
            box-sizing: border-box;
            position: relative;
            width: 100% !important;
            max-width: 100% !important;
            margin-bottom: 0px !important;
            .pm-box-2 {
                padding: 1em 0 2em;
                margin-top: -1em;
                transition: 450ms ease-in all;
                width: 100% !important;
                overflow: unset !important;
                position: relative;
                // &.anm {
                    animation: marquee_c 20s linear infinite;
                // }
                .xrw {
                    min-width: 180px;
                    width: 180px;
                }
                // &:hover, &.hover {
                //     animation-play-state: paused;
                // }
                &.hover {
                    animation: unset;
                    animation-play-state: paused;
                    transition: 450ms ease-in all !important;
                }
            }
            .slick-track.slick-slider {
                height: 40px !important;
                position: relative;
                top: 10px;
            }
        }
    }
}

@media (min-width: 768px) {
    #root {
        #clients {
            .container {
                .pm-box-2 {
                    &:hover, &.hover {
                        animation-play-state: paused;
                    }
                }
            }
        }
    }
}