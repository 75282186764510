.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  top: 0px;
  height: 34px;
  /* border: 1px solid #5977e3;
  border: 1px solid #C8D9FD; */
  border-radius: 18px;
  overflow: hidden;
}

.switch input { 
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #7D97F4;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: url(../img/mn.png);
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  padding: 2px 4px;
  background-color: white;
  border: 2px solid #C8D9FD;
  -webkit-transition: .4s;
  transition: .4s;
}


input:checked + .slider:before {
    content: url(../img/sn.png);
}
  

input:checked + .slider {
  background-color: #7D97F4;
}

input:focus + .slider {
  box-shadow: 0 0 1px #7D97F4;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}