
.mh1 {
    font-size: 64px !important;
    font-weight: 600 !important;
    min-width: 600px;
    margin-bottom: 20px;
}

.mh2 {
    font-size: 40px !important;
    font-weight: 500 !important;
    color: #7D97F4 !important;
    margin-bottom: 30px;
    min-width: 600px;
}

.appointment-page {
    .mh1, .mh2 {
        min-width: 650px !important;
    }
}

@media (min-width: 993px) and (max-width: 1100px) {
    .mh1 {
        font-size: 54px !important;
        font-weight: 600 !important;
        min-width: 580px;
    }
    
    .mh2 {
        font-size: 40px !important;
        font-weight: 500 !important;
        margin-bottom: 20px;
        min-width: 600px;
    }
}

@media (min-width: 993px) and (max-width: 1125px) {
    .sales-page {
        .mh1 {
            font-size: 54px !important;
            font-weight: 600 !important;
            min-width: 450px;
            margin-bottom: 10px;
            .is-desktop {
                display: none !important;
            }
        }
        .mh2 {
            font-size: 30px !important;
            font-weight: 600 !important;
            margin-bottom: 20px;
            min-width: 450px;
        }
    }
    .appointment-page {
        .mh1 {
            font-size: 54px !important;
            font-weight: 600 !important;
            min-width: 450px;
            margin-bottom: 10px;
        }
        .mh2 {
            font-size: 30px !important;
            font-weight: 600 !important;
            margin-bottom: 20px;
            min-width: 450px;
        }
    }
}

@media (min-width: 768px) and (max-width: 992px) {
    .mh1 {
        font-size: 48px !important;
        font-weight: 600 !important;
        min-width: 450px;
        margin-bottom: 10px;
    }
    .mh2 {
        font-size: 24px !important;
        font-weight: 600 !important;
        margin-bottom: 20px;
        min-width: 450px;
    }
}

@media (max-width: 767px) {
    .mh1 {
        font-size: 45px !important;
        font-weight: 600 !important;
        
        min-width: 100%;
        margin-bottom: 10px;
    }
    .mh2 {
        font-size: 24px !important;
        font-weight: 600 !important;

        margin-bottom: 20px;
        min-width: 100%;
    }
    .sales-page {
        .mh2 {
            font-size: 35px !important;
            font-weight: 500 !important;
            margin-bottom: 25px;
            min-width: 100%;
        }
    }
}

@media (max-width: 600px) {
    .mh1 {
        font-size: 38px !important;
        font-weight: 600 !important;
        
        min-width: 100%;
        margin-bottom: 10px;
    }
    .mh2 {
        font-size: 24px !important;
        font-weight: 500 !important;

        margin-bottom: 20px;
        min-width: 100%;
    }
    .sales-page {
        .mh2 {
            font-size: 30px !important;
            font-weight: 500 !important;
            margin-bottom: 20px;
        }
    }
}

@media (max-width: 480px) {
    .mh1 {
        font-size: 32px !important;
    }
    .mh2 {
        font-size: 24px !important;
    }
}