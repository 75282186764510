@media (min-width: 993px) and (max-width: 1100px) {
    .home-page, #root:not(.home-page) {
        .colsec {
            .container {
                .kts.nopd2 {
                    transform: scale(0.9);
                    padding-left: 0px !important;
                    top: -50px;
                    position: relative;
                    // &.cl-tx.p {
                    //     left: 50px;
                    // }
                }
            }
        }
        // .nopd2 {
        //     transform: scale(0.85);
        //     padding-left: 0px !important;
        //     top: -50px;
        //     position: relative;
        //     &.cl-tx.p {
        //         left: 50px;
        //     }
        // }
        .colsec {
            .figger, .crac.man {
                transform: scale(0.85);
            }
            &.trust {
                .figger, .man {
                    top: -70px;
                }
                .man {
                    position: relative;
                    left: 50px;
                }
            }
        }
        #wps {
            margin-top: -5em !important;
        }
        #partner {
            .iline {
                left: 85px !important;
            }
            .pml {
                padding-left: 0px !important;
            }
        }
        #dc {
            margin-top: 0px !important;
        }
        .fnrsp {
            margin-left: 2rem !important;
            width: calc(100% - 4rem) !important;
        }
        &.home-page {
            .colsec.trust {
                margin-top: -5em;
                .man {
                    left: 0px;
                }
                .nopd2 {
                    left: -22px;
                }
            }
            .colsec:not(.trust) {
                margin-top: -5em;
                .man {
                    left: 30px;
                    top: -70px;
                    position: relative;
                    transform: scale(.7);
                }
            }
            #services {
                margin-top: -5em !important;
            }
            #partner {
                padding-top: 1em !important;
                .f36 {
                    margin-bottom: 0px !important;
                }
            }
            #stats {
                margin-top: -2em !important;
            }
            .bub {
                top: -50px !important;
            }
        }
        &.sales-page {
            .colsec.colsec-3 .jts {
                position: relative;
                left: 35px;
            }
        }
        &.leads-page {
            .colsec-4 {
                .figger {
                    left: -50px;
                }
            }
        }
        &.appointment-page {
            .colsec-1 {
                margin-bottom: 2em !important;
                .figger {
                    top: -50px !important;
                }
            }
            .colsec-2 {
                .figger {
                    margin-top: -70px !important;
                }
            }
            .colsec-3 {
                margin-bottom: 0em !important;
                .crac.man {
                    transform: scale(.85);
                    margin-top: -70px !important;
                }
            }
            .colsec-4 {
                .figger {
                    margin-top: -150px !important;
                }
            }
        }
        &.sass-page, &.premium-page, &.social-page {
            .colsec-1 {
                margin-bottom: 2em !important;
                .figger {
                    top: -50px;
                }
            }
            .colsec-3 {
                margin-bottom: 0em !important;
                .figger {
                    top: -50px;
                }
            }
            .colsec-4 {
                margin-top: 0em !important;
                margin-bottom: 2em !important;
            }
            &.premium-page {
                .colsec-4 {
                    margin-top: -2em !important;
                    .man {
                        transform: scale(.85);
                        top: -100px;
                    }
                }
            }
        }
        &.premium-page {
            #services {
                h2.f40 {
                    .is-desktop {
                        display: none !important;
                    }
                }
            }
        }
        &.social-page {
            .colsec-4 {
                margin-top: -2em !important;
                margin-bottom: 0px !important;
                padding-bottom: 0em !important;
            }
        }
    }

    #root:not(.home-page) {
        #stats {
            .mt-5 {
                .text-muted {
                    font-size: 16px !important;
                }
                .h4.mb-2.bas_ {
                    width: 105%;
                    margin-left: -2.5% !important;
                }
            }
        }
        #clients {
            margin-top: -4em !important;
        }
        .testimony {
            margin-top: -2.5em !important;
        }
    }

    .home-page {
        .colsec-3 {
            margin-top: -5em !important;
            .crac.man {
                top: -20px !important;
            }
        }
        .col-md-4.cm-box.tpb {
            width: 39% !important;
        }
        #contact {
            .bub {
                top: -10px !important;
            }
        }
    }

    .sales-page {
        .colsec-1, .colsec-3 {
            .container {
                .crac.man {
                    transform: scale(1) !important;
                }
            }
        }
        .colsec-2 {
            margin-top: -1em !important;
            margin-bottom: 5em !important;
            .man.pr, .figger {
                top: -20px !important;
            }
        }
        .colsec-3 {
            margin-top: -2em !important;
            margin-bottom: 1em !important;
        }
        #stats {
            margin-top: -10em !important;
        }
        .colsec-4 {
            margin-top: -1em !important;
            margin-bottom: 1em !important;
            .man.pr, .figger {
                top: -50px !important;
            }
        }
        #partner {
            margin-top: -2.5em !important;
            margin-bottom: 0em !important;
        }
    }

    .leads-page {
        .colsec-1 {
            .figger {
                top: -50px !important;
            }
        }
        .colsec-2 {
            margin-top: 1em !important;
            margin-bottom: 0px !important;
            .figger {
                top: -50px !important;
            }
        }
        #stats {
            margin-top: -4em !important;
        }
        .colsec-3 {
            margin-top: -2em !important;
            padding-top: 4em !important;
            .man {
                top: -10px !important;
            }
        }
        .colsec-4 {
            margin-top: -2em !important;
            margin-bottom: 1em !important;
            .figger {
                top: -45px !important;
            }
        }
        #partner {
            margin-top: -2.5em !important;
        }
    }

    .appointment-page {
        .colsec-1, .colsec-2, .colsec-3 {
            .f36 {
                width: 110% !important;
            }
        }
        .colsec-4 {
            margin-top: -2em !important;
            margin-bottom: 1em !important;
        }
        #stats {
            margin-top: -3em !important;
        }
        #partner {
            margin-top: -2.5em !important;
        }
    }

    .sass-page {
        #stats {
            margin-top: -3em !important;
        }
        #partner {
            margin-top: -2.5em !important;
            margin-bottom: 0px !important;
            padding-bottom: 3em !important;
        }
    }

    .premium-page {
        .colsec-1, .colsec-4 {
            .f36 {
                width: 110% !important;
            }
        }
        .colsec-1 {
            margin-top: 1em !important;
        }
        #stats {
            margin-top: -3em !important;
        }
        .colsec-3 {
            margin-top: -1em;
        }
        .colsec-4 {
            margin-bottom: 0px !important;
            padding-bottom: 0em !important;
            .man {
                top: -50px !important;
            }
        }
        #partner {
            margin-top: -2.5em !important;
        }
    }

    .social-page {
        .colsec-1, .colsec-2, .colsec-4 {
            .f36 {
                width: 110% !important;
            }
        }
        .colsec-2 {
            margin-top: -3em !important;
        }
        #stats {
            margin-top: -7em !important;
        }
        #partner {
            margin-top: -2.5em !important;
        }
    }
}